import { inject, injectable, postConstruct } from 'inversify';
import PortalSDKStore from './PortalSDKStore';
import { IPortalSdk } from '@deliveryhero/vendor-portal-sdk';

@injectable()
export class MasterSdkStore {
  sdk: IPortalSdk;

  @inject(PortalSDKStore) private portalSdkStore: PortalSDKStore;

  @postConstruct()
  init() {
    this.sdk = this.portalSdkStore.getSdkForMaster() as any;
  }
}
