import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { datadogLogs } from '@datadog/browser-logs';
import runtime from 'serviceworker-webpack-plugin/lib/runtime';
import { addLocaleData } from 'react-intl';
import * as en from 'react-intl/locale-data/en';
import * as de from 'react-intl/locale-data/de';
import { reaction, when } from 'mobx';
import i18next from 'i18next';

import { container } from './dependencies';
import { SessionStore } from './stores/SessionStore';
import { PluginStore } from './stores/PluginStore';
import { PlatformStore } from './stores/PlatformStore';
import VersionUpdateHandler from './utils/VersionUpdateHandler';
import GtmManager from './utils/gtm/GtmManager';
import GtmVirtualPageViewListener from './utils/gtm/GtmVirtualPageViewListener';
import GtmLoginReturnedEvent from './utils/gtm/GtmLoginReturnedEvent';
import { QueryStringParser } from './utils/QueryStringParser';
import AppInstallPrompt from './utils/AppInstallPrompt';
import SalesforceStore from './stores/SalesforceStore';
import { IConfig } from './config';
import FwfStore from './stores/FwfStore';
import { VendorStore } from './stores/VendorStore';
import { TYPES } from './types';
import { ApiStore } from './stores/ApiStore';
import { BrazeManager } from './utils/BrazeManager';
import { loadQualtrics } from './utils/loadQualtrics';
import QualtricsManager from './utils/QualtricsManager';
import { TranslationsStore } from './stores/TranslationsStore';
import { initializeI18Next } from './i18n';
import { AvailableLanguagesStore } from './stores/AvailableLanguagesStore';

export const init = async () => {
  const config = container.get<IConfig>('config');
  const brazeManager = container.get<BrazeManager>(TYPES.BrazeManager);
  const window = container.get<Window>('window');
  const availableLangStore = container.get<AvailableLanguagesStore>(
    AvailableLanguagesStore,
  );
  const translationStore = container.get<TranslationsStore>(TranslationsStore);

  await availableLangStore.fetchLanguageList();

  await initializeI18Next();
  await translationStore.fetchTranslationsForLocale(i18next.language);

  datadogLogs.init({
    clientToken: config.datadog.clientToken,
    datacenter: 'eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });

  datadogLogs.setLoggerGlobalContext({
    environment: config.env,
    region: config.region,
  });

  const appModule =
    window.location.pathname.split('/')[1].toLowerCase() || 'master';
  Sentry.setTag('module', appModule);

  Sentry.init({
    environment: `${config.region}-${config.env}`,
    dsn: `https://${config.sentry.key}@sentry.telemerty.infra.euw.dh-vt-prd.net/${config.sentry.project}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: config.sentry.tracesSampleRate,
  });

  const platformStore = container.get<PlatformStore>(PlatformStore);
  const currentDomain = window.location.hostname;
  const platformQueryParam = QueryStringParser.parse(window.location.search)
    .platform;

  if (platformQueryParam) {
    platformStore.setPlatformByName(platformQueryParam);
  } else {
    platformStore.setPlatformByDomain(currentDomain);
  }

  if (platformStore.currentPlatform.braze?.apiKey) {
    await brazeManager.init({
      apiKey: platformStore.currentPlatform.braze.apiKey,
      baseUrl: platformStore.currentPlatform.braze.baseUrl,
    });
  } else {
    brazeManager.initMock();
  }

  const qualtricsProjectId =
    platformStore.currentPlatform?.qualtrics?.projectId;

  if (qualtricsProjectId) {
    loadQualtrics(qualtricsProjectId);

    const qualtricsManager = container.get<QualtricsManager>(
      TYPES.QualtricsManager,
    );
    qualtricsManager.initSurvey();
  }

  const gtmManager = container.get<GtmManager>(GtmManager);
  container.get<GtmVirtualPageViewListener>(GtmVirtualPageViewListener);
  const loginReturnedEvent = container.get<GtmLoginReturnedEvent>(
    GtmLoginReturnedEvent,
  );
  const versionUpdateHandler = container.get<VersionUpdateHandler>(
    VersionUpdateHandler,
  );
  versionUpdateHandler.init();

  addLocaleData([...en, ...de]);

  // Just initialise ApiStore to attach expiration handling
  container.get<ApiStore>(ApiStore);
  container.get<SalesforceStore>(SalesforceStore);
  const vendorStore = container.get<VendorStore>(TYPES.VendorStore);

  // Just initialise ApiStore to attach expiration handling
  container.get<ApiStore>(ApiStore);
  const sessionStore = container.get<SessionStore>(TYPES.SessionStore);

  // Update current restaurant and available restaurants when jwt changes
  const fwfStore = container.get<FwfStore>(FwfStore);
  reaction(
    () => ({
      mainSession: sessionStore.getMainSession(),
      allVendorsFetched: vendorStore.isFetched,
    }),
    ({ mainSession }) => {
      if (mainSession) {
        fwfStore.init();
      } else {
        vendorStore.clearVendors();
      }
    },
    { fireImmediately: true },
  );

  if (sessionStore.isLoggedIn) {
    when(
      () => vendorStore.isVendorAvailable,
      () => loginReturnedEvent.pushEvent(),
    );
  }

  const pluginStore = container.get<PluginStore>(PluginStore);

  if ('serviceWorker' in navigator) {
    runtime.register();
    const appInstallPrompt = container.get<any>(AppInstallPrompt);
    container
      .get<any>('window')
      .addEventListener('beforeinstallprompt', appInstallPrompt.eventHandler);
  }

  pluginStore.init();

  const performanceInMs = container.get<any>('window').performance.now();

  gtmManager.pushEvent('app.initTime', {
    eventValue: Math.round(performanceInMs),
  });
};
