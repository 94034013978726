import * as React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PasswordField from '../../components/PasswordField';
import styled from 'styled-components';
import { Translate } from '../Translate';

const LoginFormWrapper = styled.form`
  margin-top: 25px;
`;

const LoginButton = styled(Button)`
  margin-top: 14px !important;
  margin-bottom: 10px !important;
  text-decoration: none;
`;

export type LoginFormProps = {
  email: string;
  password: string;
  isSubmitting: boolean;
  onSubmit: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getError: (key: string) => string;
};

const LoginForm = ({
  email,
  password,
  isSubmitting,
  onSubmit,
  onChange,
  getError,
}) => (
  <LoginFormWrapper id="login" onSubmit={onSubmit}>
    <FormControl fullWidth={true}>
      <InputLabel htmlFor="login-email-field">
        <Translate code="global.login.email" />
      </InputLabel>
      <Input
        name="email"
        value={email}
        onChange={onChange}
        fullWidth={true}
        type="email"
        id="login-email-field"
        data-testid="login-email-field"
      />
      <FormHelperText data-testid="login-email-helpertext">
        {getError('email')}
      </FormHelperText>
    </FormControl>

    <FormControl fullWidth={true}>
      <InputLabel htmlFor="login-password-field">
        <Translate code="global.login.password" />
      </InputLabel>
      <PasswordField
        name="password"
        value={password}
        onChange={onChange}
        error={!!getError('password')}
        id="login-password-field"
        data-testid="login-password-field"
        fullWidth={true}
      />
      <FormHelperText data-testid="login-password-helpertext">
        {getError('password')}
      </FormHelperText>
    </FormControl>

    <LoginButton
      id="button_login"
      data-testid="login-button"
      fullWidth={true}
      type="submit"
      variant="contained"
      color="primary"
      disabled={isSubmitting}
    >
      <Translate
        code={
          isSubmitting
            ? 'global.login.submit_button_login_in'
            : 'global.login.submit_button'
        }
      />
    </LoginButton>
  </LoginFormWrapper>
);

export default LoginForm;
