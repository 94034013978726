import * as React from 'react';
import MoreIcon from '@material-ui/icons/MoreVert';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { Translate } from '../../components/Translate';
import { SIDEBAR_COLUMN_WIDTH, ANIMATION_DURATION_SECONDS } from './constants';
import { DesktopNavItem } from './DesktopNavItem';
import { NavigationItem } from '../App/MasterRoutes/MasterRoutes';

type NavListProps = {
  wide: boolean;
};

const NavList = styled.ul<NavListProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  list-style: none;
  margin: ${(props: any) => (props.wide ? `0 ${props.theme.spacing(1)}px` : 0)};
  padding: 0;
  height: auto;
  background-color: transparent;
  justify-content: flex-start;
  transition: margin ${ANIMATION_DURATION_SECONDS}s;
  width: ${getSpacing(SIDEBAR_COLUMN_WIDTH)};
`;

export type DesktopNavigationColumn = {
  navItems: NavigationItem[];
  open: boolean;
  currentPath: string;
  pushEvent: (key: string) => void;
  showMore?: boolean;
  onOpen: () => void;
  onClose: () => void;
};

function InnerDesktopNavigationColumn({
  navItems,
  open,
  currentPath,
  pushEvent,
  showMore,
  onOpen,
  onClose,
}: DesktopNavigationColumn) {
  return (
    <NavList
      data-testid="master-navigation"
      key={navItems[0].label}
      wide={open}
    >
      {navItems.map((nav) => (
        <DesktopNavItem
          key={nav.label}
          nav={nav}
          data-testid={`nav-item-${nav.name}`}
          label={<Translate code={nav.label} />}
          selected={currentPath.startsWith(nav.path)}
          open={open}
          onClick={() => {
            onClose();
            pushEvent(nav.gtmOpenEvent);
          }}
        />
      ))}
      {showMore && (
        <DesktopNavItem
          component="button"
          nav={
            {
              menuIcon: <MoreIcon />,
            } as any
          }
          label={<Translate code="global.menu.more" />}
          data-testid="nav-item-more"
          visible={!open}
          open={open}
          selected={!navItems.find((item) => currentPath.startsWith(item.path))}
          onClick={onOpen}
        />
      )}
    </NavList>
  );
}

export const DesktopNavigationColumn = React.memo(InnerDesktopNavigationColumn);
