import * as React from 'react';
import { isDeviceTypeRapp } from './isDeviceTypeRapp';
import { Headline as RappHeadline } from './Rapp/Headline';
import { Headline as TabletHeadline } from './Tablet/Headline';
import HeadlineWrapper from '../../components/Headline';

export const Headline = ({ platform }) => (
  <HeadlineWrapper>
    {isDeviceTypeRapp(platform.platformKeys) ? (
      <RappHeadline />
    ) : (
      <TabletHeadline />
    )}
  </HeadlineWrapper>
);
