import {
  sizing,
  shadows,
  palette,
  spacing,
  typography,
  flexbox,
  display,
  positions,
} from '@material-ui/system';
import styled from 'styled-components';

// Proxy to transforn non px values to styled components (only supports non pixel values from 4.x on)
// Depricate this when styled-components are upgraded to 4.x
const spacingProxy = createUnitProxy(spacing);
const sizingProxy = createUnitProxy(sizing);
const positionsProxy = createUnitProxy(positions);

// eslint-disable-next-line max-len
const Box = styled.div<
  any
>`${palette}${sizingProxy}${spacingProxy}${typography}${flexbox}${display}${shadows}${positionsProxy}`;
export default Box;

function createUnitProxy(fn: Function) {
  return (props) => {
    const intermediateValue = fn(props);
    return addPxToNumberValues(intermediateValue);
  };
}

function addPxToNumberValues(obj: Record<string, any>) {
  return Object.entries(obj).reduce(
    (agg, [key, val]) => ({
      ...agg,
      [key]: addPxToNumberValue(val),
    }),
    {},
  );
}

function addPxToNumberValue(val: any) {
  switch (typeof val) {
    case 'number':
      return `${val}px`;
    case 'object':
      return addPxToNumberValues(val);
    default:
      return val;
  }
}
