import UserIconBase from '@material-ui/icons/AccountCircleOutlined';
import styled, { css } from 'styled-components';
import {
  getSpacing,
  forBreakpoint,
  getColor,
} from '@deliveryhero/styled-material-ui';
import * as React from 'react';
import { Box } from '@material-ui/core';
import { PlatformLogo } from '@deliveryhero/vendor-portal-components';
import { DesktopNavigationColumn } from './DesktopNavigationColumn';
import PopMenu from '../../components/PopMenu';
import { SIDEBAR_WIDE_COLUMN_WIDTH, SIDEBAR_COLUMN_WIDTH } from './constants';
import { NavigationItem } from '../App/MasterRoutes/MasterRoutes';

export const MenuWrapper = styled.div`
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

type NavSidebarProps = {
  open: boolean;
  columnCount: number;
};

const NavSidebar = styled.div<NavSidebarProps>`
  background: #2d3645;
  color: ${getColor('common', 'white')};
  position: fixed;
  z-index: 5;
  top: 0;
  overflow: hidden;
  width: ${(props) =>
    props.open
      ? getSpacing(props.columnCount * SIDEBAR_WIDE_COLUMN_WIDTH)(props)
      : getSpacing(SIDEBAR_COLUMN_WIDTH)(props)};
  min-height: 100vh;
  transition: width 0.3s;
  align-self: flex-start;
  ${forBreakpoint(
    'sm',
    css`
      padding: ${getSpacing(0)};
    ` as any,
  )};
`;

const NavSidebarPlaceholder = styled.div`
  width: ${getSpacing(SIDEBAR_COLUMN_WIDTH)};
  flex-grow: 0;
  flex-shrink: 0;
`;

const UserIcon = styled(UserIconBase)`
  position: relative;
  top: 1px;
  color: white;
  cursor: pointer;
  font-size: 24px;
`;

export type DesktopSidebarProps = {
  platformKey: string;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  columnCount: number;
  popOverOptions;
  navItems: NavigationItem[][];
  currentPath: string;
  pushEvent: () => void;
  hasMoreButton: boolean;
};

export function DesktopNavSidebar({
  platformKey,
  open,
  onOpen,
  onClose,
  columnCount,
  popOverOptions,
  navItems,
  currentPath,
  pushEvent,
  hasMoreButton,
}: DesktopSidebarProps) {
  return (
    <>
      <NavSidebar onMouseEnter={onOpen} open={open} columnCount={columnCount}>
        <Box minHeight="100vh" display="flex" flexDirection="column">
          <MenuWrapper>
            <PlatformLogo width={32} platformKey={platformKey} />
          </MenuWrapper>
          <Box display="flex" flexGrow={1}>
            {navItems.map((items, i) => (
              <DesktopNavigationColumn
                key={items[0].label}
                navItems={items}
                open={open}
                currentPath={currentPath}
                pushEvent={pushEvent}
                showMore={i === 0 && hasMoreButton}
                onOpen={onOpen}
                onClose={onClose}
              />
            ))}
          </Box>
          <Box justifySelf="flex-end" display="flex" px={3.5} pb={2}>
            <PopMenu Trigger={<UserIcon />} options={popOverOptions} />
          </Box>
        </Box>
      </NavSidebar>
      <NavSidebarPlaceholder />
    </>
  );
}
