import { injectable, inject } from 'inversify';
import { SessionStore } from '../stores/SessionStore';
import GtmManager from './gtm/GtmManager';
import { when } from 'mobx';
import { TYPES } from '../types';

@injectable()
export default class AppInstallPrompt {
  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject('window') private window;
  @inject(GtmManager) private gtmManager;

  eventHandler = (e) => {
    e.preventDefault();
    const promptCb = () => {
      when(
        () => this.sessionStore.isLoggedIn,
        () => {
          e.prompt();
          e.userChoice.then((choiceResult) => {
            this.gtmManager.pushEvent('app.installprompt', {
              appInstallOutcome: choiceResult.outcome,
            });
          });
        },
      );

      // Remove after promtCb is called the first time
      this.window.removeEventListener('promptInstallBanner', promptCb);
    };
    return false;
  };
}
