import FrontendPluginContainer from '../FrontendPluginContainer';
import * as React from 'react';
import { FrontendPluginContainerProps } from '../FrontendPluginContainer/FrontendPluginContainer';
import { NavStore } from '../../stores/NavStore';
import {
  Translate,
  Filters,
  ContentWrapper,
  VendorsFilter,
  SingleVendorFilter,
} from '@deliveryhero/vendor-portal-components';
import MasterContentWrapper from '../../components/MasterContentWrapper';
import { match as Match } from 'react-router';
import { QueryStringParser } from '../../utils/QueryStringParser';
import Box from '../../components/Box';
import { SecondaryBar } from '../../components/SecondaryBar';
import { History } from 'history';
import styled, { css } from 'styled-components';
import { getSpacing, forBreakpoint } from '@deliveryhero/styled-material-ui';
import { Paper, Divider, PaperProps } from '@material-ui/core';
import PluginModuleStore from '../../stores/PluginModuleStore';
import PluginModule from '../../models/PluginModule';
import { observer } from 'mobx-react';
import {
  RestaurantSelectorOptions,
  SdkProvider,
} from '@deliveryhero/vendor-portal-sdk';
import { RouterProvider } from '../../utils/CustomRouter';
import { Link } from 'react-router-dom';
import { VendorStore } from '../../stores/VendorStore';
import { IConfig } from '../../config';
import { COLOR_BACKGROUND } from '../../constants';

export type MasterPluginContainerProps = FrontendPluginContainerProps & {
  navStore: NavStore;
  vendorStore: VendorStore;
  match: Match<{}>;
  history: History;
  pluginModuleStore: PluginModuleStore;
  config: IConfig;
};

const StickyContentWrapper = styled(Paper)`
  position: sticky;
  top: 64px;
  z-index: 1000;
  margin-top: -${getSpacing(7)};
  margin-bottom: ${getSpacing(4)};

  ${forBreakpoint(
    'md',
    css`
      margin-top: -${getSpacing(8)};
      top: 0;
      background-color: ${COLOR_BACKGROUND};
      box-shadow: none;
    ` as any,
  )}
`;

export default function MasterPluginContainer({
  bundleUrl,
  pluginName,
  navStore,
  vendorStore,
  match,
  history,
  pluginModuleStore,
  config,
}: MasterPluginContainerProps) {
  const pluginModule = pluginModuleStore.getModuleByName(pluginName);

  React.useEffect(() => {
    navStore.setNewNavigation({ name: '', title: '' });
  }, []);

  return (
    <>
      <SdkProvider sdk={pluginModule?.sdk}>
        {pluginModule?.isReady && (
          <PluginSecondaryBar
            pluginModule={pluginModule}
            match={match}
            history={history}
            pluginName={pluginName}
            navStore={navStore}
            vendorStore={vendorStore}
            config={config}
          />
        )}
      </SdkProvider>
      <MasterContentWrapper>
        <FrontendPluginContainer
          bundleUrl={bundleUrl}
          pluginName={pluginName}
        />
      </MasterContentWrapper>
    </>
  );
}

type PluginSecondaryBarProps = {
  pluginName: string;
  pluginModule: PluginModule;
  navStore: NavStore;
  vendorStore: VendorStore;
  match: Match<{}>;
  history: History;
  config: IConfig;
};

function InternalPluginSecondaryBar({
  pluginName,
  pluginModule,
  navStore,
  vendorStore,
  history,
  match,
  config,
}: PluginSecondaryBarProps) {
  const ActionButtons = navStore.actionButtons;
  const restaurantFilter = getRestaurantSelector();
  const navFilters = showVendorFilter()
    ? [...navStore.filters, ...restaurantFilter]
    : navStore.filters;

  return (
    <RouterProvider baseurl={match.path}>
      <SecondaryBar
        title={<Translate code={`global.menu.${pluginName.toLowerCase()}`} />}
        subtitle={navStore.navigationTitle}
        backLinkComponent={
          navStore.backUrl
            ? React.forwardRef((props, ref) => (
                <Link ref={ref as any} {...props} to={navStore.backUrl} />
              ))
            : undefined
        }
      >
        {(navFilters.length > 0 || ActionButtons) && (
          <Box display="flex" mx={-1} alignItems="flex-end">
            <Box mx={1}>
              {/* eslint-disable-next-line test-selectors/button */}
              {ActionButtons && <ActionButtons />}
            </Box>
            <Box mx={1} flexGrow={1}>
              <Filters
                children={navFilters.map<any>((elem) =>
                  React.cloneElement(elem, {
                    key: elem.props.name,
                  }),
                )}
              />
            </Box>
          </Box>
        )}
      </SecondaryBar>
      {navStore.stickyContent && (
        <StickyContentWrapper>
          <navStore.stickyContent />
          <ContentWrapper>
            <Box mx={-2}>
              <Divider />
            </Box>
          </ContentWrapper>
        </StickyContentWrapper>
      )}
    </RouterProvider>
  );

  function handleMultiVendorsFilterChange(vendors: string[]) {
    vendorStore.setSelectedVendors(vendors);
  }

  function handleSingleVendorFilterChange(vendorId: string) {
    const searchParams = QueryStringParser.parse(history.location.search);
    const basePath = config.basePath;
    const pathname = basePath
      ? history.location.pathname.replace(`/${basePath}/`, '/')
      : history.location.pathname;

    vendorStore.setCurrentVendorId(vendorId);
    history.push(
      pathname +
        QueryStringParser.stringify({
          ...searchParams,
          vendor: vendorId,
        }),
    );
  }

  function usesVendorSelection() {
    const { restaurantSelector } = pluginModule?.config;
    return (
      restaurantSelector === RestaurantSelectorOptions.MULTIPLE_VENDORS ||
      restaurantSelector === RestaurantSelectorOptions.SINGLE_VENDOR
    );
  }

  function showVendorFilter() {
    const usesVendor = usesVendorSelection();
    return usesVendor && vendorStore.allVendors.length > 1;
  }

  function getRestaurantSelector(): JSX.Element[] {
    switch (pluginModule?.config.restaurantSelector) {
      case RestaurantSelectorOptions.NONE:
        return [];
      case RestaurantSelectorOptions.MULTIPLE_VENDORS:
        return [
          <VendorsFilter
            selectedVendorIds={vendorStore.selectedVendorIds}
            onChange={handleMultiVendorsFilterChange}
          />,
        ];
      case RestaurantSelectorOptions.SINGLE_VENDOR:
      default:
        return [
          <SingleVendorFilter
            selectedVendorId={vendorStore.currentVendorId}
            onChange={handleSingleVendorFilterChange}
          />,
        ];
    }
  }
}

const PluginSecondaryBar = observer(InternalPluginSecondaryBar);
