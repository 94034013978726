import * as React from 'react';
import { InfoItemsWrapper, InfoItem } from '../../common/components/InfoItem';
import { getStaticPath } from '../../../../../utils/PathHelper';

export const InfoGroup = () => (
  <InfoItemsWrapper>
    <InfoItem
      title={''}
      description={''}
      imagePath={getStaticPath('images/rapp-welcome-info-1.jpg')}
    />
  </InfoItemsWrapper>
);
