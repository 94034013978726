/**
 * Factory to create a `reduce` function to chunk array in a multiple smaller arrays with a max size
 * @param size max size for the chunked arrays
 */
export function reduceChunk<T>(size: number) {
  return (groups: T[][], item: T) => {
    let currentGroup = groups.pop() || [];
    if (currentGroup.length >= size) {
      groups.push(currentGroup);
      currentGroup = [];
    }
    currentGroup.push(item);
    groups.push(currentGroup);
    return groups;
  };
}
