import GtmAuthenticatedUserEvent from './GtmAuthenticatedUserEvent';

export default class GtmVirtualPageViewEvent extends GtmAuthenticatedUserEvent {
  async pushEvent(pageType: string, page: string, data?: {}): Promise<void> {
    await super.pushEvent('virtualPageView', {
      ...data,
      pageType: pageType,
      page: page,
    });
  }
}
