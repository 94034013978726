import { injectable, inject, postConstruct } from 'inversify';
import { observable, computed, action } from 'mobx';
import { History } from 'history';
import {
  INewNavigationParameters,
  OnBackClickFunc,
} from '@deliveryhero/vendor-portal-sdk';
import { TranslationsStore } from './TranslationsStore';
import { TYPES } from '../types';
import { VendorStore } from './VendorStore';

export interface INavigationItem {
  name: string;
  label: string;
  route: string;
  fullPath?: string;
  callback?: Function;
  preventPushState?: boolean;
  actionButtons?: React.ComponentType;
}

@injectable()
export class NavStore {
  @observable currentNavigation: INavigationItem[] = [];
  @observable basePath: string;
  @observable currentNavigationName: string;
  /** @deprecated seems to be not used anywhere */
  @observable selectedPage: string;
  @observable navigationTitle: React.ReactNode = '';
  @observable hasBackButton: boolean = false;
  @observable backUrl: string;
  @observable onBackClick: null | OnBackClickFunc = null;
  @observable showBottomNav: boolean = true;
  @observable actionButtons?: React.ComponentType;
  @observable stickyContent: React.ComponentType;
  @observable hasUnsavedChanges: () => null | Promise<boolean> = null;
  @observable filters: JSX.Element[] = [];

  @inject('history') private history: History;
  @inject(TranslationsStore)
  private translationsStore: TranslationsStore;
  @inject(TYPES.VendorStore) private vendorStore: VendorStore;

  @postConstruct() init() {
    this.history.listen((location) => {
      this.navigationTitle = '';
      this.selectByFullPath(location.pathname);
      this.navigationTitle = '';
    });
  }

  /**
   * Set new navigation options like navigation title, back button behaviour, sticky content etc. (used by SDK)
   * @param options Options for setting new navigation
   */
  @action setNewNavigation({
    name,
    title,
    titleCode,
    basePath = '',
    /** @deprecated is used to set selectedPage, which is not used apparently */
    defaultPage,
    items = [],
    hasBackButton = false,
    backUrl,
    onBackClick,
    showBottomNav = true,
    actionButtons = null,
    hasUnsavedChanges,
    stickyContent,
    filters = [],
  }: INewNavigationParameters) {
    this.currentNavigation = [...items];
    this.basePath = basePath;
    this.currentNavigation
      .filter((navItem) => navItem.fullPath === undefined)
      .forEach((navItem) => this.addFullPath(navItem));
    this.stickyContent = stickyContent;
    this.currentNavigationName = name;
    this.setNavigationTitle(title, titleCode);
    this.hasBackButton = hasBackButton;
    this.backUrl = this.hasBackButton ? backUrl : undefined;
    this.showBottomNav = showBottomNav;
    this.actionButtons = actionButtons;
    this.hasUnsavedChanges = hasUnsavedChanges;
    this.filters = filters;

    const selectedByPath = this.selectByFullPath(
      this.history.location.pathname,
    );
    if (!selectedByPath && this.currentNavigation.length > 0) {
      this.selectedPage = defaultPage
        ? defaultPage
        : this.currentNavigation[0].name;
    }

    this.onBackClick = onBackClick;
  }

  private selectByFullPath(fullPath: string): boolean {
    for (let i = 0; i < this.currentNavigation.length; i++) {
      const navItem = this.currentNavigation[i];
      if (navItem.fullPath === fullPath) {
        this.selectedPage = navItem.name;
        if (navItem.callback) {
          navItem.callback();
        }
        return true;
      }
    }

    return false;
  }

  private addFullPath(navItem: INavigationItem) {
    navItem.fullPath = `${this.basePath}/${navItem.route}`;
  }

  @action private setNavigationTitle(
    title?: React.ReactNode,
    titleCode?: string,
  ) {
    this.navigationTitle = '';
    if (title) {
      this.navigationTitle = title;
    } else if (titleCode) {
      this.navigationTitle = this.translationsStore.translate(titleCode);
    }
  }
}
