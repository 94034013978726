import { inject, injectable } from 'inversify';
import { IBrazeConfig } from '../config';
import { TYPES, BrazeSDK } from '../types';
import Session from '../models/Session';

@injectable()
export class BrazeManager {
  @inject(TYPES.BrazeSDK) private brazeSdkProvider;
  @inject('window') private window: Window;
  private brazeSdk: BrazeSDK;

  private initialized = false;

  async init(brazeConfig: IBrazeConfig) {
    this.brazeSdk = await this.brazeSdkProvider();

    // Export braze to the global window object to be used by Google Tag manager
    (this.window as any).appboy = this.brazeSdk;

    this.brazeSdk.initialize(brazeConfig.apiKey, {
      baseUrl: brazeConfig.baseUrl,
    });
    this.brazeSdk.display.automaticallyShowNewInAppMessages();
    this.brazeSdk.openSession();
    this.initialized = true;
  }

  initMock() {
    // Overwrite `logCustomEvent` to not break the app for users not having braze deployed
    (this.window as any).appboy = {
      logCustomEvent: () => {},
      changeUser: () => {},
      getUser: () => ({
        setLastName: () => {},
        setCountry: () => {},
        setHomeCity: () => {},
        setEmail: () => {},
      }),
    };
  }

  setUserData(session: Session): void {
    if (!this.initialized) {
      return;
    }
    const platform = session.platformIds[0];

    this.brazeSdk.changeUser(session.getUserData('uniqueUserId'));
    this.brazeSdk
      .getUser()
      .setCustomUserAttribute('userId', session.getUserData('uniqueUserId'));
    this.brazeSdk.getUser().setCustomUserAttribute('platform', platform);
  }
}
