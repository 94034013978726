import { injectable, inject, postConstruct } from 'inversify';

/**
 * Abstraction for the browser's page visibility event
 */
@injectable()
export default class PageVisibilityManager {
  private isFeatureAvailable: boolean;
  private hiddenProperty: string;
  private visibilityChangeEvent: string;

  @inject('document') private document: any;

  @postConstruct() initialize(): void {
    if (typeof this.document.hidden !== 'undefined') {
      this.hiddenProperty = 'hidden';
      this.visibilityChangeEvent = 'visibilitychange';
    } else if (typeof this.document.msHidden !== 'undefined') {
      this.hiddenProperty = 'msHidden';
      this.visibilityChangeEvent = 'msvisibilitychange';
    } else if (typeof this.document.webkitHidden !== 'undefined') {
      this.hiddenProperty = 'webkitHidden';
      this.visibilityChangeEvent = 'webkitvisibilitychange';
    }

    this.isFeatureAvailable =
      typeof this.document.addEventListener !== 'undefined' &&
      typeof this.document[this.hiddenProperty] !== 'undefined';
  }

  isVisibilityFeatureAvailable(): boolean {
    return this.isFeatureAvailable;
  }

  isPageVisible(): boolean {
    return !this.isFeatureAvailable || !this.document[this.hiddenProperty];
  }

  /**
   * Add page visibility event callback
   * @param callback Function to call when page visibility event happens
   */
  addEventListener(callback): () => void {
    if (!this.isFeatureAvailable) {
      return () => {};
    }

    this.document.addEventListener(this.visibilityChangeEvent, callback, false);

    // Return a function to remove the listener
    return () => {
      this.document.removeEventListener(
        this.visibilityChangeEvent,
        callback,
        false,
      );
    };
  }
}
