import { History } from 'history';
import { IntlProvider } from 'react-intl';
import { Router, Route, Switch } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';
import { isWidthDown } from '@material-ui/core/withWidth';

let DevTools;
if (process.env.NODE_ENV !== 'production') {
  DevTools = require('mobx-react-devtools');
}

import { observer } from 'mobx-react';

import * as React from 'react';

import { SessionStore } from '../../stores/SessionStore';

import AppDialog from '../../components/AppDialog';

import RefreshTokenHandler from './RefreshTokenHandler';
import { CenteredCircularProgress } from '@deliveryhero/vendor-portal-components';
import { observable, reaction } from 'mobx';

import { QueryStringParser } from '../../utils/QueryStringParser';
import LoadingManager from '../../utils/LoadingManager';
import { IDevTools } from '../../utils/DevTools';

import { StylesProvider } from '@material-ui/core/styles';
import { PluginStore } from '../../stores/PluginStore';
import { ErrorMessage } from '@deliveryhero/vendor-portal-components';
import { Translate } from '../../components/Translate';
import Routes from './Routes';
import MobileNav from '../../components/MobileNav';
import { NavStore } from '../../stores/NavStore';
import { LanguageStore } from '../../stores/LanguageStore';
import { LocaleStore } from '../../stores/LocaleStore';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { IConfig } from '../../config';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import FwfStore from '../../stores/FwfStore';
import { FwfProvider } from '../../components/fwf';
import { VendorStore } from '../../stores/VendorStore';

type AppProps = {
  width: Breakpoint;
  sessionStore: SessionStore;
  translationStore: TranslationsStore;
  localeStore: LocaleStore;
  pluginStore: PluginStore;
  history: History;
  devTools: IDevTools;
  loadingManager: LoadingManager;
  navStore: NavStore;
  config: IConfig;
  fwfStore: FwfStore;
  languageStore: LanguageStore;
  vendorStore: VendorStore;
};

@observer
export default class App extends React.Component<AppProps> {
  testCounter: number = 0;

  componentDidMount() {
    this.props.localeStore.init();
    this.initLocale();

    reaction(
      () => this.props.sessionStore.jwtData,
      () => this.setLocaleByJwt(),
      { fireImmediately: true },
    );
  }

  render() {
    const locale = this.props.languageStore.currentLanguage.replace('_', '-');

    return (
      <StylesProvider injectFirst>
        <IntlProvider locale={locale}>
          <FwfProvider client={this.props.fwfStore.mainFwfClient}>
            <StickyContainer>
              <Router history={this.props.history}>
                <div>
                  {this.props.loadingManager.isLoading ? (
                    <CenteredCircularProgress />
                  ) : (
                    this.getBlockableContent()
                  )}
                  <RefreshTokenHandler />
                  <AppDialog />
                  {DevTools && this.props.devTools.debug ? <DevTools /> : null}
                </div>
              </Router>
            </StickyContainer>
          </FwfProvider>
        </IntlProvider>
      </StylesProvider>
    );
  }

  private getBlockableContent() {
    const mobileNav = isWidthDown('sm', this.props.width) && <MobileNav />;
    return (
      <>
        <Switch>
          <Routes />
        </Switch>
        {this.props.pluginStore.isDirty &&
        this.props.pluginStore.frontendPlugins.length === 0 ? (
          <ErrorMessage
            title={<Translate code="global.error.no_plugins.headline" />}
            message={<Translate code="global.error.no_plugins.message" />}
          />
        ) : null}
        {mobileNav}
      </>
    );
  }

  private initLocale() {
    const searchParams = QueryStringParser.parse(window.location.search);

    if (searchParams.locale) {
      this.props.localeStore.locale = searchParams.locale;
    }
  }

  private setLocaleByJwt() {
    const locale = this.props.sessionStore.getUserData('locale');
    if (locale) {
      this.props.localeStore.locale = locale.substring(0, 2);
    }
  }
}
