export class QueryStringParser {
  /**
   * Parse URL search query string to object
   * @param search search query string from URL
   */
  static parse(search: string): any {
    const entries = search.substring(1).split('&');
    if (entries[0] === '') {
      return {};
    }

    return entries.reduce((obj, element) => {
      const arr = element.split('=');
      obj[arr[0]] = arr[1];
      return obj;
    }, {} as any);
  }

  /**
   * Transforms object to search query string
   * @param searchObj object to transform to search query string
   */
  static stringify(searchObj: any): string {
    if (!searchObj) {
      return '';
    }

    return Object.keys(searchObj)
      .map((key) => [key, searchObj[key]])
      .filter(([, value]) => value !== undefined && value !== null)
      .reduce(
        (searchString, keyValuePair): string =>
          `${searchString}${keyValuePair[0]}=${keyValuePair[1]}&`,
        '?',
      )
      .slice(0, -1);
  }
}
