import { PasswordState } from './PasswordContainer';

const getErrorsFromState = (state: PasswordState) => {
  const errors: { [x: string]: string } = {};

  const isTooShort = state.password.length < 8;
  const isTooLong = state.password.length > 128;

  if (isTooShort) {
    errors.password = 'global.password_reset.errors.too_short';
  }

  if (isTooLong) {
    errors.password = 'global.password_reset.errors.too_long';
  }

  if (!isTooShort && !isTooLong && state.password !== state.passwordRepeat) {
    errors.passwordRepeat = errors.password =
      'global.password_reset.errors.no_match';
  }

  return errors;
};

export default getErrorsFromState;
