import * as React from 'react';

import { ServerError as _ServerError } from '@deliveryhero/vendor-portal-components';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { withDependencies } from '@deliveryhero/react-inversify-provider';

const ServerError = withDependencies({
  translationsStore: TranslationsStore,
})((props) => (
  <_ServerError
    title={props.translationsStore.translate(
      'global.error.server_error.headline',
    )}
    message={props.translationsStore.translate(
      'global.error.server_error.message',
    )}
    {...props}
  />
));

export default ServerError;
