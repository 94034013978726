import { Model, primitiveProp } from '@deliveryhero/mobx-model/lib';

export interface ICurrency {
  code: string;
  symbol: string;
}

export class Currency extends Model<ICurrency> {
  @primitiveProp() code: string;
  @primitiveProp() symbol: string;

  constructor(props, defaults) {
    super();
    this.fromJson(props, defaults);
  }
}
