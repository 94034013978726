import * as React from 'react';
import { action, observable, reaction } from 'mobx';
import styled from 'styled-components';
import ReactSVG from 'react-svg';
import FallbackIcon from '@material-ui/icons/Code';
import { History } from 'history';
import { Plugin } from '../models/Plugin';
import { NavStore } from '../stores/NavStore';
import { PluginStore } from '../stores/PluginStore';
import GtmAuthenticatedUserEvent from '../utils/gtm/GtmAuthenticatedUserEvent';
import { TranslationsStore } from '../stores/TranslationsStore';
import { SessionStore } from '../stores/SessionStore';
import { getStaticPath } from '../utils/PathHelper';

export type StyledReactSVGProps = {
  size: number;
};

export const StyledReactSVG = styled(ReactSVG)`
  width: ${(props: StyledReactSVGProps) => props.size}px;
  height: ${(props) => props.size}px;
  margin: 4px;

  svg {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    fill: currentColor;
  }

  path {
    fill: currentColor;
  }
`;

export interface INavItem {
  name: string;
  route: string;
  icon: JSX.Element;
  plugin?: Plugin;
}

export type BaseNavProps = {
  navStore: NavStore;
  history: History;
  pluginStore: PluginStore;
  authenticatedUserEvent: GtmAuthenticatedUserEvent;
  translationsStore: TranslationsStore;
  sessionStore: SessionStore;
};

export default class BaseNav<T = {}, S = {}> extends React.Component<
  BaseNavProps & T,
  S
> {
  @observable selectedMainNavIndex = 0;
  @observable selectedMoreNavIndex = 0;
  @observable mainNavItems = [];
  @observable moreNavItems = [];

  protected readonly iconSize: number = 24;

  private pluginsReactionDisposer: () => void;
  private pluginsMenuMoreReactionDisposer: () => void;
  private historyListenDisposer: () => void;

  componentDidMount() {
    this.historyListenDisposer = this.props.history.listen(
      this.locationChangeHandler,
    );
    this.pluginsReactionDisposer = reaction(
      () => this.props.pluginStore.pluginsForMenuItems,
      () => this.handlePluginsChange(),
      { fireImmediately: true },
    );
    this.pluginsMenuMoreReactionDisposer = reaction(
      () => this.props.pluginStore.pluginsForMoreMenuItems,
      () => this.handlePluginsChange(),
      { fireImmediately: true },
    );
  }

  componentWillUnmount() {
    this.pluginsReactionDisposer();
    this.pluginsMenuMoreReactionDisposer();
    this.historyListenDisposer();
  }

  protected handleLinkClick(navItem: INavItem) {
    this.props.authenticatedUserEvent.pushEvent(`${navItem.name}.opened`);
    this.props.history.push(navItem.route);
  }

  @action protected locationChangeHandler = (location: any) => {
    this.selectedMainNavIndex = -1;
    this.selectedMoreNavIndex = -1;
    const currentPluginPathSegment = (location.pathname.match(
      /^(\/.+?)(?:$|\/)/,
    ) || [])[1];

    // Do it for main nav
    this.mainNavItems.forEach((navItem, index) => {
      if (navItem.activeOn.indexOf(currentPluginPathSegment) >= 0) {
        this.selectedMainNavIndex = index;
      }
    });

    // Do it for more nav
    this.moreNavItems.forEach((navItem, index) => {
      if (navItem.activeOn.indexOf(currentPluginPathSegment) >= 0) {
        this.selectedMoreNavIndex = index;
      }
    });
  };

  @action protected handlePluginsChange() {
    this.mainNavItems = this.mapMenuItemsToNavItems(
      this.props.pluginStore.pluginsForMenuItems,
    );
    this.moreNavItems = this.mapMenuItemsToNavItems(
      this.props.pluginStore.pluginsForMoreMenuItems,
    );

    this.locationChangeHandler(this.props.history.location);
  }

  protected mapMenuItemsToNavItems(items: Plugin[]) {
    return items.map((plugin) => ({
      name: plugin.code.toLowerCase(),
      route: plugin.route,
      icon: plugin.menuIcon ? (
        <StyledReactSVG
          size={this.iconSize}
          path={getStaticPath(plugin.menuIcon?.url)}
        />
      ) : (
        <FallbackIcon />
      ),
      plugin: plugin,
      activeOn: [plugin.route],
    }));
  }
}
