import { injectable, inject } from 'inversify';
import GtmManager from './GtmManager';

export interface IGtmEvent {
  getName(): string;
  pushEvent(): void;
}

@injectable()
export abstract class GtmEvent {
  @inject(GtmManager) protected gtmManager: GtmManager;
}
