import * as React from 'react';
import styled, { css } from 'styled-components';
import { getSpacing, forBreakpoint } from '@deliveryhero/styled-material-ui';
import { Translate } from '../../components/Translate';
import { Typography } from '@deliveryhero/vendor-portal-components';

const ImpersonatorWarningWrapper = styled.div`
  background-color: #d61f26;
  color: #fff;
  padding: ${getSpacing(2)};
  margin: -${getSpacing(4)} 0 ${getSpacing(4)};

  ${forBreakpoint(
    'md',
    css`
      margin-top: 0;
    ` as any,
  )}
`;

export function ImpersonatorWarning() {
  return (
    <ImpersonatorWarningWrapper data-testid="impersonator-message">
      <Typography variant="subtitle">
        <Translate code="global.impersonator_message" />
      </Typography>
    </ImpersonatorWarningWrapper>
  );
}
