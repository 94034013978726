import { injectable, inject } from 'inversify';

import GtmManager from './GtmManager';
import { SessionStore } from '../../stores/SessionStore';
import GtmAuthenticatedUserEvent from './GtmAuthenticatedUserEvent';
import { IGtmEvent } from './GtmEvent';
import { when } from 'mobx';
import { TYPES } from '../../types';
import { VendorStore } from '../../stores/VendorStore';
import { IVendorData } from '@deliveryhero/vendor-portal-sdk';

interface ILoginSucceededEvent {
  userId: string;
  loginType: string;
  email: string;
  fullName: string;
  countryCode: string;
  city: string;
  lastLogin: Date;
  shopId: string;
  userRole: string;
}

@injectable()
export default class GtmLoginSucceededEvent extends GtmAuthenticatedUserEvent
  implements IGtmEvent {
  @inject(GtmManager) protected gtmManager: GtmManager;
  @inject(TYPES.SessionStore) protected sessionStore: SessionStore;
  @inject(TYPES.VendorStore) protected vendorStore: VendorStore;

  getName(): string {
    return 'login.succeeded';
  }

  async pushEvent() {
    await when(
      () => this.vendorStore.vendors.size > 0,
      () => {
        const currentVendor = this.vendorStore.currentVendor;
        this.gtmManager.pushEvent(
          this.getName(),
          this.getObject(currentVendor),
        );
      },
    );
  }

  private getObject(vendor: IVendorData): ILoginSucceededEvent {
    return {
      userId: `master-${this.sessionStore.getUserData('userId')}`,
      loginType: 'Email',
      email: this.sessionStore.getUserData('email'),
      fullName: this.sessionStore.getUserData('name'),
      countryCode: this.sessionStore.getVendorCountry(),
      city: vendor.address.city.name,
      lastLogin: new Date(),
      shopId: vendor.id,
      userRole: this.sessionStore.jwtData?.user?.role,
    };
  }
}
