import React from 'react';
import { SimpleMarkdown } from '@deliveryhero/vendor-portal-components';
import { container } from '../dependencies';
import { TranslationsStore } from '../stores/TranslationsStore';

interface ITranslateProps {
  code: string;
  params?: Record<string, string | number>;
  showKeyWhenUndefined?: boolean;
  component?: React.ComponentType<any> | string;
}

const TranslateComponent: React.FC<ITranslateProps> = ({
  code,
  params,
  showKeyWhenUndefined = false,
  component = React.Fragment,
}) => {
  const translationStore = container.get<TranslationsStore>(TranslationsStore);
  const Component = component;
  const text = translationStore.translate(code, params);

  if (!showKeyWhenUndefined && (!text || text === code)) {
    return null;
  }

  return <Component>{<SimpleMarkdown text={text} />}</Component>;
};

export const Translate = TranslateComponent;
Translate.displayName = 'Translate';
