import * as React from 'react';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import styled from 'styled-components';
import _FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Translate } from '../../../../components/Translate';
import { Headline } from '../Content';
import Button from '../../components/Button';
import PasswordField from '../../../../components/PasswordField';
import { ResetPasswordSubHeader } from '../Content';

const FormControl = styled(_FormControl)`
  margin: ${getSpacing(2)} 0;
`;

export const Box = styled.div`
  padding: 0 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export type WelcomePasswordViewProps = {
  password?: string;
  passwordRepeat?: string;
  onChange: Function;
  getError: (key: string) => any;
  onSubmit: (e: any) => void;
  onBackClick: () => void;
  currentPlatform: any;
  skipIntro?: boolean;
};

type PasswordInputProps = {
  name: string;
  value: string;
  label: JSX.Element | string;
  onChange: (event: any) => void;
  error: any;
};

const PasswordInput = ({
  label,
  name,
  value,
  onChange,
  error,
}: PasswordInputProps) => (
  <FormControl fullWidth={true}>
    <InputLabel>{label}</InputLabel>
    <PasswordField
      name={name}
      value={value}
      error={!!error}
      onChange={onChange}
      data-testid={`reset-${name}-field`}
      fullWidth={true}
    />
    <FormHelperText error={!!error}>{error}</FormHelperText>
  </FormControl>
);

const PasswordView = ({
  onChange,
  password,
  passwordRepeat,
  getError,
  onSubmit,
  onBackClick,
  currentPlatform,
  skipIntro,
}: WelcomePasswordViewProps) => (
  <Box>
    <Headline platform={currentPlatform} />

    <ResetPasswordSubHeader platform={currentPlatform} />

    <form id="password-form" onSubmit={onSubmit}>
      <PasswordInput
        name="password"
        label={<Translate code="global.password_reset.password" />}
        value={password}
        onChange={onChange('password')}
        error={getError('password')}
      />

      <PasswordInput
        name="passwordRepeat"
        label={<Translate code="global.password_reset.password_repeat" />}
        value={passwordRepeat}
        onChange={onChange('passwordRepeat')}
        error={getError('passwordRepeat')}
      />
      <Button
        variant="contained"
        color="primary"
        data-testid="welcome-password-save-btn"
        type="submit"
        fullWidth={true}
      >
        <Translate code="global.save" />
      </Button>
    </form>
    {!skipIntro && (
      <Button
        color="primary"
        onClick={onBackClick}
        data-testid="welcome-password-back-btn"
      >
        <Translate code="global.back" />
      </Button>
    )}
  </Box>
);

export default PasswordView;
