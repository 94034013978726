import { injectable } from 'inversify';
import { observable, computed, IObservableArray } from 'mobx';

/**
 * Global loading manager. Manages multiple loading states from different places.
 * Has `isLoading` method that exposes if there is at least one loading status running.
 * Used to block the UI when there are things loading.
 */
@injectable()
export default class LoadingManager {
  @observable private loadingStates: IObservableArray<
    string
  > = observable.array();

  /**
   * Add a new loading state when something starts loading
   * @param stateName name of the loading state, can be any unique string
   */
  add(stateName: string) {
    if (!this.loadingStates.find((item) => item === stateName)) {
      this.loadingStates.push(stateName);
    }
  }

  /**
   * Removes an existing loading state when something finishes loading
   * @param stateName name of the loading state, can be any unique string
   */
  remove(stateName: string) {
    this.loadingStates.remove(stateName);
  }

  /**
   * Clears all loading statuses
   */
  clear() {
    this.loadingStates.clear();
  }

  /**
   * Indicates if there is at least one running loading state registered
   */
  @computed get isLoading(): boolean {
    return this.loadingStates.length > 0;
  }
}
