import { injectable, inject, postConstruct } from 'inversify';
import { History } from 'history';
import {
  computed,
  observable,
  ObservableMap,
  action,
  reaction,
  IObservableArray,
} from 'mobx';
import { Api } from '@deliveryhero/portal-api-client';
import {
  IVendorData,
  VendorObserverCallback,
  SelectedVendorsObserverCallback,
} from '@deliveryhero/vendor-portal-sdk';
import { DialogStore } from './DialogStore';
import { Vendor } from '../models/Vendor';
import { TYPES } from '../types';
import { UtilsDateStore } from './UtilsDateStore';
import { SessionStore } from './SessionStore';

type SymMap<T> = Map<Symbol, T>;

@injectable()
export class VendorStore {
  // Used for checking if vendors were requested already to not fetch them again
  @observable requestedVendors = [];

  @observable vendors: ObservableMap<string, IVendorData> = observable.map<
    string,
    IVendorData
  >();
  @observable _currentVendorId: string;
  @observable selectedVendorIds: IObservableArray<string> = observable.array<
    string
  >() as any;
  @observable isLoading: boolean = false;
  @observable isFetched: boolean = false;

  @inject('apiUrl') private baseUrl: string;
  @inject(TYPES.GlobalApi) private api: Api;
  @inject(TYPES.UtilsDateStore) private utilsDateStore: UtilsDateStore;
  @inject('window') private window: Window;
  @inject(DialogStore) private dialogStore: DialogStore;
  @inject('history') private history: History;
  @inject(TYPES.SessionStore) private sessionStore: SessionStore;

  private observerCallbacks: SymMap<VendorObserverCallback> = new Map();
  private selectedObserverCallbacks: SymMap<
    SelectedVendorsObserverCallback
  > = new Map();

  @postConstruct() init() {
    reaction(
      () => this.currentVendor,
      async (vendor) => {
        Array.from(this.observerCallbacks.values()).forEach((cb) => cb(vendor));
      },
    );

    reaction(
      () => this.selectedVendors,
      (vendors) => {
        Array.from(this.selectedObserverCallbacks.values()).forEach((cb) =>
          cb(vendors),
        );
      },
    );
  }

  @computed get currentVendorId(): string {
    return this._currentVendorId || this.allVendors[0]?.id;
  }

  @computed get currentVendor(): IVendorData {
    return this.allVendors.find((vendor) => vendor.id === this.currentVendorId);
  }

  @computed get selectedVendors(): IVendorData[] {
    return this.selectedVendorIds
      .toJS()
      .map((vendorId) =>
        this.allVendors.find((vendor) => vendor.id === vendorId),
      )
      .filter((restaurant) => !!restaurant);
  }

  @computed get allVendors(): IVendorData[] {
    return Array.from(this.vendors || [])
      .map(([_, vendor]) => vendor)
      .sort(sortVendorsByName);
  }

  @computed get allPlatforms(): string[] {
    const platforms = Array.from(this.vendors || []).map(
      ([_, vendor]) => vendor.id.split(';')[0],
    );

    return [...new Set(platforms)];
  }

  @computed get isVendorAvailable(): boolean {
    return !!(
      this.vendors.get(this._currentVendorId) ||
      Array.from(this.vendors.values())[0]
    );
  }

  /**
   * The return value is "true" if at least one vendor is a keyAccount
   */
  @computed get hasKeyAccount(): boolean {
    return this.allVendors.reduce(
      (acc, vendor) => acc || vendor.keyAccount,
      false,
    );
  }

  /**
   * Get unique vertical types among all vendors
   */
  @computed get verticalTypes(): string[] {
    return Array.from(
      new Set(this.allVendors.map((vendor) => vendor.verticalType)),
    );
  }

  /**
   * Get unique delivery types among all vendors
   */
  @computed get deliveryTypes(): string[] {
    return Array.from(
      new Set(
        this.allVendors.reduce(
          (acc, vendor) => [...acc, ...vendor.deliveryTypes],
          [],
        ),
      ),
    );
  }

  @action setCurrentVendorId(vendorId: string): void {
    this._currentVendorId = vendorId;
  }

  setSelectedVendors(vendorIds: string[]) {
    this.selectedVendorIds.replace(
      Array.from(new Set(vendorIds)).filter((id) =>
        // Filter out platforms that are not available
        this.allVendors.find((vendor) => id === vendor.id),
      ),
    );

    const writableVendorIds =
      this.selectedVendorIds.length === this.allVendors.length
        ? []
        : this.selectedVendorIds;

    this.window.localStorage.setItem(
      'selectedVendorIds',
      JSON.stringify(writableVendorIds),
    );
  }

  clearVendors(): void {
    this.vendors = observable.map();
  }

  async fetchVendors(vendorIds: string[]): Promise<any> {
    // Check if all restaurants have been already fetched, then don't refetch them
    if (this.hasRequestedVendors(vendorIds)) {
      return Promise.resolve();
    }

    this.setLoading();
    try {
      const uniqueVendorIds = [...new Set(vendorIds)];

      const response = await this.api.fetch(
        `${this.baseUrl}/vendors/${uniqueVendorIds.join(',')}`,
        200,
        { method: 'GET' },
      );

      this.selectedVendorIds.clear();
      this.utilsDateStore.setAdjustDateWithTimeZone(response.data[0].timezone);
      this.handleVendorFetch(vendorIds, response);
    } catch (err) {
      return this.handleVendorFetchFailed(vendorIds)(err);
    } finally {
      this.unsetIsLoading();
      this.isFetched = true;
    }
  }

  async fetchAllVendors() {
    const mainSession = this.sessionStore.getMainSession();
    await Promise.all([this.fetchVendors(mainSession.platformVendorIds)]);
  }

  addVendorObserver(cb: VendorObserverCallback): () => void {
    const cbSymbol = Symbol('callback');
    this.observerCallbacks.set(cbSymbol, cb);
    cb(this.currentVendor);
    return () => {
      this.observerCallbacks.delete(cbSymbol);
    };
  }

  addSelectedVendorsObserver(cb: SelectedVendorsObserverCallback): () => void {
    const cbSymbol = Symbol('callback');
    this.selectedObserverCallbacks.set(cbSymbol, cb);
    cb(this.selectedVendors);
    return () => {
      this.selectedObserverCallbacks.delete(cbSymbol);
    };
  }

  @action private handleVendorFetch(vendorIds: string[], response: any) {
    this.vendors = observable.map(
      response.data.map((vendorData: IVendorData) => [
        vendorData.id,
        new Vendor(vendorData, {}),
      ]),
    );
    this.requestedVendors = Array.from(
      new Set([...this.requestedVendors, ...vendorIds]),
    );

    const storedSelectVendorIds = JSON.parse(
      this.window.localStorage.getItem('selectedVendorIds') || '[]',
    ) as string[];
    const allVendorIds = this.allVendors.map((vendor) => vendor.id);

    // Get overlap of stored and set fetched vendor ids
    const overlappingStoredSelectVendorIds = storedSelectVendorIds.filter(
      (id) => allVendorIds.indexOf(id) > -1,
    );

    // If stored restaurants (partially) overlap with fetched vendor use stored ones, otherwise all fetched
    this.setSelectedVendors(
      overlappingStoredSelectVendorIds.length > 0
        ? overlappingStoredSelectVendorIds
        : allVendorIds,
    );
  }

  private handleVendorFetchFailed = (vendorIds: string[]) => (err) => {
    this.unsetIsLoading();
    if (err.status === 403 || err.status === 404) {
      vendorIds.forEach((id) => this.vendors.delete(id));
      this._currentVendorId = undefined;
      this.dialogStore.addDialog({
        titleCode: 'global.login.error.no_restaurant.title',
        messageCode: 'global.login.error.no_restaurant.message',
        okButtonCode: 'global.login.error.no_restaurant.close_button',
        okButtonCallback: () => {
          this.history.push('/logout');
        },
        isCancelable: false,
        isModal: true,
      });
    }

    return Promise.reject(err);
  };

  private hasRequestedVendors(ids: string[]): boolean {
    if (ids.length === 0) {
      return false;
    }

    const unknownId = ids.find(
      // Does check against requested ones, because response can include less than the requested restaurants
      // Checking against response therefore can result in infinite loop
      (id) => this.requestedVendors.indexOf(id) === -1,
    );
    return !unknownId;
  }

  private setLoading() {
    this.isLoading = true;
  }

  private unsetIsLoading() {
    this.isLoading = false;
  }
}

function sortVendorsByName(a: IVendorData, b: IVendorData) {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  if (aName < bName) {
    return -1;
  }

  if (aName > bName) {
    return 1;
  }

  return 0;
}
