import * as React from 'react';
import { PlatformLogo } from '@deliveryhero/vendor-portal-components';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';

const LogoWrapper = styled.div`
  margin: auto;
  margin-bottom: ${getSpacing(3)};
  padding-top: ${getSpacing(2)};
  display: flex;
  justify-content: center;
`;

const LogoImage = styled.div`
  margin-left: -10px;
  &:first-child {
    margin-left: 0;
    z-index: 100;
  }
`;

const LOGO_WIDTH = 64;

const BackgroundWrapper = styled.div`
  background: #1e91d6;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  background: #fff;
  padding: 0 ${getSpacing(2)};
  box-shadow: 0px 0px 50px #000000ad;
  max-width: 500px;
  margin: auto;
`;

const HeaderLogos = ({ currentPlatform }) => (
  <LogoWrapper>
    {currentPlatform.platformKeys.map((pk) => (
      <LogoImage key={pk}>
        <PlatformLogo width={LOGO_WIDTH} platformKey={pk} />
      </LogoImage>
    ))}
  </LogoWrapper>
);

const MasterFooter = styled.div`
  height: ${getSpacing(4)};
`;

const PasswordHandlingView = ({ children, currentPlatform }) => {
  return (
    <BackgroundWrapper>
      <Wrapper>
        <HeaderLogos currentPlatform={currentPlatform} />

        {children}

        <MasterFooter />
      </Wrapper>
    </BackgroundWrapper>
  );
};

export default PasswordHandlingView;
