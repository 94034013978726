import { config } from '../dependencies';

export function getStaticPath(relativePath: string): string {
  const base = config.basePath === '' ? '' : `/${config.basePath}`;

  // Checks if the path is absolute
  if (relativePath.startsWith('https://')) {
    return relativePath;
  }

  if (relativePath.startsWith('/static/')) {
    return base + relativePath;
  }

  return `${base}/static/${relativePath}`;
}
