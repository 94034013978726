import styled, { css } from 'styled-components';
import { getSpacing, forBreakpoint } from '@deliveryhero/styled-material-ui';
import {
  SecondaryBar as PcSecondaryBar,
  SecondaryBarProps,
} from '@deliveryhero/vendor-portal-components';
import * as React from 'react';

const Wrapper = styled.div`
  margin: -${getSpacing(4)} 0 ${getSpacing(3)};

  ${forBreakpoint(
    'md',
    css`
      margin: 0 0 ${getSpacing(4)};
    ` as any,
  )}
`;

export function SecondaryBar(props: SecondaryBarProps) {
  return (
    <Wrapper>
      <PcSecondaryBar {...props} />
    </Wrapper>
  );
}
