import * as React from 'react';
import {
  SidebarMenuItem,
  SidebarMenuLabel,
} from '../../components/SidebarMenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import SideMenuHeader from '../../components/SideMenuHeader';
import LanguageSelector from '../../components/LanguageSelector';
import { List, Drawer } from '@material-ui/core';
import { Translate } from '../../components/Translate';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import styled from 'styled-components';

const StyledList = styled(List)`
  margin-top: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
`;

export function MobileDrawer({ platformKey, open, onClose, userName }) {
  return (
    <Drawer open={open} anchor="left" onClose={onClose}>
      <SideMenuHeader
        platformKey={platformKey}
        text={
          <Translate
            code="master_dashboard.welcome"
            params={{ name: userName }}
          />
        }
      />
      <StyledList>
        <LanguageSelector
          renderComponent={(_label, onClick) => (
            <SidebarMenuItem
              data-testid="switch-language-button"
              icon={LanguageIcon}
              onClick={onClick}
            >
              <SidebarMenuLabel>
                <Translate code="sidebar.language" />
              </SidebarMenuLabel>
            </SidebarMenuItem>
          )}
        />
        <SidebarMenuItem
          key={'btn-sidebar-menu-logout'}
          icon={LogoutIcon}
          href="/logout"
          onClick={onClose}
          data-testid="master-menu-logout"
        >
          <SidebarMenuLabel>
            <Translate code="global.menu.logout" />
          </SidebarMenuLabel>
        </SidebarMenuItem>
      </StyledList>
    </Drawer>
  );
}
