import { injectable, inject, postConstruct } from 'inversify';

const DEFAULT_DATA = {
  eventLabel: undefined,
  eventValue: undefined,
};

@injectable()
export default class GtmManager {
  @inject('window') private window: any;

  constructor() {}

  pushEvent(event: string, data?: {}): void {
    this.window['dataLayer'].push({
      ...DEFAULT_DATA,
      ...data,
      event: event,
    });
  }

  @postConstruct() initialize(): void {
    if (this.window['dataLayer'] === undefined) {
      this.window['dataLayer'] = [];
    }
  }
}
