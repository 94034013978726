module.exports = [
  { value: 'ar', label: 'العربية' },
  { value: 'bg', label: 'Български' },
  { value: 'bn', label: 'বাংলা' },
  { value: 'my', label: 'မြန်မာစာ' },
  { value: 'de', label: 'Deutsch' },
  { value: 'de-AT', label: 'Deutsch (Österreich)' },
  { value: 'cs', label: 'Čeština' },
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'fi', label: 'Suomi' },
  { value: 'fr-CA', label: 'Français (Canada)' },
  { value: 'hu', label: 'magyar' },
  { value: 'lo', label: 'ພາສາລາວ' },
  { value: 'km', label: 'ភាសាខ្មែរ' },
  { value: 'ku-CKB', label: 'سۆرانی' },
  { value: 'ku-RM', label: 'Badînî' },
  { value: 'ms', label: 'bahasa Melayu' },
  { value: 'nb-NO', label: 'Norsk' },
  { value: 'ro', label: 'Română' },
  { value: 'sk', label: 'Slovenčina' },
  { value: 'sv', label: 'Svenska' },
  { value: 'th', label: 'ไทย' },
  { value: 'tr', label: 'Türkçe' },
  { value: 'ur', label: 'اردو' },
  { value: 'vi', label: 'Tiếng Việt' },
  { value: 'ja', label: '日本語' },
  { value: 'zh-Hans-SG', label: '简体中文' },
  { value: 'zh-Hant-HK', label: '繁體中文 (香港)' },
  { value: 'zh-Hant', label: '繁體中文 (台灣)' },
];
