import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';

export type TextOrElement = string | JSX.Element;

export type MenuOption = {
  label: TextOrElement;
  action: () => void;
};

export type Props = {
  Trigger: JSX.Element;
  options: MenuOption[];
};

export type State = {
  open: boolean;
  anchorEl: any;
};

const renderMenuOption = (onClick) => ({ label }: MenuOption, i: number) => (
  <MenuItem
    key={`menu-item-${i}`}
    data-testid={`menu-item-${i}`}
    onClick={() => onClick(i)}
  >
    {label}
  </MenuItem>
);

class PopMenu extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  render() {
    const { Trigger, options } = this.props;
    const { anchorEl } = this.state;

    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          onClick={this.openMenu.bind(this)}
          data-testid="popmenu-trigger"
          color="inherit"
        >
          {Trigger}
        </IconButton>
        <div>
          <Menu
            open={open}
            onClose={this.closeMenu.bind(this)}
            anchorEl={anchorEl}
          >
            {options.map(renderMenuOption(this.onOptionSelected.bind(this)))}
          </Menu>
        </div>
      </div>
    );
  }

  private openMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  private closeMenu() {
    this.setState({ anchorEl: null });
  }

  private onOptionSelected(i: number) {
    this.setState({ anchorEl: null });
    this.props.options[i].action();
  }
}

export default PopMenu;
