import * as React from 'react';
import { reaction, observable } from 'mobx';
import { observer } from 'mobx-react';
import { PluginStore } from '../../stores/PluginStore';
import GtmAuthenticatedUserEvent from '../../utils/gtm/GtmAuthenticatedUserEvent';
import { MoreMenuList } from './components/MoreMenuList';
import { NavStore } from '../../stores/NavStore';
import { CenteredCircularProgress } from '@deliveryhero/vendor-portal-components';
import { IPlugin } from '../../models/Plugin';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { RouteComponentProps } from 'react-router';
import { getStaticPath } from '../../utils/PathHelper';

export type MoreMenuContainerProps = RouteComponentProps<any> & {
  pluginStore: PluginStore;
  authenticatedUserEvent: GtmAuthenticatedUserEvent;
  navStore: NavStore;
  translationsStore: TranslationsStore;
};

export interface IMoreNavItem {
  name: string;
  route: string;
  iconUrl: string | null;
  label: string;
  plugin?: IPlugin;
}

@observer
export class MoreMenuContainer extends React.Component<MoreMenuContainerProps> {
  @observable menuNavItems: IMoreNavItem[] = [];

  private pluginsReactionDisposer: Function;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.navStore.setNewNavigation({
      title: this.props.translationsStore.translate('global.menu.more'),
      name: 'more',
    });
    this.pluginsReactionDisposer = reaction(
      () => this.props.pluginStore.pluginsForMoreMenuItems,
      this.handlePluginsChange.bind(this),
      { fireImmediately: true },
    );
  }

  componentWillUnmount() {
    this.pluginsReactionDisposer();
  }

  render() {
    if (this.menuNavItems.length < 1) {
      return <CenteredCircularProgress />;
    }

    return (
      <MoreMenuList
        menuItems={this.menuNavItems}
        onItemClicked={this.handleMenuListClick}
      />
    );
  }

  private handlePluginsChange() {
    this.menuNavItems = this.props.pluginStore.pluginsForMoreMenuItems.map(
      (plugin) => ({
        name: plugin.code.toLowerCase(),
        route: plugin.route,
        iconUrl: plugin.menuIcon ? getStaticPath(plugin.menuIcon.url) : null,
        label: this.props.translationsStore.translate(
          `global.menu.${plugin.code.toLowerCase()}`,
        ),
        plugin,
      }),
    );
  }

  private handleMenuListClick = (menuItem: IMoreNavItem) => {
    this.props.authenticatedUserEvent.pushEvent(`${menuItem.name}.opened`);
    this.props.history.push(menuItem.route);
  };
}
