import * as React from 'react';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { Translate } from '../../../../components/Translate';
import { PasswordHandlingContainerComponentProps } from '../../PasswordHandlingContainer';
import Headline from '../../components/Headline';
import Button from '../../components/Button';
import { Content, StartSubHeader } from '../Content';

const ActionsWrapper = styled.div`
  padding-bottom: ${getSpacing(3)};
`;

const getPlatformReadable = (platform) => {
  if (platform === 'Default') {
    return 'Restaurant Portal';
  }

  return platform;
};

const StartView = ({
  onNextClick,
  currentPlatform,
}: PasswordHandlingContainerComponentProps) => (
  <React.Fragment>
    <Headline>
      <Translate
        code="set_password.header"
        params={{
          platform: getPlatformReadable(currentPlatform.nameReadable),
        }}
      />
    </Headline>

    <StartSubHeader platform={currentPlatform} />

    <Content platform={currentPlatform} />

    <ActionsWrapper>
      <Button
        color="primary"
        variant="contained"
        onClick={onNextClick}
        fullWidth={true}
        data-testid="welcome-start-next-btn"
      >
        <Translate code="set_password.next" />
      </Button>
    </ActionsWrapper>
  </React.Fragment>
);

export default StartView;
