import { primitiveProp, Model } from '@deliveryhero/mobx-model';

export interface ICountry {
  name: string;
  code: string;
}

export class Country extends Model<ICountry> {
  @primitiveProp() name: string;
  @primitiveProp() code: string;

  constructor(props, defaults) {
    super();
    this.fromJson(props, defaults);
  }
}
