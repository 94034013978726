import * as React from 'react';
import { Typography } from '@deliveryhero/vendor-portal-components';
import { ButtonBase } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { ANIMATION_DURATION_SECONDS } from './constants';
import { TouchRippleProps } from '@material-ui/core/ButtonBase/TouchRipple';
import { PluginLink } from '../../utils/CustomRouter';
import { ElementType } from 'react';

const NavListItem = styled.li`
  padding: 0;
  margin: 0 0 ${getSpacing(1)};
`;

type NavListItemProps = ElementType<any> & {
  ref: any;
  selected: boolean;
  visible: boolean;
  open: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  component: React.ComponentType | string;
  to?: string;
  TouchRippleProps?: Partial<TouchRippleProps>;
};

const NavListItemInset = styled<NavListItemProps>(ButtonBase as any).attrs({
  TouchRippleProps: {
    center: true,
  },
})`
  position: relative;
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0 ${getSpacing(1)};
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.selected ? '#0bafed' : 'inherit')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  border-radius: ${(props) => props.theme.shape.borderRadius * 3}px;
  ${(props) =>
    !props.visible &&
    css`
      pointer-events: none;
    `}
  transition: opacity ${ANIMATION_DURATION_SECONDS *
    0.5}s, background-color ${ANIMATION_DURATION_SECONDS * 0.5}s;
  text-decoration: none;

  &:hover {
    background-color: rgba(0,0,0,0.2);
  }

  &:after {
  transition: opacity ${ANIMATION_DURATION_SECONDS * 0.5}s;
    opacity: ${(props) => (props.selected && !props.open ? 1 : 0)};
    content: '';
    background-color: #0bafed;
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
    height: 100%;
  }
`;

const NavListItemIconWrapper = styled.div`
  margin-bottom: -${getSpacing(0.25)};
`;

type DesktopNavItemProps = {
  nav: { menuIcon: JSX.Element; path?: string };
  open: boolean;
  selected: boolean;
  onClick: () => void;
  label: React.ReactNode;
  component?: React.ComponentType | string;
  visible?: boolean;
  'data-testid'?: string;
};

function InternalDesktopNavItem({
  component = PluginLink,
  nav,
  open,
  selected,
  onClick,
  label,
  visible = true,
  'data-testid': dataTestId,
}: DesktopNavItemProps) {
  return (
    <NavListItem>
      <NavListItemInset
        component={component}
        to={nav.path}
        selected={selected}
        visible={visible ? 1 : 0}
        open={open}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <NavListItemIconWrapper>{nav.menuIcon}</NavListItemIconWrapper>
        <Typography variant="caption" align="center" color="inherit">
          {label}
        </Typography>
      </NavListItemInset>
    </NavListItem>
  );
}

export const DesktopNavItem = React.memo(InternalDesktopNavItem);
