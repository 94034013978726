import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { IconButton as MuiIconButton } from '@material-ui/core';

export type LinkIconButtonProps = IconButtonProps & LinkProps;

const IconButton: any = MuiIconButton;

export default function LinkIconButton({
  component,
  ...props
}: LinkIconButtonProps) {
  // eslint-disable-next-line test-selectors/button
  return <IconButton {...props} component={Link} />;
}
