import { Model, primitiveProp, referenceProp } from '@deliveryhero/mobx-model';

import { Currency } from './Currency';
import { Address } from './Address';
import { IVendorData, IJoker } from '@deliveryhero/vendor-portal-sdk';

export class Vendor extends Model<IVendorData> {
  @primitiveProp() id: string;
  @primitiveProp() vendorId: string;
  @primitiveProp() globalEntityId: string;
  @primitiveProp() globalVendorId: string;
  @primitiveProp() chainId: string;
  @primitiveProp() chainName: string;
  @primitiveProp() billingParentId: string;
  @primitiveProp() creationDate: string;
  @primitiveProp() businessType: string;
  @primitiveProp() verticalType: string;
  @primitiveProp() keyAccount: boolean;
  @primitiveProp() deliveryTypes: string[];
  @primitiveProp() joker: IJoker;
  @primitiveProp() name: string;
  @primitiveProp() timezone: string;
  @referenceProp(Address) address: Address;
  @referenceProp(Currency) currency: Currency;

  constructor(props, defaults) {
    super();
    this.fromJson(props, defaults);
  }
}
