import * as React from 'react';
import styled from 'styled-components';
import { InfoGroup as TabletInfoGroup } from './Tablet/InfoGroup';
import { InfoGroup as RappInfoGroup } from './Rapp/InfoGroup';
import { isDeviceTypeRapp } from './isDeviceTypeRapp';

const ContentWrapper = styled.div`
  max-width: 500px;
  margin: auto;
`;

export const Content = ({ platform }) => {
  return (
    <ContentWrapper>
      {isDeviceTypeRapp(platform.platformKeys) ? (
        <RappInfoGroup />
      ) : (
        <TabletInfoGroup />
      )}
    </ContentWrapper>
  );
};
