import * as portalComponents from '@deliveryhero/vendor-portal-components';

import * as ChartComponents from './charts';
import ErrorMessage from '../errors/ErrorMessage';
import Forbidden from '../errors/Forbidden';
import NotFound from '../errors/NotFound';
import ServerError from '../errors/ServerError';
import { LieferheldLogo } from './logos/LieferheldLogo';
import { PizzaDeLogo } from './logos/PizzaDeLogo';
import { FoodoraLogo } from './logos/FoodoraLogo';

export default {
  ...portalComponents,
  ...ChartComponents,
  ErrorMessage,
  Forbidden,
  NotFound,
  ServerError,

  // Needed until all plugins have upgraded to new PlatformLogo component
  LieferheldLogo,
  PizzaDeLogo,
  FoodoraLogo,
};
