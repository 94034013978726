import * as React from 'react';

import { ForbiddenError } from '@deliveryhero/vendor-portal-components';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { TranslationsStore } from '../../stores/TranslationsStore';
const Forbidden = withDependencies({
  translationsStore: TranslationsStore,
})((props) => (
  <ForbiddenError
    title={props.translationsStore.translate('global.error.forbidden.headline')}
    message={props.translationsStore.translate(
      'global.error.forbidden.message',
    )}
    {...props}
  />
));

export default Forbidden;
