import * as React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { defaultTheme } from '../../theme';

const withThemeProviders = (Container) => (props) => (
  <MuiThemeProvider theme={defaultTheme}>
    <StyledThemeProvider theme={defaultTheme}>
      <Container {...props} />
    </StyledThemeProvider>
  </MuiThemeProvider>
);

export default withThemeProviders;
