import { Button as _Button } from '@deliveryhero/vendor-portal-components';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { ComponentType } from 'react';
import { ButtonProps } from '@material-ui/core';

const Button = styled(_Button)`
  margin: ${getSpacing(1)} 0;
  padding: ${getSpacing(1.5)} 0;
` as ComponentType<ButtonProps>;

export default Button;
