import styled, { css } from 'styled-components';
import { getSpacing, forBreakpoint } from '@deliveryhero/styled-material-ui';

export const MasterContentWrapper = styled.div`
  width: auto;
  padding: 0 ${getSpacing(2)};
  position: relative;
  min-height: calc(100vh - 200px);

  ${forBreakpoint(
    'md',
    css`
      max-width: 1312px;
      margin: auto;
    ` as any,
  )}
`;

export default MasterContentWrapper;

export const MobileFullWidthContent = styled.div`
  margin: 0 -${getSpacing(2)};

  ${forBreakpoint(
    'sm',
    css`
      margin: 0;
    ` as any,
  )}
`;
