import * as React from 'react';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import styled from 'styled-components';
import { Typography } from '@deliveryhero/vendor-portal-components';

export type Props = {
  title: string | JSX.Element;
  text: string | JSX.Element;
};

const Title = styled(Typography).attrs({ variant: 'headline' })`
  text-align: center;
  padding-bottom: ${getSpacing(2)};
`;

const Text = styled(Typography)`
  text-align: center;
  line-height: 1.5em;
  font-size: 16px;
  padding-bottom: ${getSpacing(2)};
`;

const StartPromptHead = ({ title, text }: Props) => (
  <div>
    <Title>{title}</Title>
    <Text>{text}</Text>
  </div>
);

export default StartPromptHead;
