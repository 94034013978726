import * as React from 'react';

import { NotFoundError } from '@deliveryhero/vendor-portal-components';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { TranslationsStore } from '../../stores/TranslationsStore';
const NotFound = withDependencies({
  translationsStore: TranslationsStore,
})((props) => (
  <NotFoundError
    title={props.translationsStore.translate(
      'global.error.page_not_found.headline',
    )}
    message={props.translationsStore.translate(
      'global.error.page_not_found.message',
    )}
    {...props}
  />
));

export default NotFound;
