import * as React from 'react';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { PlatformLogo } from '@deliveryhero/vendor-portal-components';

export type HeadProps = {
  text: React.ReactNode;
  platformKey: string;
};

const Wrapper = styled.div`
  padding: ${getSpacing(4)} ${getSpacing(2)} ${getSpacing(1)};
  max-width: 80vw;
`;

const LogoWrapper = styled.div`
  padding-bottom: ${getSpacing(3)};
`;

const RestaurantName = styled.div`
  padding-bottom: ${getSpacing(1)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SideMenuHead = ({ platformKey, text }: HeadProps) => (
  <Wrapper>
    <LogoWrapper>
      <PlatformLogo width={64} platformKey={platformKey} />
    </LogoWrapper>

    <RestaurantName>{text}</RestaurantName>
  </Wrapper>
);

export default SideMenuHead;
