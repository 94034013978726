import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  SIDEBAR_WIDE_COLUMN_WIDTH,
  SIDEBAR_COLUMN_WIDTH,
  ANIMATION_DURATION_SECONDS,
} from './constants';
import { ImpersonatorWarning } from './ImpersonatorWarning';
import { forBreakpoint } from '@deliveryhero/styled-material-ui';

export type ContentWrapperProps = {
  open: boolean;
  columnCount: number;
  children: React.ReactNode | React.ReactNodeArray;
  showImpersonatorMessage: boolean;
  onMouseOver?: () => void;
};

const OuterContentWrapper = styled.div<ContentWrapperProps>`
  ${forBreakpoint(
    'md',
    css`
      flex-grow: 1;
      flex-shrink: 1;
      ${(props: any) =>
        props.open &&
        css`
          overflow-x: hidden;
        `}
      position: relative;
      z-index: 3;
      min-height: 100vh;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #fff;
        pointer-events: none;
        transition: opacity 0.3s;
        opacity: ${(props: any) => (props.open ? 0.8 : 0)};
      }
    ` as any,
  )}
`;

const InnerContentWrapper = styled.div<ContentWrapperProps>`
  ${forBreakpoint(
    'md',
    css`
      ${(props: any) => {
        if (props.open) {
          const width =
            props.theme.spacing(props.columnCount * SIDEBAR_WIDE_COLUMN_WIDTH) -
            props.theme.spacing(SIDEBAR_COLUMN_WIDTH);
          return `transform: translateX(${width}px)`;
        }
      }};
      transition: transform ${ANIMATION_DURATION_SECONDS}s;
    ` as any,
  )}
`;

export const ContentWrapper = ({
  open,
  columnCount,
  children,
  onMouseOver,
  showImpersonatorMessage,
}: ContentWrapperProps) => (
  <OuterContentWrapper
    onMouseOver={onMouseOver}
    open={open}
    columnCount={columnCount}
    showImpersonatorMessage={showImpersonatorMessage}
  >
    {showImpersonatorMessage && <ImpersonatorWarning />}
    <InnerContentWrapper
      open={open}
      columnCount={columnCount}
      showImpersonatorMessage={showImpersonatorMessage}
    >
      {children}
    </InnerContentWrapper>
  </OuterContentWrapper>
);
