import { observer } from 'mobx-react';
import React from 'react';
import PasswordView from './PasswordView';
import { Redirect } from 'react-router-dom';
import getErrorsFromState from './getErrorsFromState';
import { Translate } from '../../../../components/Translate';
import { PasswordHandlingContainerComponentProps } from '../../PasswordHandlingContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { Snackbar, SnackbarContent } from '@material-ui/core';

export type PasswordProps = PasswordHandlingContainerComponentProps;

export type PasswordState = {
  password: string;
  passwordRepeat: string;
  wasSent: boolean;
  isLoading: boolean;
  errors: {
    [x: string]: string;
  };
  snackbarMessage: string;
};

const ProgressWrapper = styled.div`
  text-align: center;
  padding: ${getSpacing(4)};
`;

const ERROR_MESSAGES = {
  FIELD_NOT_ENOUGH_CHARACTERS: 'login.set_password.error.not_enough_characters',
  FIELD_CANNOT_BE_EMPTY: 'login.set_password.error.empty',
};

@observer
export default class PasswordContainer extends React.Component<
  PasswordProps,
  PasswordState
> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      password: '',
      passwordRepeat: '',
      wasSent: false,
      isLoading: false,
      errors: {},
      snackbarMessage: '',
    };

    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState({ isLoading: true });
      this.props
        .submit(this.props.token, this.state.password)
        .then(this.handleSubmitSuccess, this.handleSubmitError.bind(this));
    }
  };

  render() {
    const { snackbarMessage } = this.state;

    const snackbarOpen = snackbarMessage !== '';

    if (this.state.wasSent) {
      return <Redirect to="/login?message=resetSuccess" />;
    }

    if (this.state.isLoading) {
      return (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      );
    }

    return (
      <React.Fragment>
        <PasswordView
          password={this.state.password}
          passwordRepeat={this.state.passwordRepeat}
          onChange={this.handleChange}
          getError={this.getError}
          onSubmit={this.handleSubmit}
          onBackClick={this.props.onBackClick}
          currentPlatform={this.props.currentPlatform}
          skipIntro={this.props.skipIntro}
        />

        <Snackbar
          data-testid={'password-snackbar-error'}
          open={snackbarOpen}
          onClose={this.closeSnackbar.bind(this)}
          autoHideDuration={3000}
        >
          <SnackbarContent message={<Translate code={snackbarMessage} />} />
        </Snackbar>
      </React.Fragment>
    );
  }

  protected handleSubmitSuccess(): void {
    this.setState({
      wasSent: true,
      isLoading: false,
    });
  }

  protected handleSubmitError(error) {
    const errorMessage = this.resolveErrorMessage(error);

    this.setState({
      isLoading: false,
      snackbarMessage: errorMessage,
    });
  }

  private resolveErrorMessage(error): string {
    if (error && error.response && error.response.code === 'INVALID_TOKEN') {
      return 'login.set_password.invalid_token';
    }

    if (this.hasError(error) && this.isKnownError(error)) {
      return ERROR_MESSAGES[this.getErrorCodes(error)[0]];
    }

    return 'login.set_password.failed';
  }

  private hasError(errorResponse): boolean {
    return (
      errorResponse.response &&
      errorResponse.response.errors &&
      errorResponse.response.errors.length > 0
    );
  }

  private isKnownError(errorResponse): boolean {
    return ERROR_MESSAGES[this.getErrorCodes(errorResponse)[0]];
  }

  private getErrorCodes(errorResponse): string[] {
    return errorResponse.response.errors.map((error) => error.code);
  }

  private validate(): boolean {
    const errors = getErrorsFromState(this.state);
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  }

  private handleChange = (field) => (event) =>
    this.setState({
      [field]: event.target.value,
    } as any);

  private getError = (key) => {
    const error = this.state.errors[key];
    if (error) {
      return <Translate code={error} />;
    }
  };

  private closeSnackbar() {
    this.setState({ snackbarMessage: '' });
  }
}
