if (!window.localStorage) {
  let _data = {};
  Object.defineProperty(window, "localStorage", {
    value: {
      setItem: (id, val) => _data[id] = String(val),
      getItem: (id) => _data.hasOwnProperty(id) ? _data[id] : undefined,
      removeItem: (id) => delete _data[id],
      clear: () => _data = {},
    },
  });
}
