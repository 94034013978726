import * as React from 'react';
import StartPrompt from '../../components/StartPrompt';
import StartPromptHead from '../../components/StartPrompt/StartPromptHead';
import { Translate } from '../../components/Translate';
import { Button } from '@deliveryhero/vendor-portal-components';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import TextField from '@material-ui/core/TextField';
import { Link as _Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

export type Props = {
  platform: any;
  email: string;
  loading: boolean;
  onEmailChange;
  onSubmit;
};

const validateEmail = (email: string): boolean => {
  const regex = /\S+@\S+\.\S+/;

  return regex.test(String(email).toLowerCase());
};

const InputWrapper = styled.div`
  padding: 0 0 ${getSpacing(3)};
`;

const ButtonWrapper = styled.div`
  padding-bottom: ${getSpacing(1)};
`;

const Link = styled(_Link)`
  text-decoration: none;
`;

const LoadingWrapper = styled.div`
  margin: auto;
  text-align: center;
  padding: ${getSpacing(2)} 0;
`;

const Form = styled.form`
  margin-top: ${getSpacing(2)};
`;

const ResetPasswordView = ({
  platform,
  email,
  onEmailChange,
  onSubmit,
  loading,
}: Props) => (
  <StartPrompt platform={platform}>
    <StartPromptHead
      title={<Translate code="login.reset_password.title" />}
      text={<Translate code="login.reset_password.subtitle" />}
    />

    {loading && (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    )}

    {!loading && (
      <React.Fragment>
        <Form onSubmit={onSubmit}>
          <InputWrapper>
            <TextField
              name="email"
              value={email}
              onChange={onEmailChange}
              fullWidth={true}
              type="text"
              label={<Translate code="global.login.email" />}
              data-testid="reset-password-email-input"
            />
          </InputWrapper>

          {/* eslint-disable-next-line test-selectors/button */}
          <ButtonWrapper>
            <Button
              fullWidth={true}
              color="primary"
              type="submit"
              disabled={!validateEmail(email)}
              data-testid="reset-password-submit-button"
            >
              <Translate code="login.reset_password.submit" />
            </Button>
          </ButtonWrapper>
        </Form>

        {/* eslint-disable-next-line test-selectors/button */}
        <ButtonWrapper>
          <Link to="/" data-testid="reset-password-back">
            {/* eslint-disable-next-line test-selectors/button */}
            <Button fullWidth={true} color="primary">
              <Translate code="login.reset_password.back" />
            </Button>
          </Link>
        </ButtonWrapper>
      </React.Fragment>
    )}
  </StartPrompt>
);

export default ResetPasswordView;
