import { Model, primitiveProp } from '@deliveryhero/mobx-model';
import { computed } from 'mobx';
import { Audience } from '../stores/SessionStore';

export interface ISession {
  accessToken: string;
  accessTokenContent: IAccessTokenContent;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  subject: string;
  role?: string;
  user: IUser;
}

export interface IAccessTokenContent {
  aud: Audience;
  country: string;
  exp: number;
  iat: number;
  impersonator: boolean;
  iss: string;
  sub: string;
  version: string;
  vendors: {
    [key: string]: Array<string>;
  };
  user: IUserData;
}

export type IUserRole =
  | 'ADMIN'
  | 'MANAGER'
  | 'OPERATIONS'
  | 'REPORTS'
  | 'SUPPORT'
  | 'FINANCE';

export interface IUserData {
  locale: string;
  name: string;
  email: string;
  role: IUserRole;
  userId: string;
  uniqueUserId: string;
  region: string;
  createdAt: string;
}

export interface IUser {
  createdAt: Date;
  email: string;
}

export default class Session extends Model<ISession> {
  @primitiveProp() accessToken: string;
  @primitiveProp() accessTokenContent: IAccessTokenContent;
  @primitiveProp() refreshToken: string;
  @primitiveProp() expiresIn: number;
  @primitiveProp() tokenType: string;
  @primitiveProp() role: string;
  @primitiveProp() user: IUser;

  constructor(props) {
    super();

    const sessionInfo = { user: {}, ...props };

    if (props.user) {
      // this to extract the region and userId e.g. eu-34439b25-5ca8-4efb-a867-d9fb77b8d7de
      const [, region, userId] = /([a-z]+)-(.*)/.exec(
        props.accessTokenContent?.sub,
      );

      sessionInfo.user = {
        ...props.user,
        role: props.accessTokenContent?.user.role,
        userId,
        uniqueUserId: props.accessTokenContent?.sub,
        region,
        createdAt: new Date(props.user.createdAt),
      };
    }

    this.fromJson(sessionInfo, {});
  }

  @computed get jwtData(): IAccessTokenContent {
    return this.toJson().accessTokenContent;
  }

  @computed get audience(): Audience {
    return (this.jwtData && this.jwtData.aud) || Audience.MASTER;
  }

  @computed get subject(): string {
    return this.jwtData && this.jwtData.sub;
  }

  @computed get isImpersonator(): boolean {
    return this.jwtData && this.jwtData.impersonator;
  }

  @computed get firstVendorId(): string {
    return this.platformVendorIds[0];
  }

  @computed get hasRefreshToken(): boolean {
    return !!this.toJson().refreshToken;
  }

  @computed get platformVendorIds(): string[] {
    return Object.entries(this.accessTokenContent.vendors).reduce(
      (acc, [globalEntityId, vendorIds]) => [
        ...acc,
        ...vendorIds.map((vendorId) => `${globalEntityId};${vendorId}`),
      ],
      [],
    );
  }

  @computed get platformIds(): string[] {
    return Object.keys(this.accessTokenContent.vendors);
  }

  getUserData(prop: keyof IUserData): any {
    if (this.user[prop]) {
      return this.user[prop];
    }

    return this.jwtData && this.jwtData.user && this.jwtData.user[prop];
  }

  getVendorCountry(): string {
    return this.jwtData && this.jwtData.country;
  }
}
