import 'normalize.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { container } from './dependencies';
import { DependencyProvider } from '@deliveryhero/react-inversify-provider';
import { init } from './init';
import App from './containers/App';

const render = async (Component) => {
  // Initialize the entire app
  await init();

  ReactDOM.render(
    <DependencyProvider value={container}>
      <AppContainer>
        <Component />
      </AppContainer>
    </DependencyProvider>,
    document.getElementById('root'),
  );
};

render(App);
