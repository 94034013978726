import { withDependencies } from '@deliveryhero/react-inversify-provider';
import AuthService from '../../../services/AuthService';
import LoadingManager from '../../../utils/LoadingManager';
import RefreshTokenHandler from './RefreshTokenHandler';
import { TYPES } from '../../../types';

export default withDependencies({
  globalApi: TYPES.GlobalApi,
  authService: AuthService,
  loadingManager: LoadingManager,
  sessionStore: TYPES.SessionStore,
})(RefreshTokenHandler);
