import { injectable, inject, postConstruct } from 'inversify';
import { SessionStore } from '../../stores/SessionStore';
import GtmManager from './GtmManager';
import GtmVirtualPageViewEvent from './GtmVirtualPageViewEvent';
import { History } from 'history';
import { TYPES } from '../../types';

@injectable()
export default class GtmVirtualPageViewListener {
  private currentPage: string;

  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject(GtmVirtualPageViewEvent)
  private virtualPageViewEvent: GtmVirtualPageViewEvent;
  @inject(GtmManager) private gtmManager: GtmManager;
  @inject('history') private history: History;

  @postConstruct() initialize(): void {
    this.handleVirtualPageView(this.history.location);
    this.history.listen(this.handleVirtualPageView.bind(this));
  }

  private handleVirtualPageView(location) {
    const page = `${location.pathname}${location.hash}`;
    // Don't send the same event twice
    if (page === this.currentPage) {
      return;
    }

    this.currentPage = page;
    const pageType = this.getPageType(page);

    // Don't do anything if there is no actual page visited
    if (!pageType) {
      return;
    }

    if (this.sessionStore.isLoggedIn) {
      this.virtualPageViewEvent.pushEvent(pageType, page);
    } else {
      this.gtmManager.pushEvent('virtualPageView', {
        pageType,
        page,
      });
    }
  }

  private getPageType(page: string) {
    if (page.startsWith('/login')) {
      return 'login';
    }

    return (page.match(/^\/(.+?)(?:$|\/)/) || [])[1];
  }
}
