import { Model, primitiveProp } from '@deliveryhero/mobx-model';
import {
  IPluginConfig,
  RestaurantSelectorOptions,
} from '@deliveryhero/vendor-portal-sdk';

const DEFAULT_PLUGIN_CONFIG = {
  restaurantSelector: RestaurantSelectorOptions.SINGLE,
};

export interface IPluginModule {
  moduleName: string;
  bundleUrl: string;
  instance: any;
  promise: Promise<PluginModule>;
  config: IPluginConfig;
  scriptDomNode: HTMLScriptElement;
  sdk: any;
  isReady: boolean;
}

export default class PluginModule extends Model<IPluginModule> {
  @primitiveProp() moduleName: string;
  @primitiveProp() bundleUrl: string;
  @primitiveProp() instance: any;
  @primitiveProp() promise: Promise<void>;
  @primitiveProp() scriptDomNode: HTMLScriptElement;
  @primitiveProp() sdk: any;
  @primitiveProp() config: IPluginConfig;
  @primitiveProp() isReady: boolean;

  constructor(props, defaults) {
    super();
    this.fromJson(
      {
        isReady: false,
        ...props,
        config: mergeConfigWithDefault(props.config),
      },
      defaults,
    );
  }

  setConfig(newConfig: IPluginConfig = {}) {
    this.config = mergeConfigWithDefault(newConfig);
  }
}

function mergeConfigWithDefault(config: IPluginConfig) {
  return {
    ...DEFAULT_PLUGIN_CONFIG,
    ...config,
  };
}
