import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { PluginStore } from '../../../stores/PluginStore';
import Routes from './Routes';
import { TYPES } from '../../../types';

export default withDependencies({
  pluginStore: PluginStore,
  sessionStore: TYPES.SessionStore,
  window: 'window',
  config: 'config',
})(Routes);
