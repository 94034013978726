import styled from 'styled-components';
import * as React from 'react';
import { Typography } from '@deliveryhero/vendor-portal-components';
import MenuIcon from '@material-ui/icons/Menu';
import { Toolbar, IconButton } from '@material-ui/core';
import { getColor, getSpacing } from '@deliveryhero/styled-material-ui';
import BackIcon from '@material-ui/icons/ArrowBack';
import { Translate } from '../../components/Translate';
import LinkIconButton from '../../components/LinkIconButton';

const StyledToolbar = styled(Toolbar)`
  min-height: 64px;
`;

const NavTopBar = styled.div`
  background: #2d3645;
  color: ${getColor('common', 'white')};
  margin-bottom: ${getSpacing(4)};
  position: relative;
`;

const FixedHeader = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: -${getSpacing(1.5)};
  margin-right: ${getSpacing(4)};
`;

const StyledLinkIconButton = styled(LinkIconButton)`
  margin-left: -${getSpacing(1.5)};
  margin-right: ${getSpacing(4)};
`;

const FixedHeaderPlaceholder = styled.div`
  height: 96px;
`;

export function MobileTopBar({
  currentMenuItem,
  backUrl,
  toggleMenu,
  navigationTitle,
}) {
  return (
    <>
      <FixedHeaderPlaceholder />
      <FixedHeader>
        <NavTopBar>
          <StyledToolbar>
            {backUrl ? (
              <StyledLinkIconButton
                color="inherit"
                aria-label="Back"
                to={backUrl}
                data-testid="back-button"
              >
                <BackIcon />
              </StyledLinkIconButton>
            ) : (
              <StyledIconButton
                color="inherit"
                aria-label="Menu"
                onClick={toggleMenu}
                data-testid="master-menu-toggle"
              >
                <MenuIcon />
              </StyledIconButton>
            )}
            <Typography variant="title" color="inherit">
              {navigationTitle || (
                <Translate code={currentMenuItem && currentMenuItem.label} />
              )}
            </Typography>
          </StyledToolbar>
        </NavTopBar>
      </FixedHeader>
    </>
  );
}
