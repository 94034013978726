import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { PlatformStore } from '../../stores/PlatformStore';
import Container from './Container';
import withLanguageSwitch from '../../components/withLanguageSwitch';
import PortalSDKStore from '../../stores/PortalSDKStore';
import { withRouter } from 'react-router-dom';
import GtmAuthenticatedUserEvent from '../../utils/gtm/GtmAuthenticatedUserEvent';
import { NavStore } from '../../stores/NavStore';
import { PluginStore } from '../../stores/PluginStore';
import { TYPES } from '../../types';

export default withDependencies({
  platformStore: PlatformStore,
  vendorStore: TYPES.VendorStore,
  sessionStore: TYPES.SessionStore,
  portalSdkStore: PortalSDKStore,
  navStore: NavStore,
  history: 'history',
  window: 'window',
  availableLanguages: 'availableLanguages',
  authenticatedUserEvent: GtmAuthenticatedUserEvent,
  pluginStore: PluginStore,
})(withRouter(withLanguageSwitch(Container)));
