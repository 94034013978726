/* eslint-disable test-selectors/anchor */
/* eslint-disable test-selectors/button */
import * as React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import styled, { css } from 'styled-components';
import { getSpacing, getColor } from '@deliveryhero/styled-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { transparentize } from 'polished';
import { Theme } from '@material-ui/core';
import { Typography } from '@deliveryhero/vendor-portal-components';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

type MobileMenuItemProps = {
  icon: React.ComponentType<SvgIconProps>;
  children: any;
  onClick?: any;
  href?: string;
  active?: boolean;
  'data-testid'?: string;
};

const nullOnClick = () => null;

export const SidebarMenuLabel = styled(Typography)`
  padding-left: ${getSpacing(1)};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(3)};
`;

type LinkListItemProps = {
  to: string;
  children?: React.ReactNode;
  'data-testid'?: string;
};

const LinkListItem = (props: LinkListItemProps) => (
  <StyledLink to={props.to} data-testid={props['data-testid']}>
    {props.children}
  </StyledLink>
);

const ButtonWrapper = ({
  href,
  children,
  'data-testid': dataTestId,
}: {
  href: string;
  children: React.ReactNode;
  'data-testid'?: string;
}) => {
  if (!href) {
    return <div data-testid={dataTestId}>{children}</div>;
  }

  return (
    <LinkListItem data-testid={dataTestId} to={href}>
      {children}
    </LinkListItem>
  );
};

const ButtonBackground = styled.div`
  ${(props: { active: boolean; theme?: Theme }) =>
    props.active &&
    css`
      background-color: ${transparentize(0.95)(
        props.theme.palette.primary.main,
      )};
      color: ${getColor('primary')};
    `};
  min-width: 304px;
  position: relative;

  &:before {
    ${(props: { active: boolean; theme?: Theme }) =>
      props.active && 'content: "";'}
    position: absolute;
    background-color: ${getColor('primary')};
    height: 100%;
    width: 4px;
    top: 0;
    left: 0;
  }
`;

export const SidebarMenuItem = ({
  icon: Icon,
  onClick,
  children,
  href,
  active,
  'data-testid': dataTestId,
}: MobileMenuItemProps) => (
  <ButtonWrapper href={href} data-testid={dataTestId}>
    <ButtonBackground active={active}>
      <StyledMenuItem onClick={onClick || nullOnClick} color="primary">
        <ListItemIcon>
          <Icon color={active ? 'primary' : 'inherit'} />
        </ListItemIcon>
        {children}
      </StyledMenuItem>
    </ButtonBackground>
  </ButtonWrapper>
);
