import { inject, postConstruct, injectable } from 'inversify';
import { observable, when, reaction } from 'mobx';
import { IConfig } from '../config';
import { TYPES } from '../types';
import { VendorStore } from './VendorStore';

export class SalesforceError extends Error {}

/**
 * @deprecated We should deprecated this! It was implemented for DH Germany (Lieferheld/Pizza.de)
 * for customer care chat and was never used afterwards.
 */
@injectable()
export default class SalesforceStore {
  @observable chatOnline: boolean = false;

  private intervalId: number;
  private isInitiated: boolean = false;

  @inject('window') private window: Window;
  @inject('document') private document: Document;
  @inject('config') private config: IConfig;
  @inject(TYPES.VendorStore) private vendorStore: VendorStore;

  @postConstruct() init() {
    (this.window as any).initSalesforce = this.initSalesforce;
  }

  platformHasSalesforce(platformKey: string) {
    return !!this.getSalesforceConfigForPlatform(platformKey);
  }

  startChat(platformKey: string) {
    const { liveagent } = this.window as any;
    if (liveagent && this.platformHasSalesforce(platformKey)) {
      const salesforceConfig = this.getSalesforceConfigForPlatform(platformKey);
      liveagent.startChat(salesforceConfig.buttonId);
    }
  }

  initSalesforce = (platformKey: string) =>
    new Promise<void>(async (resolve, reject) => {
      if (this.isInitiated) {
        resolve();
        return;
      }

      this.isInitiated = true;
      const salesforceConfig = this.getSalesforceConfigForPlatform(platformKey);

      if (!salesforceConfig) {
        reject(
          new SalesforceError('No salesforce configuration found for platform'),
        );
        return;
      }

      const {
        buttonId,
        chatUrl,
        orgId,
        deploymentId,
        companyName,
      } = salesforceConfig;

      (this.window as any)._laq = [];

      (this.window as any)._laq.push(() => {
        const { liveagent } = this.window as any;
        liveagent.showWhenOnline(buttonId, this.document.createElement('div'));
        liveagent.addButtonEventHandler(buttonId, this.onButtonEvent);
        when(() => this.chatOnline, resolve);
        reaction(
          () => this.vendorStore.currentVendor,
          () => this.setVendorMetadata(),
          { fireImmediately: true },
        );
      });

      await this.scriptLoader(salesforceConfig.deploymentUrl);

      // Check for liveagent to be there in an interval and init chat
      this.intervalId = this.window.setInterval(() => {
        const { liveagent } = this.window as any;
        if (liveagent) {
          liveagent
            .addCustomDetail('Company', companyName)
            .saveToTranscript('Platform__c');
          liveagent.init(chatUrl, deploymentId, orgId);
          this.window.clearInterval(this.intervalId);
        }
      }, 5);
    });

  private setVendorMetadata() {
    // Get restaurant meta information
    const { currentVendor } = this.vendorStore;
    const { liveagent } = this.window as any;

    if (!!currentVendor && !!Object.keys(currentVendor).length && !!liveagent) {
      const platformRestaurantId = currentVendor.vendorId;
      const restaurantName = currentVendor.name;
      liveagent
        .addCustomDetail('Restaurant id', platformRestaurantId)
        .saveToTranscript('Restaurant_ID__c');
      liveagent
        .addCustomDetail('Restaurant Name', restaurantName)
        .saveToTranscript('Restaurant_Name__c');
    }
  }

  private getSalesforceConfigForPlatform(platformKey: string) {
    return (
      this.config.salesforcePlatforms &&
      this.config.salesforcePlatforms[platformKey]
    );
  }

  private scriptLoader = (src: string): Promise<void> => {
    const elem = this.document.createElement('script');
    this.document.body.appendChild(elem);

    return new Promise((resolve, reject) => {
      elem.onload = () => resolve();
      elem.onerror = reject;
      elem.src = src;
    });
  };

  private onButtonEvent = (event: string) => {
    const { liveagent } = this.window as any;

    // Chat is online
    if (event === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
      this.chatOnline = true;
      return;
    }

    // Chat is offline
    if (event === liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
      this.chatOnline = false;
      return;
    }
  };
}
