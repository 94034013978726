import * as React from 'react';
import DialogMenu, {
  Option as DialogMenuOption,
} from '../../components/DialogMenu';
import { LanguageStore } from '../../stores/LanguageStore';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { IAvailableLanguage } from '../../config';

export type LanguageSelectorProps = {
  languageStore: LanguageStore;
  translationsStore: TranslationsStore;
  availableLanguages: IAvailableLanguage[];
  renderComponent?: (labelText: string, onClick: Function) => JSX.Element;
  renderType?: RenderType;
  open?: boolean;
  closeDialog?: () => void;
};

export type LanguageSelectorState = {
  languageDialogOpen: boolean;
  languageOptions: DialogMenuOption[];
};

const toLanguageOption = (activeLanguageIndex: number) => (
  language: IAvailableLanguage,
  i: number,
): DialogMenuOption => ({
  label: language.label,
  initiallySelected: activeLanguageIndex === i,
});

const languageByValueFind = (value: string) => (language: IAvailableLanguage) =>
  value === language.value;

const valueEquals = (valueMatch: string) => ({
  value,
}: IAvailableLanguage): boolean => valueMatch === value;

export enum RenderType {
  TRIGGER,
  DIALOG,
}

export default class LanguageSelector extends React.Component<
  LanguageSelectorProps,
  LanguageSelectorState
> {
  constructor(props) {
    super(props);
    const currentLanguage = this.props.languageStore.currentLanguage;
    const activeLanguageIndex = this.props.availableLanguages.findIndex(
      valueEquals(currentLanguage),
    );
    const languageOptions = this.props.availableLanguages.map(
      toLanguageOption(activeLanguageIndex),
    );

    this.state = {
      languageDialogOpen: false,
      languageOptions,
    };
  }
  render() {
    const { languageDialogOpen, languageOptions } = this.state;

    const currentLanguage =
      this.props.availableLanguages.find(
        languageByValueFind(this.props.languageStore.currentLanguage),
      ) || this.props.availableLanguages[0];

    if (this.props.renderType === RenderType.DIALOG) {
      return (
        <DialogMenu
          open={this.props.open}
          onClose={this.props.closeDialog}
          options={languageOptions}
          onSelect={this.onLanguageSelected.bind(this)}
          title={this.props.translationsStore.translate(
            'sidebar.language_dialog.title',
          )}
        />
      );
    }

    return (
      <React.Fragment>
        {this.props.renderComponent(currentLanguage.label, () =>
          this.setLanguageDialogOpen(true),
        )}
        <DialogMenu
          open={languageDialogOpen}
          onClose={() => this.setLanguageDialogOpen(false)}
          options={languageOptions}
          onSelect={this.onLanguageSelected.bind(this)}
          title={this.props.translationsStore.translate(
            'sidebar.language_dialog.title',
          )}
        />
      </React.Fragment>
    );
  }

  private onLanguageSelected = (index: number) => {
    const selectedLanguage = this.props.availableLanguages[index];
    const currentLanguageValue = this.props.languageStore.currentLanguage;

    if (selectedLanguage.value !== currentLanguageValue) {
      this.props.languageStore.setCurrentLanguage(selectedLanguage.value);
    }

    this.setLanguageDialogOpen(false);
  };

  private setLanguageDialogOpen = (open: boolean) => {
    this.setState({ languageDialogOpen: open });
  };
}
