const availableLanguages = require('./availableLanguages.stg.js');
module.exports = {
  basePath: '',
  env: 'dev',
  region: 'eu',
  devTools: true,
  defaultLocale: 'en',
  authServiceUrl: 'https://511weka2u7.execute-api.eu-west-1.amazonaws.com/dev',
  notificationCenterUrl:
    'https://portal-notification-api.envoy.euw.dh-vt-stg.net',
  apiUrl: 'https://rzcwp8qifj.execute-api.eu-west-1.amazonaws.com/dev/v1',
  pluginUrl: 'https://rzcwp8qifj.execute-api.eu-west-1.amazonaws.com/dev/v2',
  translationsUrl: 'https://d2xzidqie5hn2y.cloudfront.net/portal/{locale}.json',
  availableLanguagesUrl:
    'https://d2xzidqie5hn2y.cloudfront.net/portal/languages.json',
  reportIconsUrl: 'https://d2ypy576uxlbzp.cloudfront.net',
  vendorStatusUrl: 'https://egl12nboz0.execute-api.eu-west-1.amazonaws.com/dev',
  reportsApiUrl: 'https://0n1ui7giq2.execute-api.eu-west-1.amazonaws.com/dev',
  gtmCode: 'GTM-PFKLQR9',
  sentry: {
    project: '2',
    key: '9114432daf774b09bf6d8f136400f591',
    tracesSampleRate: 1.0,
  },
  datadog: {
    clientToken: 'pubefe42b58dfc921b8a518b8dfa6db84cc',
    level: 'info',
  },
  fwf: {
    region: 'eu',
    environmentToken: '45484146-3a32-4e62-a08f-8fccf83f50fe',
  },
  hiddenBrands: ['OP_SE', 'FO_OP'],
  salesforcePlatforms: {
    LH_DE: {
      companyName: 'LieferheldDE',
      chatUrl: 'https://d.la1-c2-frf.salesforceliveagent.com/chat',
      buttonId: '5731r000000L1Ph',
      deploymentUrl:
        'https://c.la1-c2-frf.salesforceliveagent.com/content/g/js/41.0/deployment.js',
      deploymentId: '5721r000000L0zG',
      orgId: '00Dw0000000l3C7',
    },
    PDE_DE: {
      companyName: 'PizzaDE',
      chatUrl: 'https://d.la1-c2-frf.salesforceliveagent.com/chat',
      buttonId: '5731r000000L1Ph',
      deploymentUrl:
        'https://c.la1-c2-frf.salesforceliveagent.com/content/g/js/41.0/deployment.js',
      deploymentId: '5721r000000L0zG',
      orgId: '00Dw0000000l3C7',
    },
  },
  platforms: [
    {
      name: 'lieferheld_pizza_de',
      nameReadable: 'Lieferheld & Pizza.de',
      platformKeys: ['LH_DE', 'PDE_DE'],
      defaultLocale: 'de',
      color: 'rgb(214, 31, 38)',
      phoneNumber: '030 544 459 22',
      domains: [
        'manager.dev.portal.restaurant',
        'lieferheld.dev.portal.restaurant',
        'pizzade.dev.portal.restaurant',
      ],
    },
    {
      name: 'talabat',
      nameReadable: 'Talabat',
      platformKeys: ['TB'],
      defaultLocale: 'en',
      color: 'rgb(255, 111, 0)',
      qualtrics: {
        projectId: 'ZN_3NQQ1fng12YnE1f',
      },
      domains: ['talabat.dev.portal.restaurant'],
    },
    {
      name: 'otlob',
      nameReadable: 'Otlob',
      platformKeys: ['TB_OT'],
      defaultLocale: 'ar',
      color: 'rgb(99, 42, 123)',
      domains: ['otlob.dev.portal.restaurant'],
    },
    {
      name: 'foodora',
      nameReadable: 'Foodora',
      platformKeys: ['FO_DE'],
      defaultLocale: 'en',
      color: 'rgb(214, 3, 101)',
      domains: ['foodora.dev.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_aYwmYVXZggJtRQO',
      },
    },
    {
      name: 'onlinepizza',
      nameReadable: 'Onlinepizza',
      platformKeys: ['FO_OP'],
      defaultLocale: 'en',
      color: 'rgb(254, 52, 31)',
      domains: ['onlinepizza.dev.portal.restaurant'],
    },
    {
      name: 'mjam',
      nameReadable: 'Mjam',
      platformKeys: ['MJM_AT'],
      defaultLocale: 'de',
      color: 'rgb(170, 209, 74)',
      domains: ['mjam.dev.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_eesC4ktsrpdfv2m',
      },
    },
    {
      name: 'foodpanda',
      nameReadable: 'foodpanda',
      platformKeys: ['FP_HK'],
      defaultLocale: 'en',
      color: 'rgb(214, 3, 101)',
      braze: {
        baseUrl: 'https://sdk.iad-01.braze.com',
        apiKey: 'b8ebc8c6-bd33-47eb-af15-eb27c6c9b55d',
      },
      domains: [
        'foodpanda.dev.portal.restaurant',
        'https://foodpandanext.dev.portal.restaurant',
      ],
      qualtrics: {
        projectId: 'ZN_007jO3SH6xaMKdE',
      },
    },
    {
      name: 'foodpanda_ro',
      nameReadable: 'foodpanda România',
      platformKeys: ['FP_RO'],
      defaultLocale: 'ro',
      color: 'rgb(214, 3, 101)',
      braze: {
        baseUrl: 'https://sdk.iad-01.braze.com',
        apiKey: 'b8ebc8c6-bd33-47eb-af15-eb27c6c9b55d',
      },
      domains: ['ro.dev.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_007jO3SH6xaMKdE',
      },
    },
    {
      name: 'foodpanda_bg',
      nameReadable: 'foodpanda България',
      platformKeys: ['FP_BG'],
      defaultLocale: 'bg',
      color: 'rgb(214, 3, 101)',
      braze: {
        baseUrl: 'https://sdk.iad-01.braze.com',
        apiKey: 'b8ebc8c6-bd33-47eb-af15-eb27c6c9b55d',
      },
      domains: ['bg.dev.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_007jO3SH6xaMKdE',
      },
    },
    {
      name: 'foodpanda_de',
      nameReadable: 'foodpanda Germany',
      platformKeys: ['FP_DE'],
      defaultLocale: 'de',
      color: 'rgb(214, 3, 101)',
      braze: {
        baseUrl: 'https://sdk.iad-01.braze.com',
        apiKey: 'b8ebc8c6-bd33-47eb-af15-eb27c6c9b55d',
      },
      domains: ['de.dev.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_007jO3SH6xaMKdE',
      },
    },
    {
      name: 'foodpanda_sk',
      nameReadable: 'foodpanda Slovakia',
      platformKeys: ['FP_SK'],
      defaultLocale: 'sk',
      color: 'rgb(214, 3, 101)',
      braze: {
        baseUrl: 'https://sdk.iad-01.braze.com',
        apiKey: 'b8ebc8c6-bd33-47eb-af15-eb27c6c9b55d',
      },
      domains: ['sk.dev.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_007jO3SH6xaMKdE',
      },
    },
    {
      name: 'pizzaonline',
      nameReadable: 'Pizza Online',
      platformKeys: ['PO_FI'],
      defaultLocale: 'fi',
      color: 'rgb(0, 178, 114)',
      braze: {
        baseUrl: 'https://sdk.iad-01.braze.com',
        apiKey: 'c3ce05cd-446f-4a6e-ae16-68d04ebd665c',
      },
      domains: [
        'pizza-online.dev.portal.restaurant',
        'pizzaonline.dev.portal.restaurant',
      ],
    },
    {
      name: 'damejidlo',
      nameReadable: 'Dáme Jídlo',
      platformKeys: ['DJ_CZ'],
      defaultLocale: 'cs',
      color: '#DC1C22',
      domains: ['damejidlo.dev.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_a4eZGNzUgXHDU4m',
      },
    },
    {
      name: 'netpincer',
      nameReadable: 'NetPincér',
      platformKeys: ['NP_HU'],
      defaultLocale: 'hu',
      color: '#ee2638',
      domains: ['netpincer.dev.portal.restaurant'],
      qualtrics: {
        projectId: 'ZN_b3GHiwyPMB9RgNw',
      },
    },
    {
      name: 'pedidosya',
      nameReadable: 'PedidosYa',
      platformKeys: ['PY_AG'],
      defaultLocale: 'es',
      color: '#f52f41',
      domains: ['pedidosya.dev.portal.restaurant'],
      loginRedirect: 'https://portal.pedidosya.com/',
      braze: {
        baseUrl: 'https://sdk.iad-01.braze.com',
        apiKey: 'ead59506-4cf6-4b00-89e4-71cf1a52053c',
      },
      qualtrics: {
        projectId: 'ZN_bJYM95pOBYKrccC',
      },
    },
    {
      name: 'hungerstation',
      nameReadable: 'HungerStation',
      platformKeys: ['HS_SA'],
      defaultLocale: 'en',
      color: 'rgb(248, 212, 87)',
      domains: ['hungerstation.dev.portal.restaurant'],
      qulatrics: {
        projectId: 'ZN_37s95aciLiEHmRM',
      },
    },
  ],
  availableLanguages,
  features: {
    showOnboarding: true,
  },
};
