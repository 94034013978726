import { primitiveProp, Model } from '@deliveryhero/mobx-model';

export interface ICity {
  name: string;
}

export class City extends Model<ICity> {
  @primitiveProp() name: string;

  constructor(props, defaults) {
    super();
    this.fromJson(props, defaults);
  }
}
