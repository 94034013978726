import { withDependencies } from '@deliveryhero/react-inversify-provider';
import MobileNav from './MobileNav';
import { NavStore } from '../../stores/NavStore';
import { PluginStore } from '../../stores/PluginStore';
import GtmAuthenticatedUserEvent from '../../utils/gtm/GtmAuthenticatedUserEvent';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { TYPES } from '../../types';

export default withDependencies({
  navStore: NavStore,
  history: 'history',
  pluginStore: PluginStore,
  authenticatedUserEvent: GtmAuthenticatedUserEvent,
  translationsStore: TranslationsStore,
  sessionStore: TYPES.SessionStore,
})(MobileNav);
