import { Translate } from '../../components/Translate';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import StartPrompt from '../../components/StartPrompt';
import { getColor, getSpacing } from '@deliveryhero/styled-material-ui';
import LoginForm from '../../components/LoginForm';
import { Typography } from '@deliveryhero/vendor-portal-components';

const ForgottenPasswordButton = styled(
  // eslint-disable-next-line test-selectors/button
  (props: any) => <Button {...props} />,
)`
  margin-top: ${getSpacing(2)} !important;
  margin-bottom: ${getSpacing(1)} !important;
  text-decoration: none;
  color: ${getColor('primary', 'main')};
` as any;
ForgottenPasswordButton.displayName = 'ForgottenPasswordButton';

const LoginError = styled.p`
  color: red;
`;
LoginError.displayName = 'LoginError';

const LoginFeedback = styled.p`
  font-size: 0.9em;
  color: #aaa;
  width: 300px;
  margin: 5px auto 30px;
  line-height: 1.45em;
`;
LoginFeedback.displayName = 'LoginFeedback';

const ForgottenPasswordLink = styled(Link)`
  text-decoration: none;
`;

const LoginFeedbackLink = styled.a`
  color: #aaa;
`;

const LoginHead = styled.div`
  text-align: center;
`;

const LoginHeadTitle = styled(Typography).attrs({ variant: 'headline' })`
  padding-bottom: ${getSpacing(0.5)};
`;

const LoginHeadDescription = styled(Typography).attrs({ variant: 'paragraph' })`
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: ${getSpacing(1)};
`;

export const LoginView: React.StatelessComponent<any> = ({
  hasError,
  errorMessage,
  onChange,
  onSubmit,
  email,
  password,
  isLoading,
  getError,
  isLoggedOut,
  platform,
  feedbackEmail,
}) => {
  const platformName = platform.name;

  const titleTranslationCode = `global.welcome_title.${platformName}`;
  const messageTranslationCode = `global.welcome_message.${platformName}`;

  return (
    <div>
      <StartPrompt platform={platform}>
        <LoginHead>
          <LoginHeadTitle>
            <Translate code={titleTranslationCode} />
          </LoginHeadTitle>

          <LoginHeadDescription>
            <Translate code={messageTranslationCode} />
          </LoginHeadDescription>
        </LoginHead>
        {hasError ? (
          <LoginError id="login-error" data-testid="login-error">
            {errorMessage}
          </LoginError>
        ) : null}
        {isLoggedOut ? (
          <LoginError id="login-logout-error">
            <Translate code="global.login.logout_out" />
          </LoginError>
        ) : null}

        <LoginForm
          email={email}
          password={password}
          isSubmitting={isLoading}
          onChange={onChange}
          onSubmit={onSubmit}
          getError={getError}
        />

        <ForgottenPasswordLink to={'/reset-password'}>
          <ForgottenPasswordButton
            id="button_forgot_password"
            fullWidth={true}
            data-testid="login-forgotten-password"
          >
            <Translate code="global.login.forgot_password" />
          </ForgottenPasswordButton>
        </ForgottenPasswordLink>
        {feedbackEmail && (
          <LoginFeedback>
            <Translate code="global.login.feedback" />{' '}
            <LoginFeedbackLink
              href={`mailto:${feedbackEmail}`}
              data-testid="login-feedback-link"
            >
              {feedbackEmail}
            </LoginFeedbackLink>
          </LoginFeedback>
        )}
      </StartPrompt>
    </div>
  );
};
LoginView.displayName = 'Login';

export default LoginView;
