import * as React from 'react';
import { CenteredCircularProgress } from '@deliveryhero/vendor-portal-components';
import { SessionStore } from '../../stores/SessionStore';
import { PlatformStore } from '../../stores/PlatformStore';
import { Translate } from '../../components/Translate';
import { observer } from 'mobx-react';
import ServerError from '../../components/errors/ServerError';
import { DesktopNavSidebar } from './DesktopNavSidebar';
import { History, Location } from 'history';
import { SdkProvider } from '@deliveryhero/vendor-portal-sdk';
import PortalSDKStore from '../../stores/PortalSDKStore';
import { withWidth } from '@material-ui/core';
import { WithWidthProps, isWidthUp } from '@material-ui/core/withWidth';
import GtmAuthenticatedUserEvent from '../../utils/gtm/GtmAuthenticatedUserEvent';
import Box from '../../components/Box';
import { NavStore } from '../../stores/NavStore';
import { PluginStore } from '../../stores/PluginStore';
import { StyledReactSVG } from '../../components/BaseNav';
import { MobileTopBar } from './MobileTopBar';
import { MobileDrawer } from './MobileDrawer';
import { ContentWrapper } from './ContentWrapper';
import { IPlugin } from '../../models/Plugin';
import { reduceChunk } from '../../utils/reduceChunk';
import { IAvailableLanguage } from '../../config';
import { NavigationItem } from '../App/MasterRoutes/MasterRoutes';
import FallbackIcon from '@material-ui/icons/Code';
import { getStaticPath } from '../../utils/PathHelper';
import { VendorStore } from '../../stores/VendorStore';

const MAX_NAVITEMS_PER_COLUMN = 6;

export type MasterContainerProps = WithWidthProps & {
  sessionStore: SessionStore;
  platformStore: PlatformStore;
  vendorStore: VendorStore;
  children?: any;
  currentLanguage: string;
  history: History;
  location: Location;
  switchLanguage: () => void;
  portalSdkStore: PortalSDKStore;
  authenticatedUserEvent: GtmAuthenticatedUserEvent;
  window: Window;
  navStore: NavStore;
  pluginStore: PluginStore;
  availableLanguages: IAvailableLanguage[];
};

export type MasterContainerState = {
  moreOpen: boolean;
  currentSelectedNavigation: number;
  currentLanguageLabel: string;
};

@observer
class MasterContainer extends React.Component<
  MasterContainerProps,
  MasterContainerState
> {
  state = {
    moreOpen: false,
    currentSelectedNavigation: -1,
    currentLanguageLabel: '',
  };

  static getDerivedStateFromProps(props, state) {
    const navItems = MasterContainer.getNavItems(props);
    const currentSelectedNavigation = navItems[0]
      ? navItems[0].findIndex((nav) =>
          props.location.pathname.startsWith(nav.path),
        )
      : -1;

    const { currentLanguage, availableLanguages } = props;
    const currentLanguageObj = availableLanguages.find(
      (lang) => lang.value === currentLanguage,
    );
    const currentLanguageLabel = currentLanguageObj?.label;
    return {
      ...state,
      currentSelectedNavigation,
      currentLanguageLabel,
    };
  }

  private static getNavItems(props) {
    const filteredPlugins: NavigationItem[] = props.pluginStore.menuPlugins.map(
      mapPluginToNavItem,
    );

    return filteredPlugins.reduce(reduceChunk(MAX_NAVITEMS_PER_COLUMN), []);
  }

  async componentDidMount() {
    this.props.navStore.setNewNavigation({
      title: null,
      name: 'master',
      showAppBar: false,
      showBottomNav: true,
    });

    await this.props.vendorStore.fetchAllVendors();
  }

  render() {
    if (
      this.props.vendorStore.isFetched &&
      this.props.vendorStore.vendors.size === 0
    ) {
      return <ServerError />;
    }

    if (this.props.vendorStore.isLoading) {
      return <CenteredCircularProgress />;
    }

    const showDesktop = isWidthUp('md', this.props.width);

    const sdk = this.props.portalSdkStore.getSdkForMaster();
    if (!sdk) {
      return null;
    }

    const navItems = MasterContainer.getNavItems(this.props);
    const { moreOpen } = this.state;
    const hasMoreButton = navItems.length > 1;
    const isMoreOpen = hasMoreButton && moreOpen;

    return (
      <SdkProvider sdk={sdk}>
        <Box display={{ xs: 'block', md: 'flex' }}>
          {showDesktop && this.renderDesktopSidebar()}
          {!showDesktop && this.renderMobileMenu()}

          <ContentWrapper
            open={isMoreOpen}
            columnCount={navItems.length}
            onMouseOver={() => this.setState({ moreOpen: false })}
            showImpersonatorMessage={this.showImpersonatorMessage()}
          >
            {this.props.children}
          </ContentWrapper>
        </Box>
      </SdkProvider>
    );
  }

  private renderDesktopSidebar() {
    const platformKey = this.props.platformStore.currentPlatform
      .platformKeys[0];
    const { switchLanguage, location, authenticatedUserEvent } = this.props;
    const { currentLanguageLabel, moreOpen } = this.state;
    const navItems = MasterContainer.getNavItems(this.props);
    const hasMoreButton = navItems.length > 1;
    const isMoreOpen = hasMoreButton && moreOpen;
    return (
      <DesktopNavSidebar
        onOpen={() => this.setState({ moreOpen: true })}
        onClose={() => this.setState({ moreOpen: false })}
        open={isMoreOpen}
        columnCount={navItems.length}
        platformKey={platformKey}
        navItems={navItems}
        hasMoreButton={hasMoreButton}
        currentPath={location.pathname}
        pushEvent={authenticatedUserEvent.pushEvent.bind(
          authenticatedUserEvent,
        )}
        popOverOptions={[
          {
            label: currentLanguageLabel,
            action: switchLanguage,
          },
          {
            label: <Translate code="global.menu.logout" />,
            action: this.onLogout.bind(this),
          },
        ]}
      />
    );
  }

  private renderMobileMenu() {
    const platformKey = this.props.platformStore.currentPlatform
      .platformKeys[0];
    const navItems = MasterContainer.getNavItems(this.props);

    const currentMenuItem = navItems.length
      ? navItems[0][this.state.currentSelectedNavigation]
      : undefined;

    return (
      <>
        <MobileTopBar
          currentMenuItem={currentMenuItem}
          backUrl={this.props.navStore.backUrl}
          toggleMenu={this.toggleMenu}
          navigationTitle={this.props.navStore.navigationTitle}
        />
        <MobileDrawer
          platformKey={platformKey}
          open={this.isMenuOpen()}
          onClose={this.closeMenu}
          userName={this.props.sessionStore.getUserData('name')}
        />
      </>
    );
  }

  private isMenuOpen() {
    return this.props.location.state?.menuOpen;
  }

  private showImpersonatorMessage = (): boolean => {
    return this.props.sessionStore.mainSessionInfo.isImpersonator;
  };

  private toggleMenu = () => {
    const menuIsCurrentlyClosed = !this.isMenuOpen();

    if (menuIsCurrentlyClosed) {
      // Push new location state to open the menu
      this.props.history.push({
        ...this.props.location,
        state: {
          menuOpen: true,
        },
      });
    } else {
      // Go Back to old closed menu state
      this.props.history.goBack();
    }
  };

  private closeMenu = () => {
    this.props.history.goBack();
  };

  private onLogout = () => {
    this.props.history.push('/logout');
  };
}

export default withWidth()(MasterContainer);

function mapPluginToNavItem(plugin: IPlugin) {
  const code = plugin.code.toLowerCase();
  return {
    ...plugin,
    name: code,
    label: `global.menu.${code}`,
    gtmOpenEvent: `${code}.opened`,
    menuIcon: plugin.menuIcon ? (
      <StyledReactSVG size={24} path={getStaticPath(plugin.menuIcon?.url)} />
    ) : (
      <FallbackIcon />
    ),
    path: plugin.route,
  };
}
