/**
 * Default Token expired condition function. Gets API library fetch payload and determines when token is expired
 * due to vendor portal standard
 */
export default ({ res, payload }) => {
  if (res.status !== 401 || !payload) {
    return false;
  }

  try {
    if (typeof payload === 'string') {
      payload = JSON.parse(payload);
    }
  } catch (_e) {}

  return payload.code === 'TOKEN_EXPIRED';
};
