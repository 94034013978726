import { primitiveProp, referenceProp, Model } from '@deliveryhero/mobx-model';
import { City, ICity } from './City';
import { Country, ICountry } from './Country';

export interface IAddress {
  street?: string;
  buildingNumber?: string;
  floor?: string;
  postcode?: string;
  area?: string;
  info?: string;
  city: ICity;
  country: ICountry;
}

export class Address extends Model<IAddress> {
  @primitiveProp() street?: string;
  @primitiveProp() buildingNumber?: string;
  @primitiveProp() floor?: string;
  @primitiveProp() postcode?: string;
  @primitiveProp() area?: string;
  @primitiveProp() info?: string;
  @referenceProp(City) city: City;
  @referenceProp(Country) country?: Country;

  constructor(props, defaults) {
    super();
    this.fromJson(props, defaults);
  }
}
