import MasterPluginContainer from './MasterPluginContainer';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { NavStore } from '../../stores/NavStore';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PluginModuleStore from '../../stores/PluginModuleStore';
import { TYPES } from '../../types';

export default withDependencies({
  navStore: NavStore,
  vendorStore: TYPES.VendorStore,
  pluginModuleStore: PluginModuleStore,
  config: 'config',
})(
  withRouter<any, typeof MasterPluginContainer>(
    observer(MasterPluginContainer),
  ),
);
