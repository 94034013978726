import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { CenteredCircularProgress } from '@deliveryhero/vendor-portal-components';
import AuthService, {
  InvalidTokenError,
  ExpiredTokenError,
} from '../../services/AuthService';
import ServerError from '../../components/errors/ServerError';
import ErrorMessage from '../../components/errors/ErrorMessage';
import { Translate } from '../../components/Translate';

export type OtpSigninContainerProps = RouteComponentProps & {
  authService: AuthService;
};

export default function OtpSigninContainer({
  location,
  authService,
}: OtpSigninContainerProps) {
  const code = location.hash.substr(1);
  const [isReady, setReady] = React.useState(false);
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    authService.loginOtp(code).then(
      () => setReady(true),
      (err) => {
        setError(err);
      },
    );
  }, []);

  if (error instanceof InvalidTokenError) {
    return (
      <ErrorMessage
        title={<Translate code="global.error.invalid_token.title" />}
        message={<Translate code="global.error.invalid_token.message" />}
        centered
      />
    );
  }

  if (error instanceof ExpiredTokenError) {
    return (
      <ErrorMessage
        title={<Translate code="global.error.expired_otp_token.title" />}
        message={<Translate code="global.error.expired_otp_token.message" />}
        centered
      />
    );
  }

  if (error) {
    return <ServerError centered />;
  }

  if (isReady) {
    return <Redirect to="/" />;
  }

  return <CenteredCircularProgress />;
}
