import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { IMoreNavItem } from '../MoreMenuContainer';
import ReactSVG from 'react-svg';
import FallbackIcon from '@material-ui/icons/Code';

const Wrapper = styled.div`
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.08);
  border: solid 0.5px transparent;
` as any;

const StyledReactSVG = styled(ReactSVG)`
  svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }

  path {
    fill: currentColor;
  }
`;

const StyledListItem = styled(ListItem)`
  &:hover {
    background-color: white;
  }
`;

export interface IMoreMenuListProps {
  menuItems: IMoreNavItem[];
  onItemClicked: Function;
}

export class MoreMenuList extends React.Component<IMoreMenuListProps> {
  render() {
    return (
      <Wrapper>
        <List component="nav">
          {this.props.menuItems.map((menuItem) => {
            return (
              <StyledListItem
                data-testid="more-menu-list-item"
                key={menuItem.name}
                button
                onClick={() => this.listItemClickAction(menuItem)}
              >
                <ListItemIcon>
                  {menuItem.iconUrl ? (
                    <StyledReactSVG path={menuItem.iconUrl} />
                  ) : (
                    <FallbackIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={menuItem.label} />
              </StyledListItem>
            );
          })}
        </List>
      </Wrapper>
    );
  }

  private listItemClickAction(menuItem: IMoreNavItem) {
    this.props.onItemClicked(menuItem);
  }
}
