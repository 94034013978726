import styled from 'styled-components';
import { Typography } from '@deliveryhero/vendor-portal-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';

const SubHeaderText = styled(Typography).attrs({ variant: 'subtitle' })`
  margin-top: -${getSpacing(1)};
  margin-bottom: ${getSpacing(2)};
  text-align: center;
`;

export default SubHeaderText;
