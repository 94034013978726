import { inject, injectable } from 'inversify';
import GtmAuthenticatedUserEvent from './GtmAuthenticatedUserEvent';
import { GtmEvent } from './GtmEvent';

@injectable()
export default class GtmPerformanceTiming {
  startTimeNormalise = {};

  @inject('window') window: Window;
  @inject(GtmAuthenticatedUserEvent)
  authenticatedUserEvent: GtmAuthenticatedUserEvent;

  start(event = '') {
    if (!this.window.performance) {
      return;
    }

    this.startTimeNormalise[event] = this.window.performance.now();
  }

  async pushEvent(event: string, data?: {}): Promise<void> {
    if (!this.window.performance) {
      return Promise.resolve();
    }

    const normaliseTime = this.startTimeNormalise[event] || 0;

    const timeSincePageLoad = Math.round(
      this.window.performance.now() - normaliseTime,
    );

    // Sends the timing hit to Google Analytics.
    await this.authenticatedUserEvent.pushEvent(event, {
      timingValue: timeSincePageLoad,
    });
  }
}
