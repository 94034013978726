import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { PluginStore } from '../../stores/PluginStore';
import GtmAuthenticatedUserEvent from '../../utils/gtm/GtmAuthenticatedUserEvent';
import { NavStore } from '../../stores/NavStore';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { MoreMenuContainer } from './MoreMenuContainer';
import { TYPES } from '../../types';

export default withDependencies({
  pluginStore: PluginStore,
  authenticatedUserEvent: GtmAuthenticatedUserEvent,
  navStore: NavStore,
  translationsStore: TranslationsStore,
  sessionStore: TYPES.SessionStore,
})(MoreMenuContainer);
