import { injectable, postConstruct, inject } from 'inversify';
import { observable, ObservableMap, when } from 'mobx';
import PortalSDKStore from './PortalSDKStore';
import PluginModuleDependencyStore from './PluginModuleDependencyStore';
import PluginModule from '../models/PluginModule';
import { IPluginConfig } from '@deliveryhero/vendor-portal-sdk';
import { SentrySDK, TYPES } from '../types';
import GtmPerformanceTiming from '../utils/gtm/GtmPerformanceTiming';
import { LoggerService } from '../services/LoggerService';

@injectable()
export default class PluginModuleStore {
  @observable private moduleRegistry: ObservableMap<any> = observable.map();

  @inject(PluginModuleDependencyStore)
  private dependencyStore: PluginModuleDependencyStore;
  @inject(PortalSDKStore) private portalSDKStore: PortalSDKStore;
  @inject('window') private window: Window;
  @inject(TYPES.Sentry) private sentry: SentrySDK;
  @inject(TYPES.LoggerService) private loggerService: LoggerService;
  @inject(GtmPerformanceTiming) private performance: GtmPerformanceTiming;

  /**
   * Expose the AMD `define` function in `window`
   */
  @postConstruct() init() {
    this.window['define'] = this.define;
  }

  /**
   * Load the plugin module (initiated by `FrontendPluginContainer`) and initiate it's code execution
   * @param moduleName Same as the plugin code
   * @param bundleUrl The URL of the plugin JS bundle
   */
  loadModule(moduleName: string, bundleUrl: string): Promise<PluginModule> {
    // Add contexts for logging
    this.sentry.setTag('plugin', moduleName.toLowerCase());
    this.loggerService.addContext('plugin', moduleName.toLowerCase());

    // Return already existing loading promise if module is already there
    if (this.moduleRegistry.has(moduleName)) {
      return this.moduleRegistry.get(moduleName).promise;
    }

    const pluginName = moduleName.toLowerCase();

    // Load the script from the `bundleUrl` (and start performance measuring)
    this.performance.start(`${pluginName}.plugin_loaded`);
    this.performance.start(`${pluginName}.plugin_initialised`);
    const { promise, elem } = this.addScriptTag(bundleUrl);
    const modulePromise = promise
      .then(() => when(() => !!pluginModule.instance))
      .then(() => pluginModule);

    // Create the plugin module
    const pluginModule = new PluginModule(
      {
        moduleName,
        bundleUrl,
        instance: null,
        promise: modulePromise,
        scriptDomNode: elem,
        sdk: this.portalSDKStore.getSdkForPlugin(moduleName),
      },
      {},
    );

    this.moduleRegistry.set(moduleName, pluginModule);

    // If module loading fails, remove the added script node and
    // delete it from the registry to be able to try it again
    return modulePromise.catch((err) => {
      // Remove the script dom node
      elem.parentElement.removeChild(elem);
      // Remove module from the registry
      this.moduleRegistry.delete(moduleName);
      return Promise.reject(err);
    });
  }

  /**
   * Resolve module by module name
   * @param moduleName Same as the plugin code
   */
  getModuleByName(moduleName: string): PluginModule {
    return this.moduleRegistry.get(moduleName);
  }

  /**
   * Add the script tag to load and execute the plugin code
   * @param bundleUrl The URL of the plugin JS bundle
   */
  private addScriptTag(bundleUrl) {
    const elem = this.window.document.createElement('script');
    return {
      elem,
      promise: new Promise((resolve, reject) => {
        this.window.document.body.appendChild(elem);
        elem.onload = resolve;
        elem.onerror = reject;
        elem.src = bundleUrl;
      }),
    };
  }

  /**
   * Resolve the plugin `PluginModule` based on it's `<script>` DOM node
   * @param scriptDomNode `<script>` DOM node of the plugin module
   */
  private getModuleByScriptNode(
    scriptDomNode: HTMLScriptElement,
  ): PluginModule {
    return Array.from(this.moduleRegistry.values()).find(
      (pluginModule) => pluginModule.scriptDomNode === scriptDomNode,
    );
  }

  /**
   * AMD define function, will be exposed through `window.define`
   * @param args up to three arguments, (name, dependency name array, factory function),
   * only factory function and dependency name array are mandatory
   */
  private define = async (...args: any[]) => {
    // Script DOM node that is the caller of the define function, used to match it with the right plugin
    const scriptNode = this.window.document.currentScript as HTMLScriptElement;
    // Get the right module, that matches the script node
    const parentModule = this.getModuleByScriptNode(scriptNode);

    // This is to prevent foreign code injection. Only requested modules can be loaded.
    // Also they can only be loaded once.
    if (!parentModule || parentModule.bundleUrl !== scriptNode.src) {
      throw new Error(
        `This module (from source "${scriptNode.src}") was not requested by the Portal plugin system`,
      );
    }

    const pluginName = parentModule.moduleName.toLowerCase();

    // Get the last two arguments (dependencies and factory) from the function
    const factory: Function = args[args.length - 1];
    const deps: string[] = args[args.length - 2] || [];

    // Some build systems expose the exported modules in the `exports` dependency object
    // instead returning it in the factory
    const moduleExports: any = {};

    // Resolve dependencies through the dependency Store, sdk is a custom dependency, because
    // it is created individually for each plugin
    const resolvedDeps = await this.dependencyStore.resolveDependencies(deps, {
      exports: moduleExports,
      '@deliveryhero/vendor-portal-sdk': parentModule.sdk,
      '@rps/portal-sdk': parentModule.sdk, // For legacy suport
    });

    // Some build systems expose the exports as the return value
    const factoryReturnVal = factory.apply(null, resolvedDeps);

    // This creates and instantiates the plugin class exposed by the plugin default export.
    // As there are many different ways building systems expose this, we need to go through every option
    const PluginClass =
      moduleExports.default || factoryReturnVal.default || factoryReturnVal;
    const pluginInstance = new PluginClass();

    // Get optional configs exposed by the plugin
    if (typeof PluginClass.getConfig === 'function') {
      parentModule.setConfig(PluginClass.getConfig() as IPluginConfig);
    }

    this.performance.pushEvent(`${pluginName}.plugin_loaded`);

    // Do async init (e.g. loading plugin translations)
    await pluginInstance.init();

    this.performance.pushEvent(`${pluginName}.plugin_initialised`);

    parentModule.instance = pluginInstance;
    parentModule.isReady = true;
  };
}
