import * as React from 'react';
import PasswordHandlingContainer from '../PasswordHandlingContainer';
import { PasswordHandlingContainerProps } from '../PasswordHandlingContainer/PasswordHandlingContainer';
import AuthService from '../../services/AuthService';

export type ResetPasswordContainer = PasswordHandlingContainerProps & {
  authService: AuthService;
  skipIntro?: boolean;
};

const resetPassword = (authService: AuthService) => (
  token: string,
  newPassword: string,
) => authService.resetPassword(token, newPassword);

const ResetPasswordContainer = (props) => (
  <PasswordHandlingContainer
    translationPrefix="set_password"
    submit={resetPassword(props.authService)}
    skipIntro={props.skipIntro || false}
    {...props}
  />
);

export default ResetPasswordContainer;
