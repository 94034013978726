import { inject, injectable, postConstruct } from 'inversify';
import { observable, autorun, action, toJS } from 'mobx';
import { SessionStore } from '../stores/SessionStore';
import { TranslationsStore } from '../stores/TranslationsStore';
import { Api } from '@deliveryhero/portal-api-client';
import { LanguageStore } from '../stores/LanguageStore';
import { TYPES } from '../types';
import { IAccessTokenContent } from '../models/Session';

export enum LoginType {
  MASTER,
}

export class InvalidTokenError extends Error {}

export class ExpiredTokenError extends Error {}

@injectable()
export default class AuthService {
  @observable lastLoginEmail: string;
  @observable refreshStatusSubscribers: ((isExpired: boolean) => void)[] = [];
  @observable refreshTokenRequest: Promise<any>;

  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject(TYPES.GlobalApi) private api: Api;
  @inject('authServiceUrl') private baseUrl: string;
  @inject(TranslationsStore)
  private translationsStore: TranslationsStore;
  @inject('window') private window: Window;
  @inject(LanguageStore) private languageStore: LanguageStore;

  private lastOtpToken: string;

  @postConstruct() init() {
    // Setup last login email from local storage if exists
    this.lastLoginEmail =
      this.window.localStorage.getItem('lastLoginEmail') || '';

    // Save the lastLoginEmail localStorage value every time this.lastLoginEmail is changed
    autorun(() => {
      this.window.localStorage.setItem('lastLoginEmail', this.lastLoginEmail);
    });
  }

  /**
   * Login method, `master` name is historical
   * @todo change method name to just `login`
   * @param email Email the user has typed in
   * @param password Password the user has typed in
   */
  loginMaster(email: string, password: string): Promise<any> {
    const fetchOptions = this.createLoginOptions(email, password);
    return this.api
      .fetch(`${this.baseUrl}/v3/master/login`, 200, fetchOptions)
      .then((response) => this.handleLoginSuccess(response));
  }

  /**
   * Login via one time password token, which is used for impersonation, but can also be used
   * for proxy login or potentially login via email magic link.
   * @param otpAccessToken one time password token
   */
  loginOtp(otpAccessToken: string): Promise<void> {
    // OTP signin container is mounted twice because of the loading event blocking the whole UI
    // After that it attempts to do a new OTP login because the "ready" state is stored locally
    // In this component. To prevent an endless loop we use the "lastOtpToken" here as otp
    // Was already successful before. This is not an optimal approach and we should rethink our
    // LoadingManager/UI Blocking approach in the future!

    if (this.lastOtpToken === otpAccessToken) {
      return Promise.resolve();
    }

    const fetchOptions = {
      body: { otpAccessToken },
      method: 'POST',
    };
    return this.api.fetch(`${this.baseUrl}/v2/otp`, 200, fetchOptions).then(
      (response) => {
        this.lastOtpToken = otpAccessToken;
        return this.handleLoginSuccess(response);
      },
      (err) => {
        switch (err.status) {
          case 401:
            return Promise.reject(new ExpiredTokenError('Expired Token'));
          case 403:
            return Promise.reject(new InvalidTokenError('Bad Token'));
          default:
            return Promise.reject(err);
        }
      },
    );
  }

  logout() {
    this.sessionStore.isDirty = false;
    this.sessionStore.clearSession();
  }

  /**
   * Generates a new authentication token with the refresh token
   */
  refreshToken() {
    // When there is already a refresh token request running, return it's promise.
    // This is to prevent two token refresh requests from running in parallel.
    if (this.refreshTokenRequest) {
      return this.refreshTokenRequest;
    }

    // If user is not logged in, send a fake 401 response, which redirects the user to the login page
    if (!this.sessionStore.isLoggedIn) {
      return Promise.reject({
        response: {
          code: 'INVALID_REFRESH_TOKEN',
        },
        status: 401,
      });
    }

    // If user has no refresh token, send a fake 401 response, which redirects the user to the login page
    if (!this.sessionStore.hasRefreshToken) {
      this.sessionStore.clearSession();
      return Promise.reject({
        response: {
          code: 'INVALID_REFRESH_TOKEN',
        },
        status: 401,
      });
    }

    const mainSession = this.sessionStore.getMainSession();

    return this.refreshWithRefreshToken(mainSession.refreshToken);
  }

  /**
   * Refresh the authentication token with a given refresh token
   * @param refreshToken the refresh token to use
   */
  refreshWithRefreshToken(refreshToken: string) {
    let path: string;
    let api: Api;

    path = 'v3/master/token';
    api = this.api;

    // Inform all refresh status subscribers, that refresh is in action (`true`)
    this.refreshStatusSubscribers.forEach((cb) => cb(true));

    const fetchPromise = api
      .fetch(
        `${this.baseUrl}/${path}`,
        200,
        {
          body: { refreshToken },
          method: 'POST',
        },
        true,
      )
      .then(
        action((response) => {
          this.handleLoginSuccess(response);
          // Inform all refresh status subscribers, that refresh finished (`false` => not running anymore)
          this.refreshStatusSubscribers.forEach((cb) => cb(false));
          this.refreshTokenRequest = undefined;
          return response;
        }),
        (err) => {
          this.sessionStore.clearSession();
          this.refreshTokenRequest = undefined;

          throw err;
        },
      );

    // Store the refresh promise, so it can be used when an additional refresh request comes in
    this.refreshTokenRequest = fetchPromise;

    return fetchPromise;
  }

  /**
   * Send reset password request with a new password and a reset token
   * @param token Token that was sent to the user's email address
   * @param newPassword New password that the user typed in
   */
  async resetPassword(token: string, newPassword: string) {
    const fetchOptions = {
      body: {
        newPassword,
      },
      method: 'PUT',
    };

    const expectedResponseStatus = 200;

    const response = await this.api.fetch(
      `${this.baseUrl}/v3/master/password-reset/${token}`,
      expectedResponseStatus,
      fetchOptions,
    );

    // The reset password API endpoint responds with the same payload as a login response.
    // With this the user is automatically logged in after typing in the new password.
    return this.handleLoginSuccess(response);
  }

  /**
   * Initiates the password reset flow
   * @param email Email the user has typed in in the reset password form
   */
  requestMasterPasswordReset(email: string): Promise<void> {
    const url = `${this.baseUrl}/v2/master/password-reset`;
    const method = 'POST';
    const locale = this.languageStore.currentLanguage;
    const domain = this.window.location.hostname;
    const body = {
      email,
      locale,
      domain,
    };

    return this.api.fetch(url, 202, { method, body });
  }

  /**
   * Subscribe to changes in refresh requests. Takes a callback function that receives a boolean
   * value that describes if the refresh request is running or not
   * @param cb Function that is called when the refresh request is called and has ended.
   * The function receives a boolean value that indicates if the refresh is running or not.
   */
  subscribeToRefreshStatus(cb: (isRefreshing: boolean) => void): () => void {
    this.refreshStatusSubscribers.push(cb);
    return () => {
      this.refreshStatusSubscribers.splice(
        this.refreshStatusSubscribers.indexOf(cb),
        1,
      );
    };
  }

  /**
   * Method to call when the login has been successfully ran.
   * Sets session info in session store.
   * Is an arrow function to make it possible to pass it directly to `.then()`.
   * @param response response from the login or password reset endpoint
   */
  private handleLoginSuccess = (response: any) => {
    if (response.accessToken) {
      if (!this.hasVendors(response.accessTokenContent)) {
        return Promise.reject({
          response: {
            status: 500,
            message: this.translationsStore.translate(
              'global.login.error.no_restaurants_assigned',
            ),
          },
        });
      }

      this.sessionStore.setSessionInfo(response);

      return response;
    }

    return response;
  };

  /**
   * Indicates if user has vendors by looking at the access token content
   * @param accessTokenContent JSON Content of the access token, includes vendors array
   */
  private hasVendors(accessTokenContent: IAccessTokenContent): boolean {
    const vendorsIds = accessTokenContent.vendors;
    return Object.keys(vendorsIds).length > 0;
  }

  /**
   * Create the login options and has side effect to set the lastLoginEmail with the passed email
   * @param email Email the user has typed in
   * @param password Password the user has typed in
   */
  private createLoginOptions(email: string, password: string) {
    email = email.toLowerCase().trim();
    this.lastLoginEmail = email;
    return {
      body: { email, password },
      method: 'POST',
    };
  }
}
