import FWFMain, {
  VariationFallbackType,
  VariationFallback,
} from '@deliveryhero/fwf-sdk-javascript';
import { inject, injectable } from 'inversify';
import { SessionStore } from './SessionStore';
import { observable, when, ObservableMap } from 'mobx';
import { IConfig } from '../config';
import { VendorStore } from './VendorStore';
import { TYPES } from '../types';

type VariationExplanation = {
  kind: string;
  from: string;
};

type Variation = {
  variation: boolean;
  abTest: boolean;
  explanation: VariationExplanation;
  relevantContext: string;
};

type VariationMap<N extends string = string> = Record<N, Variation>;

interface IFWFMainConstructor {
  new (fwfParams: any): FWFMain;
}

@injectable()
export default class FwfStore {
  @inject(TYPES.SessionStore) sessionStore: SessionStore;
  @inject('config') config: IConfig;
  @inject('Newable<FWFMain>') FWFMain: IFWFMainConstructor;
  @inject(TYPES.VendorStore) vendorStore: VendorStore;

  @observable mainFwfClient: FWFMain;
  @observable private fwfClients: ObservableMap<
    string,
    FWFMain
  > = new ObservableMap();

  @observable private ready: boolean;
  private userContext;

  /**
   * Create main FWF client and set user context
   */
  async init() {
    const { region, environmentToken } = this.config.fwf;
    const session = this.sessionStore.getMainSession();

    const country = session.getVendorCountry();

    const userId = `master-${session.getUserData('userId')}`;
    const { subject, role } = session;
    const platformVendorIds = this.sessionStore.getMainSession()
      .platformVendorIds;

    const platformIdentifiers = platformVendorIds.join(',');
    const verticalTypes = this.vendorStore.verticalTypes.join(',');

    const vendorsCount = platformVendorIds.length;

    this.userContext = {
      userId,
      country,
      restaurantCount: vendorsCount, // @todo: Change restaurant to vendor
      vendorsCount,
      platformIdentifiers,
      subject,
      role,
      verticalTypes,
    };

    this.mainFwfClient = new this.FWFMain({
      user: this.userContext,
      region,
      environmentToken,
    });
    this.ready = true;
  }

  /**
   * Create additional user client
   * @param name name of the client (e.g. plugin code)
   * @param environmentToken environment token for the client
   */
  async createFwfClient(name: string, environmentToken: string) {
    await when(() => this.ready);
    const { region } = this.config.fwf;
    const client = new this.FWFMain({
      region,
      user: { ...this.userContext },
      environmentToken,
    });
    this.fwfClients.set(name, client);

    return client;
  }

  getFwfClient(name: string) {
    return this.fwfClients.get(name);
  }

  /**
   * Clear all the user metadata
   */
  clearUsers() {
    this.fwfClients.forEach((client) => {
      client.clearUser();
    });

    this.mainFwfClient.clearUser();
  }

  /**
   * Wrapper around FWF getVariation that creates variation fallback from value (not from `VariationFallback` class)
   * @param name key of the flag
   * @param fallbackValue fallback value when fetching fails
   * @param forced force http request to HTTP server
   */
  async getVariation<T extends string>(
    name: T,
    fallbackValue: boolean | string = false,
    forced: boolean = false,
  ): Promise<VariationMap<T>> {
    const fallBack = new VariationFallback(VariationFallbackType.FROM_VALUE);
    fallBack.setValue(fallbackValue);

    await when(() => this.ready);
    return this.mainFwfClient.getVariation(name, fallBack, forced);
  }

  /**
   * Wrapper around FWF getVariation that abstracts it's response format and directly returns the variation value
   * @param name key of the flag
   * @param fallbackValue fallback value when fetching fails
   * @param forced force http request to HTTP server
   */
  async getVariationValue<T extends string>(
    name: T,
    fallBackValue?: boolean | string,
    forced?: boolean,
  ): Promise<boolean | string> {
    try {
      const result = await this.getVariation(name, fallBackValue, forced);
      return result[name].variation;
    } catch (fallback) {
      return fallback && fallback.variation !== undefined
        ? fallback.variation
        : false;
    }
  }

  /**
   * Wrapper around FWF getVariations
   * @param flags array of flags to do batch request
   */
  async getVariations<T extends string>(flags: T[]): Promise<VariationMap> {
    await when(() => this.ready);
    return this.mainFwfClient.getVariations(flags);
  }
}
