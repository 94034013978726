import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import LoginView from './LoginView';
import { QueryStringParser } from '../../utils/QueryStringParser';
import styled from 'styled-components';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { Translate } from '../../components/Translate';

import { SessionStore } from '../../stores/SessionStore';
import { DialogStore } from '../../stores/DialogStore';
import GtmLoginSucceededEvent from '../../utils/gtm/GtmLoginSucceededEvent';
import { PlatformStore } from '../../stores/PlatformStore';
import { NavStore } from '../../stores/NavStore';
import AuthService from '../../services/AuthService';
import { TranslationsStore } from '../../stores/TranslationsStore';
import GtmManager from '../../utils/gtm/GtmManager';

export type LoginContainerProps = RouteComponentProps<any> & {
  gtmManager: GtmManager;
  translationsStore: TranslationsStore;
  authService: AuthService;
  sessionStore: SessionStore;
  dialogStore: DialogStore;
  loginSucceededEvent: GtmLoginSucceededEvent;
  platformStore: PlatformStore;
  navStore: NavStore;
  window: Window;
};

export type LoginContainerState = {
  email?: string;
  password?: string;
  snackbarMessage: string | JSX.Element;
  isLoading: boolean;
  hasError: boolean;
  redirectPath: string;
  hasSucceeded: boolean;
  errorMessage?: string;
  errors?: any[];
};

const LoginWrapper = styled.div`` as any;
LoginWrapper.displayName = 'LoginWrapper';

const MESSAGES: Map<string, string> = new Map();
MESSAGES.set('resetSuccess', 'login.reset_success');

const getMessage = (message: string) =>
  MESSAGES.has(message) ? <Translate code={MESSAGES.get(message)} /> : '';

@observer
export class LoginContainer extends React.Component<
  LoginContainerProps,
  LoginContainerState
> {
  constructor(props, context) {
    super(props, context);

    const urlParams = QueryStringParser.parse(
      this.props.window.location.search,
    );

    const message = urlParams.message ? getMessage(urlParams.message) : '';

    this.state = {
      errors: [],
      hasError: false,
      hasSucceeded: false,
      isLoading: false,
      redirectPath: urlParams.redirect
        ? decodeURIComponent(urlParams.redirect)
        : '/',
      email:
        props.location.search.email || this.props.authService.lastLoginEmail,
      password: props.location.search.password || '',
      snackbarMessage: message,
    };
  }

  componentDidMount() {
    this.props.navStore.setNewNavigation({
      title: null,
      name: 'login',
      showAppBar: false,
      showBottomNav: false,
    });

    if (this.props.platformStore.currentPlatform.loginRedirect) {
      this.props.window.location.href = this.props.platformStore.currentPlatform.loginRedirect;
    }
  }

  /**
   * Reset Qualtrics on login, to prevent mixed up data
   * in case the user was not logging out naturally (e.g. session expired)
   */
  resetQualtrics() {
    const _window: Window & { QSI?: any } = window;

    if (_window.QSI) {
      _window.QSI.API.unload();
    }
  }

  handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({
      hasError: false,
      isLoading: true,
    });

    this.resetQualtrics();

    return this.props.authService
      .loginMaster(this.state.email, this.state.password)
      .then(this.handleSubmitSuccess, this.handleSubmitError);
  };

  render() {
    if (this.props.platformStore.currentPlatform.loginRedirect) {
      return null;
    }

    if (this.props.sessionStore.isLoggedIn) {
      const [
        redirectPathname,
        redirectSearchString,
      ] = this.state.redirectPath.split('?');
      const redirectSearchParsed = QueryStringParser.parse(
        '?' + redirectSearchString,
      );

      return (
        <Redirect
          to={{
            pathname: redirectPathname,
            search: QueryStringParser.stringify(redirectSearchParsed),
          }}
        />
      );
    }

    const { snackbarMessage } = this.state;
    const snackbarOpen = snackbarMessage !== '';

    const platform = this.props.platformStore.currentPlatform;
    const feedbackEmailTranslation = this.props.translationsStore.translate(
      'global.login.feedback_email',
    );
    const feedbackEmail =
      !!feedbackEmailTranslation &&
      feedbackEmailTranslation !== 'global.login.feedback_email'
        ? feedbackEmailTranslation
        : null;

    return (
      <div>
        <LoginWrapper>
          <LoginView
            {...this.state}
            onChange={this.handleInputChange}
            onSubmit={this.handleSubmit}
            getError={this.getError}
            isLoggedOut={
              !!this.props.location.state &&
              !!this.props.location.state.isLoggedOut
            }
            platform={platform}
            feedbackEmail={feedbackEmail}
          />
        </LoginWrapper>

        <Snackbar
          open={snackbarOpen}
          onClose={this.closeSnackbar.bind(this)}
          autoHideDuration={3000}
        >
          <SnackbarContent
            data-testid="login-snackbar"
            message={snackbarMessage}
          />
        </Snackbar>
      </div>
    );
  }

  getError = (fieldName: string): JSX.Element | string => {
    const foundError =
      (this.state.errors as any[]).filter((error) => {
        return error.field === fieldName;
      })[0] || {};
    const errorCode = foundError.code;
    const errorMessage = foundError.message;

    if (errorCode) {
      return this.props.translationsStore.translate(
        `global.error.${errorCode.toLowerCase()}`,
      );
    }

    if (errorMessage) {
      return errorMessage;
    }
  };

  private handleInputChange = (event: any): void => {
    const target: any = event.target;
    this.setState({ [target.name]: target.value } as any);
  };

  private handleSubmitSuccess = (response): void => {
    if (response.accessToken) {
      this.props.loginSucceededEvent.pushEvent();
      return;
    }
    this.setState({
      errors: [],
      hasError: false,
      hasSucceeded: true,
      isLoading: false,
    });
  };

  private handleSubmitError = (err) => {
    if (!err.response) {
      this.props.gtmManager.pushEvent('error_message.shown', {
        errorMessage: err.message,
      });
      this.setState({
        errorMessage: 'An error happened',
        errors: [],
        hasError: true,
        isLoading: false,
      });
      return;
    }

    const errorCode = err.response.code;
    const errorMessage = errorCode
      ? this.props.translationsStore.translate(
          `global.error.${errorCode.toLowerCase()}`,
        )
      : err.response.message;

    this.props.gtmManager.pushEvent('error_message.shown', {
      errorMessage: errorMessage,
    });

    if (err.status === 400) {
      this.setState({
        errorMessage,
        errors: err.response.errors || [],
        hasError: true,
        isLoading: false,
      });
    } else {
      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }

    this.props.gtmManager.pushEvent('login.failed');
  };

  private closeSnackbar() {
    this.setState({ snackbarMessage: '' });
  }
}
