import * as React from 'react';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';

export const InfoItemsWrapper = styled.div`
  display: flex;
  margin: -${getSpacing(1)};
  padding-bottom: ${getSpacing(2)};
`;

export type InfoItemProps = {
  title: string | JSX.Element;
  description: string | JSX.Element;
  imagePath: string;
};

const InfoItemWrapper = styled.div`
  margin: ${getSpacing(1)};
  padding-bottom: ${getSpacing(1)};
  text-align: center;
`;

const InfoItemTitle = styled.div`
  margin-bottom: ${getSpacing(1)};
`;

const InfoItemDescription = styled.div`
  font-size: 12px;
`;

const ItemImage = styled.img`
  width: 100%;
  padding-bottom: ${getSpacing(1.5)};
`;

export const InfoItem = ({ title, description, imagePath }: InfoItemProps) => (
  <InfoItemWrapper>
    <ItemImage src={imagePath} />

    <InfoItemTitle>{title}</InfoItemTitle>
    <InfoItemDescription>{description}</InfoItemDescription>
  </InfoItemWrapper>
);
