import * as React from 'react';
import { withFwf, WithFwfProps } from './FwfProvider';

export type FwfVariationProps = {
  children: React.ReactNode;
  flagKey: string;
  loadingComponent?: React.ComponentType;
};

export type InnerFwfVariationProps = WithFwfProps & FwfVariationProps;

function InnerFwfVariation({
  children,
  flagKey,
  fwfClient,
  loadingComponent,
}: InnerFwfVariationProps) {
  const [showComponent, setShowComponent] = React.useState(false);
  const [variationFetched, setVariationFetched] = React.useState(false);
  React.useEffect(() => {
    fwfClient.getVariation(flagKey).then(
      (result) => {
        setShowComponent(result[flagKey].variation);
        setVariationFetched(true);
      },
      () => setVariationFetched(true),
    );
  }, []);

  if (showComponent) {
    return <>{children}</>;
  }

  if (!variationFetched && loadingComponent) {
    const LoadingComponent = loadingComponent;
    return <LoadingComponent />;
  }

  return null;
}

export const FwfVariation = withFwf<FwfVariationProps>(InnerFwfVariation);
