import * as React from 'react';
import SubHeaderText from '../../components/SubHeaderText';
import { isDeviceTypeRapp } from './isDeviceTypeRapp';
import { SubHeader as RappSubHeader } from './Rapp/StartSubHeader';
import { SubHeader as TabletSubHeader } from './Tablet/StartSubHeader';

export const Header = ({ platform }) => (
  <SubHeaderText>
    {isDeviceTypeRapp(platform.platformKeys) ? (
      <RappSubHeader />
    ) : (
      <TabletSubHeader />
    )}
  </SubHeaderText>
);
