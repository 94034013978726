import * as React from 'react';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { Button } from '@deliveryhero/vendor-portal-components';
import { Divider } from '@material-ui/core';
import { Translate } from '../../../components/Translate';

const FooterWrapper = styled.div`
  font-size: 14px;
  margin: 0 -20px;
  margin-top: ${getSpacing(1)};
  padding-bottom: ${getSpacing(2)};
`;

const FooterContent = styled.div`
  padding: ${getSpacing(3)} ${getSpacing(2)} 0;
`;

const FooterMessage = styled.div`
  padding-bottom: ${getSpacing(1)};
  line-height: 1.5em;
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -${getSpacing()};
`;

const FooterButton: any = styled(Button).attrs({
  color: 'primary',
})`
  padding-left: ${getSpacing()};
  padding-right: ${getSpacing()};
`;

export type StartPromptFooterProps = {
  hasSalesforce?: boolean;
  phoneNumber?: string;
  onLiveChatClick: () => void;
};

export default ({
  hasSalesforce,
  phoneNumber,
  onLiveChatClick,
}: StartPromptFooterProps) =>
  phoneNumber ? (
    <FooterWrapper>
      <Divider />
      <FooterContent>
        <FooterMessage>
          {hasSalesforce ? (
            <Translate code={'welcome.footer.textWithLiveChat'} />
          ) : (
            <Translate code={'welcome.footer.text'} />
          )}
        </FooterMessage>
        {/* eslint-disable-next-line test-selectors/button*/}
        <FooterButtons>
          <FooterButton
            href={`tel:${phoneNumber}`}
            data-testid="bottom-bar-phone"
          >
            {phoneNumber}
          </FooterButton>
          {hasSalesforce && (
            <FooterButton
              onClick={onLiveChatClick}
              data-testid="bottom-bar-livechat"
            >
              <Translate code="welcome.footer.live_chat" />
            </FooterButton>
          )}
        </FooterButtons>
      </FooterContent>
    </FooterWrapper>
  ) : null;
