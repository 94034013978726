import { inject, injectable, postConstruct } from 'inversify';
import { observable, autorun, action } from 'mobx';
import PageVisibilityManager from '../utils/PageVisibilityManager';

export type IntervalFunc = () => Promise<void>;

type DomIntervalReference = any;

export type RemoveIntervalFunc = () => void;

export type AddResponse = {
  removeInterval: RemoveIntervalFunc;
  getRemainingMilliseconds: () => number;
};

const removeInterval = (window: Window, ref) => (): void => {
  window.clearInterval(ref);
};

/**
 * @deprecated doesn't seem to be used anymore
 */
@injectable()
export default class IntervalService {
  private pageVisible: boolean = true;

  @inject('window') private window: Window;
  @inject(PageVisibilityManager)
  private pageVisibilityManager: PageVisibilityManager;

  @postConstruct() initialize(): void {
    this.pageVisibilityManager.addEventListener(() => {
      this.pageVisible = this.pageVisibilityManager.isPageVisible();
    });
  }

  add(func: IntervalFunc, timeout: number): AddResponse {
    let lastInterval: Date = new Date();
    const ref = this.window.setInterval(() => {
      if (this.pageVisible) {
        func();
      }
      lastInterval = new Date();
    }, timeout);

    return {
      removeInterval: removeInterval(this.window, ref),
      getRemainingMilliseconds: () =>
        Math.max(lastInterval.getTime() + timeout - Date.now(), 0),
    };
  }
}
