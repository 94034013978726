import * as React from 'react';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { Translate } from '../../Translate';
import _Button from '@material-ui/core/Button';
import _LanguageIcon from '@material-ui/icons/Language';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { IAvailableLanguage } from '../../../config';

type OnLanguageChangeFunc = (index: number) => void;

export type Props = {
  languages: IAvailableLanguage[];
  currentLanguage: number;
  onLanguageChange: OnLanguageChangeFunc;
};

type State = {
  languageMenuOpen: boolean;
};

const BUTTON_COLOR = '#fff';

const LanguageIcon = styled(_LanguageIcon)`
  padding-right: ${getSpacing(1)};
  color: ${BUTTON_COLOR};
  opacity: 0.54;
`;

const Button = styled(_Button)`
  color: ${BUTTON_COLOR};
`;

const Wrapper = styled.div`
  height: 68px;
  background: #0000002e;
`;

const Content = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 ${getSpacing(2)};
  display: flex;
  justify-content: flex-end;
`;

const Item = styled.div``;

const toLanguageCode = (language: string): string => `language.${language}`;

const renderLanguageOption = (
  onChange: OnLanguageChangeFunc,
  selectedIndex: number,
  closeMenu: () => void,
) => (language: IAvailableLanguage, i: number) => (
  <MenuItem
    key={`language-menu-${i}`}
    onClick={() => {
      onChange(i);
      closeMenu();
    }}
    selected={selectedIndex === i}
    disabled={selectedIndex === i}
  >
    {language.label}
  </MenuItem>
);

const languageLabel = (language: string) => (
  <Translate code={toLanguageCode(language)} />
);

class BottomBar extends React.Component<Props, State> {
  private anchorEl;

  constructor(props) {
    super(props);

    this.state = {
      languageMenuOpen: false,
    };
  }

  render() {
    const { languages, currentLanguage, onLanguageChange } = this.props;

    const { languageMenuOpen } = this.state;
    const currentLanguageObj = languages[currentLanguage];

    return (
      <Wrapper>
        <Content>
          <Item>
            <Button
              variant="text"
              color="primary"
              onClick={() => this.setLanguageMenuOpen(true)}
              buttonRef={(node) => (this.anchorEl = node)}
              data-testid="bottom-bar-language-switch-btn"
            >
              <LanguageIcon />
              {currentLanguageObj && currentLanguageObj.label}
            </Button>

            <Popover open={languageMenuOpen} anchorEl={this.anchorEl}>
              <Paper>
                <ClickAwayListener
                  onClickAway={() => this.setLanguageMenuOpen(false)}
                >
                  <MenuList>
                    {languages.map(
                      renderLanguageOption(
                        onLanguageChange,
                        currentLanguage,
                        () => this.setLanguageMenuOpen(false),
                      ),
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popover>
          </Item>
        </Content>
      </Wrapper>
    );
  }

  setLanguageMenuOpen(open: boolean): void {
    this.setState({ languageMenuOpen: open });
  }
}

export default BottomBar;
