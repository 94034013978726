import React from 'react';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import FrontendPluginContainer, {
  FrontendPluginContainerProps,
} from './FrontendPluginContainer';
import PluginModuleStore from '../../stores/PluginModuleStore';
import { TYPES } from '../../types';

export default withDependencies({
  moduleStore: PluginModuleStore,
  vendorStore: TYPES.VendorStore,
  sessionStore: TYPES.SessionStore,
})(FrontendPluginContainer) as React.ComponentType<
  FrontendPluginContainerProps
>;
