import { injectable, inject } from 'inversify';
import { when } from 'mobx';
import differenceInDays from 'date-fns/differenceInDays';

import { SessionStore } from '../stores/SessionStore';
import { LanguageStore } from '../stores/LanguageStore';
import { VendorStore } from '../stores/VendorStore';
import { TYPES } from '../types';

export type MarketingUserInfoType = {
  userId: string;
  shopId: string;
  city: string;
  countryCode: string;
  locale: string;
  platforms: string;
  platformIdentifiers: string;
  deliveryTypes: string;
  verticalTypes: string;
  keyAccount: boolean;
  userRole: string;
  userCreatedDaysAgo: number;
};

/**
 * It creates a user info object to be exposed for marketing purposes (GTM, Qualtrics)
 */

@injectable()
export default class MarketingUserInfo {
  @inject(TYPES.SessionStore) protected sessionStore: SessionStore;
  @inject(TYPES.VendorStore) protected vendorStore: VendorStore;
  @inject(LanguageStore) protected languageStore: LanguageStore;

  async getData(): Promise<MarketingUserInfoType> {
    // Defer the code below until restaurant data is initially fetched
    await when(() => this.vendorStore.isFetched);
    await when(() => this.sessionStore.hasSession);

    const address = this.vendorStore.currentVendor.address;
    const session = this.sessionStore.getMainSession();
    const platforms = session.platformIds;
    const platformIdentifiers = session.platformVendorIds;

    const userId = session.isImpersonator
      ? `impersonator-${this.sessionStore.getUserData('email')}`
      : `master-${this.sessionStore.getUserData('userId')}`;

    const keyAccount = this.vendorStore.hasKeyAccount;
    const verticalTypes = this.vendorStore.verticalTypes.join(',');
    const deliveryTypes = this.vendorStore.deliveryTypes.join(',');
    const userRole = this.sessionStore.jwtData?.user?.role;
    const userCreatedDaysAgo = differenceInDays(
      new Date(),
      this.sessionStore.getUserData('createdAt'),
    );
    const userInfo = {
      userId,
      shopId: this.sessionStore.firstVendorId,
      city: address && address.city && address.city.name,
      countryCode: this.sessionStore.getVendorCountry(),
      locale: this.languageStore.currentLanguage,
      platforms: platforms.join(','),
      platformIdentifiers: platformIdentifiers.join(','),
      deliveryTypes,
      verticalTypes,
      keyAccount,
      userRole,
      userCreatedDaysAgo,
    };

    return userInfo;
  }
}
