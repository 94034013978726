import * as React from 'react';
import StartPrompt from '../../components/StartPrompt';
import StartPromptHead from '../../components/StartPrompt/StartPromptHead';
import { Translate } from '../../components/Translate';
import { Button as _Button } from '@deliveryhero/vendor-portal-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import { Audience } from '../../stores/SessionStore';
import { ButtonProps } from '@material-ui/core/Button';
import { ElementType } from 'react';

export type Props = {
  platform: any;
  audience: Audience;
};

type InternalButtonProps = ElementType<any> &
  ButtonProps & {
    component: string | React.ComponentType;
  };

const Button = styled<InternalButtonProps>(_Button as any)`
  margin-bottom: ${getSpacing(1)};
`;

const goBackPath = (audience: Audience): string =>
  audience === Audience.MASTER ? '/master/login' : '/login';

const ResetPasswordSuccessView = ({ platform, audience }: Props) => (
  <div data-testid="reset-password-success">
    <StartPrompt platform={platform}>
      <StartPromptHead
        title={<Translate code="login.reset_password.success.title" />}
        text={<Translate code="login.reset_password.success.text" />}
      />
      <Button
        data-testid="reset-password-back-to-login"
        component={createButtonComponent(audience)}
        fullWidth
        color="primary"
      >
        <Translate code="login.reset_password.success.back_to_login" />
      </Button>
    </StartPrompt>
  </div>
);

export default ResetPasswordSuccessView;

function createButtonComponent(audience: Audience) {
  return React.forwardRef((props: any, ref) => (
    <Link to={goBackPath(audience)} ref={ref} {...props} />
  ));
}
