import AuthService from '../../services/AuthService';
import GtmManager from '../../utils/gtm/GtmManager';
import { SessionStore } from '../../stores/SessionStore';
import FwfStore from '../../stores/FwfStore';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import Logout from './Logout';
import { TYPES } from '../../types';

export default withDependencies({
  sessionStore: TYPES.SessionStore,
  fwfStore: FwfStore,
  gtmManager: GtmManager,
  window: 'window',
  authService: AuthService,
})(Logout);
