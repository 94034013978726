import * as React from 'react';
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';
import {
  PlatformLogo,
  TintedBackground,
} from '@deliveryhero/vendor-portal-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';
import BottomBar from './components/BottomBar';
import { IAvailableLanguage, IPlatform } from '../../config';
import { LanguageStore } from '../../stores/LanguageStore';
import StartPromptFooter from './components/StartPromptFooter';
import SalesforceStore from '../../stores/SalesforceStore';
import { getStaticPath } from '../../utils/PathHelper';

const LOGO_WIDTH = 64;

const Paper = styled(MuiPaper)`
  margin: 20px auto;
  padding: 10px 20px 10px;
  max-width: 300px;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.24), 0 0 24px 0 rgba(0, 0, 0, 0.14);
`;
Paper.displayName = 'LoginContainer';

const LogoWrapper = styled.div`
  margin: auto;
  margin-top: -56px;
  margin-bottom: ${getSpacing(3)};
  padding-top: ${getSpacing(2)};
  display: flex;
  justify-content: center;
`;

const LogoImage = styled.div`
  margin-left: -10px;
  &:first-child {
    margin-left: 0;
    z-index: 100;
  }
`;

const BackgroundWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const Background = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 1000;
`;

const Content = styled.div`
  flex-grow: 1;
  padding-bottom: ${getSpacing(5)};
  padding-top: ${getSpacing(7.5)};
`;

const ContentWrapper = styled.div`
  padding-bottom: ${getSpacing(1)};
`;

const CircularShadow = styled.span`
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  display: flex;
`;

const PlatformLogoWithShadow = (props) => {
  return (
    <CircularShadow>
      <PlatformLogo {...props} />
    </CircularShadow>
  );
};

const HeaderLogos = ({ currentPlatform }) => (
  <LogoWrapper>
    {currentPlatform.platformKeys.map((pk) => (
      <LogoImage key={pk}>
        <PlatformLogoWithShadow width={LOGO_WIDTH} platformKey={pk} />
      </LogoImage>
    ))}
  </LogoWrapper>
);

export type StartPromptInnerProps = {
  salesforceStore: SalesforceStore;
  languageStore: LanguageStore;
  availableLanguages: IAvailableLanguage[];
  platform: IPlatform;
};

export default class StartPrompt extends React.Component<
  StartPromptInnerProps,
  any
> {
  render() {
    const { platform, children } = this.props;

    const languages = this.props.availableLanguages;
    const currentLanguage = this.props.languageStore.currentLanguage;
    const currentLanguageIndex = languages.findIndex(
      (lang) => lang.value === currentLanguage,
    );

    const platformKey = platform.platformKeys[0];
    const hasSalesforce = this.props.salesforceStore.platformHasSalesforce(
      platformKey,
    );

    return (
      <div>
        <BackgroundWrapper>
          <Background>
            <TintedBackground image={getStaticPath('images/login-bg.jpg')} />
          </Background>
        </BackgroundWrapper>
        <Wrapper>
          <Content>
            <Paper elevation={24}>
              <HeaderLogos currentPlatform={platform} />

              <ContentWrapper>{children}</ContentWrapper>

              <StartPromptFooter
                phoneNumber={platform.phoneNumber}
                hasSalesforce={hasSalesforce}
                onLiveChatClick={this.handleLiveChatClick}
              />
            </Paper>
          </Content>

          <BottomBar
            languages={languages}
            currentLanguage={currentLanguageIndex}
            onLanguageChange={this.changeLanguage}
          />
        </Wrapper>
      </div>
    );
  }

  private changeLanguage = (index: number): void => {
    const newLanguage = this.props.availableLanguages.map((lang) => lang.value)[
      index
    ];

    this.props.languageStore.setCurrentLanguage(newLanguage);
  };

  private handleLiveChatClick = async () => {
    const { platform, salesforceStore } = this.props;
    const platformKey = platform.platformKeys[0];
    await salesforceStore.initSalesforce(platformKey);
    salesforceStore.startChat(platformKey);
  };
}
