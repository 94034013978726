import * as React from 'react';
import ResetPasswordView from './ResetPasswordView';
import ResetPasswordSuccessView from './ResetPasswordSuccessView';
import { PlatformStore } from '../../stores/PlatformStore';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { Translate } from '../../components/Translate';
import AuthService from '../../services/AuthService';
import { Audience } from '../../stores/SessionStore';

export type Props = {
  platformStore: PlatformStore;
  authService: AuthService;
};

export type State = {
  email: string;
  loading: boolean;
  snackbarMessage: string;
  requestSuccess: boolean;
};

class ResetPasswordContainer extends React.Component<Props, State> {
  state = {
    email: '',
    loading: false,
    snackbarMessage: '',
    requestSuccess: false,
  };

  componentDidMount() {
    this.state = {
      email: '',
      loading: false,
      snackbarMessage: '',
      requestSuccess: false,
    };
  }

  render() {
    const { email, loading, snackbarMessage, requestSuccess } = this.state;

    const currentPlatform = this.props.platformStore.currentPlatform;

    const snackbarOpen = snackbarMessage !== '';

    return (
      <React.Fragment>
        {requestSuccess ? (
          <ResetPasswordSuccessView
            platform={currentPlatform}
            audience={this.getAudience()}
          />
        ) : (
          <ResetPasswordView
            platform={currentPlatform}
            email={email}
            onEmailChange={this.onEmailChange.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            loading={loading}
          />
        )}

        <Snackbar
          open={snackbarOpen}
          onClose={this.closeSnackbar.bind(this)}
          autoHideDuration={3000}
        >
          <SnackbarContent
            message={<Translate code={snackbarMessage} />}
            data-testid="reset-password-snackbar-content"
          />
        </Snackbar>
      </React.Fragment>
    );
  }

  protected resetPassword(email: string): Promise<void> {
    return this.props.authService.requestMasterPasswordReset(email);
  }

  protected getAudience(): Audience {
    return Audience.MASTER;
  }

  private onEmailChange = (evt) => {
    const value = evt.target.value;

    this.setState({ email: value });
  };

  private onSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;

    this.setState({ loading: true });

    this.resetPassword(email)
      .then(this.onRequestPasswordResetSuccess.bind(this))
      .catch(this.onRequestPasswordResetFail.bind(this));
  };

  private onRequestPasswordResetSuccess = () => {
    this.setState({
      snackbarMessage: 'login.reset_password.request_succeeded',
      requestSuccess: true,
      loading: false,
    });
  };

  private onRequestPasswordResetFail = () => {
    this.setState({
      snackbarMessage: 'login.reset_password.request_failed',
      loading: false,
    });
  };

  private closeSnackbar = () => {
    this.setState({ snackbarMessage: '' });
  };
}

export { ResetPasswordContainer };
