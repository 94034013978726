import { injectable, inject, postConstruct } from 'inversify';
import { autorun, computed, observable, action } from 'mobx';
import Session, {
  IAccessTokenContent,
  ISession,
  IUserData,
} from '../models/Session';
import * as Sentry from '@sentry/browser';
import { TYPES } from '../types';
import { BrazeManager } from '../utils/BrazeManager';

export enum Audience {
  MASTER = 'master',
  GLOBAL = 'global',
}

@injectable()
export class SessionStore {
  @observable isUserReturned: boolean;
  @observable isDirty = false;
  @observable private session: Session = null;
  @inject('window') private window: Window;
  @inject(TYPES.BrazeManager) private braze: BrazeManager;

  @postConstruct() init() {
    const localStorageSessionInfo = this.window.localStorage.getItem(
      'mainSessionInfo',
    );
    const sessionInfo = JSON.parse(localStorageSessionInfo || '{}');

    // If sessionInfo is not empty, it means there is a session
    if (Object.keys(sessionInfo).length > 0) {
      this.setSessionInfo(sessionInfo);
      this.isUserReturned = this.isLoggedIn;
    }
    // Autosave sessionInfo on change
    autorun(() => {
      if (!this.isLoggedIn) {
        this.window.localStorage.removeItem('mainSessionInfo');
        this.clearSession();
      }
    });

    autorun(() => {
      if (
        this.mainSessionInfo &&
        Object.keys(this.mainSessionInfo).length > 0
      ) {
        this.window.localStorage.setItem(
          'mainSessionInfo',
          JSON.stringify(this.mainSessionInfo),
        );
      }
    });

    this.window.addEventListener('storage', (e) => {
      if (e.key === 'mainSessionInfo') {
        if (e.newValue === JSON.stringify(this.mainSessionInfo)) {
          return;
        }

        // Log out if new main session info or the new main session key is not defined
        if (!e.newValue) {
          this.clearSession();
          return;
        }

        this.setSessionInfo(JSON.parse(e.newValue || '{}'));
      }
    });
  }

  @computed get isLoggedIn(): boolean {
    const session = this.getMainSession();
    return !!session?.jwtData;
  }

  @computed get hasSession(): boolean {
    return !!this.session;
  }

  @action clearSession() {
    if (!!this.session) {
      this.session = null;
      this.isUserReturned = false;
    }
  }

  @action setSessionInfo(sessionContent: ISession) {
    this.session = new Session(sessionContent);
    Sentry.setUser({
      id: this.session.getUserData('userId'),
    });
    this.braze.setUserData(this.session);
    this.isDirty = true;
  }

  getMainSession(): Session {
    return this.session;
  }

  @computed get platformIds(): string[] {
    const mainSession = this.getMainSession();
    if (!mainSession) {
      return [];
    }

    return mainSession.platformIds;
  }

  @computed get sessionPlatformVendorsIds(): string[] {
    const mainSession = this.getMainSession();
    if (!mainSession) {
      return [];
    }

    return mainSession.platformVendorIds;
  }

  @computed get mainSessionInfo() {
    return this.getMainSession();
  }

  // Proxy methods to current session
  // VVVVVVVVVVVVVVVVV

  @computed get jwtData(): IAccessTokenContent {
    return <IAccessTokenContent>this.proxySessionGetter('jwtData');
  }

  @computed get audience(): Audience {
    return <Audience>this.proxySessionGetter('audience');
  }

  @computed get firstVendorId(): string {
    return <string>this.proxySessionGetter('firstVendorId');
  }

  @computed get hasRefreshToken(): boolean {
    return <boolean>this.proxySessionGetter('hasRefreshToken');
  }

  getUserData(prop: keyof IUserData): any {
    const session = this.getMainSession();
    if (session) {
      return session.getUserData(prop);
    }
  }

  getVendorCountry(): string {
    const session = this.getMainSession();
    if (session) {
      return session.getVendorCountry();
    }
  }

  private getSessionInfo(session?: Session) {
    if (!session) {
      return {};
    }

    return session.toJson();
  }

  private proxySessionGetter(key: keyof Session) {
    const session = this.getMainSession();
    if (session) {
      return session[key];
    }
  }
}
