import { Model, primitiveProp } from '@deliveryhero/mobx-model';

export interface IDialog {
  messageCode: string;
  okButtonCode: string;
  okButtonCallback?: any;
  isCancelable?: boolean;
  cancelButtonCode?: string;
  isModal?: boolean;
  titleCode?: string;
  isOpen?: boolean;
}

export class Dialog extends Model<IDialog> {
  @primitiveProp() messageCode: string;
  @primitiveProp() okButtonCode: string;
  @primitiveProp() isCancelable?: boolean;
  @primitiveProp() cancelButtonCode?: string;
  @primitiveProp() isModal?: boolean;
  @primitiveProp() titleCode?: string;
  @primitiveProp(false) isOpen: boolean;
  @primitiveProp(false) okButtonCallback: Function;

  constructor(props: IDialog) {
    super();
    this.fromJson(props, {
      isOpen: false,
      okButtonCallback: () => {},
    });
  }
}
