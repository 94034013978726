import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { LoginContainer } from './LoginContainer';
import { DialogStore } from '../../stores/DialogStore';
import GtmLoginSucceededEvent from '../../utils/gtm/GtmLoginSucceededEvent';
import { PlatformStore } from '../../stores/PlatformStore';
import { NavStore } from '../../stores/NavStore';
import GtmManager from '../../utils/gtm/GtmManager';
import { TranslationsStore } from '../../stores/TranslationsStore';
import AuthService from '../../services/AuthService';
import { TYPES } from '../../types';

export default withDependencies({
  authService: AuthService,
  sessionStore: TYPES.SessionStore,
  dialogStore: DialogStore,
  loginSucceededEvent: GtmLoginSucceededEvent,
  platformStore: PlatformStore,
  navStore: NavStore,
  window: 'window',
  gtmManager: GtmManager,
  translationsStore: TranslationsStore,
})(LoginContainer);
