import styled from 'styled-components';
import { Typography } from '@deliveryhero/vendor-portal-components';

const Headline = styled(Typography).attrs({ variant: 'headline' })`
  text-align: center;
  margin-bottom: 1em;

  & > strong {
    font-weight: 500;
  }
`;

export default Headline;
