import * as React from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { IConfig } from '../../../config';
import { PluginStore } from '../../../stores/PluginStore';
import WelcomeContainer from '../../Welcome';
import SetPasswordContainer from '../../SetPassword';
import routeComponentFactory from '../../../utils/routeComponentFactory';
import LoginContainer from '../../Login';
import Logout from '../../Logout';
import MasterRoutes from '../MasterRoutes';
import ResetPasswordContainer from '../../ResetPassword';
import OtpSignin from '../../OtpSignin';

const loginContainer = routeComponentFactory(LoginContainer);
const logoutContainer = routeComponentFactory(Logout);
const masterResetPasswordContainer = routeComponentFactory(
  ResetPasswordContainer,
);

export type RoutesProps = RouteComponentProps & {
  pluginStore: PluginStore;
  window: Window;
  config: IConfig;
};

const SetMasterPasswordContainer = (props) => (
  <SetPasswordContainer skipIntro={true} {...props} />
);

export default class Routes extends React.Component<RoutesProps> {
  componentDidMount() {
    this.props.window.dispatchEvent(new Event('promptInstallBanner'));
    this.props.window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.props.window.dispatchEvent(new Event('promptInstallBanner'));
    });
  }

  render() {
    const firstPluginRoute = this.props.pluginStore.frontendPlugins[0]?.route;

    return (
      <Switch>
        <Route
          path="/index\.html"
          exact={true}
          component={({ location }: any) => (
            <Redirect
              to={{
                pathname: this.props.config.basePath,
                search: location.search,
              }}
            />
          )}
        />
        <Route path="/login" exact={true} component={loginContainer} />
        <Route
          path="/set-password"
          exact={true}
          component={SetMasterPasswordContainer as any}
        />
        <Route path="/logout" exact={true} component={logoutContainer} />
        <Route
          path="/reset-password"
          exact={true}
          component={masterResetPasswordContainer}
        />
        <Route
          path="/welcome"
          exact={true}
          component={WelcomeContainer as any}
        />
        <Route path="/otp-signin" component={OtpSignin} />
        <Route
          path="/master/:path+"
          component={({ match }) => <Redirect to={`/${match.params.path}`} />}
        />
        {firstPluginRoute ? (
          <Route
            path="/"
            component={() => <Redirect to={firstPluginRoute} />}
            exact
          />
        ) : null}
        <Route component={MasterRoutes} />
      </Switch>
    );
  }
}
