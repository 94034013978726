import { withDependencies } from '@deliveryhero/react-inversify-provider';
import App from './App';
import { hot } from 'react-hot-loader';
import withThemeProviders from './withThemeProviders';
import { TranslationsStore } from '../../stores/TranslationsStore';
import { LocaleStore } from '../../stores/LocaleStore';
import { PluginStore } from '../../stores/PluginStore';
import LoadingManager from '../../utils/LoadingManager';
import { NavStore } from '../../stores/NavStore';
import { LanguageStore } from '../../stores/LanguageStore';
import FwfStore from '../../stores/FwfStore';
import { withWidth } from '@material-ui/core';
import { TYPES } from '../../types';

export default hot(module)(
  withThemeProviders(
    withWidth()(
      withDependencies({
        sessionStore: TYPES.SessionStore,
        translationStore: TranslationsStore,
        localeStore: LocaleStore,
        pluginStore: PluginStore,
        loadingManager: LoadingManager,
        navStore: NavStore,
        vendorStore: TYPES.VendorStore,
        languageStore: LanguageStore,
        fwfStore: FwfStore,
        history: 'history',
        devTools: 'devTools',
        config: 'config',
      })(App),
    ),
  ),
);
