import * as React from 'react';
import PasswordHandlingContainer from '../PasswordHandlingContainer';
import { PasswordHandlingContainerProps } from '../PasswordHandlingContainer/PasswordHandlingContainer';
import AuthService from '../../services/AuthService';

export type WelcomeContainerProps = PasswordHandlingContainerProps & {
  authService: AuthService;
};

const resetPassword = (authService: AuthService) => (
  token: string,
  newPassword: string,
) => authService.resetPassword(token, newPassword);

const WelcomeContainer = (props: WelcomeContainerProps) => (
  <PasswordHandlingContainer
    translationPrefix="welcome"
    submit={resetPassword(props.authService)}
    {...props}
  />
);

export default WelcomeContainer;
