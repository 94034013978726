import React from 'react';
import {
  getColor,
  withChildStyles,
  getSpacing,
} from '@deliveryhero/styled-material-ui';
import styled, { css } from 'styled-components';
import MuiMoreIcon from '@material-ui/icons/MoreVert';
import MuiBottomNavigation from '@material-ui/core/BottomNavigation';
import MuiBottomNavigationAction, {
  BottomNavigationActionProps,
} from '@material-ui/core/BottomNavigationAction';

import BaseNav, { INavItem } from '../BaseNav';
import { observer } from 'mobx-react';
import { Typography } from '@deliveryhero/vendor-portal-components';

const MoreIcon = styled(MuiMoreIcon)`
  margin: ${getSpacing(0.5)};
`;

const moreNavItem = {
  name: 'more',
  route: '/more',
  icon: <MoreIcon />,
  plugin: { code: 'more' },
  activeOn: ['/more'],
};

const BottomNavigationAction: React.ComponentType<BottomNavigationActionProps> = withChildStyles(
  MuiBottomNavigationAction,
  {
    root: css`
      min-width: 76px;
      color: ${getColor('common', 'white')};
      opacity: 0.45;
    `,
    selected: css`
      color: ${getColor('common', 'white')};
      opacity: 1;
    `,
  } as any,
);
BottomNavigationAction.displayName = 'BottomNavigationAction';

const BottomNavigationContainer = styled.div`
  height: 100px;
`;

const BottomNavigation = styled.div`
  padding: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
`;

const BottomNavigationList = styled(MuiBottomNavigation as any)`
  background-color: ${getColor('common', 'black')};
  box-shadow: ${(props) => props.theme.shadows[3]};
  height: 78px;
`;

@observer
export default class MobileNav extends BaseNav {
  handlePluginsChange() {
    super.handlePluginsChange();

    if (this.moreNavItems.length > 0) {
      this.mainNavItems.push({
        ...moreNavItem,
        activeOn: [
          ...moreNavItem.activeOn,
          ...this.moreNavItems.map((item) => item.route),
        ],
        route: moreNavItem.route,
      });
    }

    this.locationChangeHandler(this.props.history.location);
  }

  render() {
    const selectedIndex = this.selectedMainNavIndex;
    if (this.mainNavItems.length === 0 || !this.props.navStore.showBottomNav) {
      return null;
    }

    const navItems = [...this.mainNavItems];

    return (
      <BottomNavigationContainer>
        <BottomNavigation id="ci-navigation-bar">
          <BottomNavigationList
            value={selectedIndex}
            showLabels={true}
            className="ci-navigation-bar-container"
          >
            {navItems.map((navItem: INavItem) => (
              <BottomNavigationAction
                className={`ci-navigation-bar-item MENU_${navItem.plugin.code}`}
                data-testid={`nav-item-${navItem.name}`}
                key={`MENU_${navItem.plugin.code}`}
                label={
                  <Typography variant="caption" color="inherit">
                    {this.props.translationsStore.translate(
                      `global.menu.${navItem.plugin.code.toLowerCase()}`,
                    )}
                  </Typography>
                }
                showLabel={true}
                icon={navItem.icon}
                onClick={() => this.handleLinkClick(navItem)}
              />
            ))}
          </BottomNavigationList>
        </BottomNavigation>
      </BottomNavigationContainer>
    );
  }
}
