import * as React from 'react';
import { Switch, Route, Redirect, match } from 'react-router-dom';
import NotFound from '../../../components/errors/NotFound';
import { SessionStore } from '../../../stores/SessionStore';
import MasterContainer from '../../MasterContainer';
import { PluginStore } from '../../../stores/PluginStore';
import MoreMenuContainer from '../../MoreMenu';
import MasterPluginContainer from '../../MasterPluginContainer';
import { Location } from 'history';
import { QueryStringParser } from '../../../utils/QueryStringParser';
import { VendorStore } from '../../../stores/VendorStore';
import { getLoginRedirectUrlParts } from '../../../utils/getLoginRedirectUrlParts';
import { Plugin } from '../../../models/Plugin';

export type MasterRoutesProps = {
  sessionStore: SessionStore;
  pluginStore: PluginStore;
  match: match<{}>;
  location: Location;
  vendorStore: VendorStore;
  MasterContainer?: React.ComponentType<any>;
  MasterPluginContainer?: React.ComponentType<any>;
};

export type NavigationItem = {
  label: string;
  name: string;
  path: string;
  menuIcon: JSX.Element;
  gtmOpenEvent?: string;
};

export default class MasterRoutes extends React.Component<MasterRoutesProps> {
  static defaultProps = {
    MasterContainer: MasterContainer,
    MasterPluginContainer: MasterPluginContainer,
  };

  componentDidMount() {
    this.setCurrentVendorFromUrl();
  }

  componentDidUpdate(prevProps: Readonly<MasterRoutesProps>) {
    if (this.props.location.search === prevProps.location.search) {
      return;
    }

    this.setCurrentVendorFromUrl();
  }

  render() {
    const isLogged = this.props.sessionStore.isLoggedIn;

    if (!isLogged) {
      const {
        redirectQueryParameter,
        excludedQueryParameters,
      } = getLoginRedirectUrlParts(this.props.location);
      return (
        <Redirect
          to={{
            pathname: '/login',
            search: QueryStringParser.stringify({
              ...excludedQueryParameters,
              redirect: redirectQueryParameter,
            }),
          }}
        />
      );
    }

    // Sort plugins by route length to put more specific routes first
    const sortedPlugins = this.getSortedPluginsByRoutes();

    return (
      <this.props.MasterContainer>
        <Switch>
          {sortedPlugins.map(({ bundleUrl, route, code }) => (
            <Route
              path={route}
              key={code}
              exact={false}
              render={() => (
                <this.props.MasterPluginContainer
                  pluginName={code}
                  bundleUrl={bundleUrl}
                />
              )}
            />
          ))}
          <Route path={'/more'} exact={true} component={MoreMenuContainer} />
          <Route component={NotFound} />
        </Switch>
      </this.props.MasterContainer>
    );
  }

  private getSortedPluginsByRoutes() {
    const sortRoutesByLengthDesc = (a: Plugin, b: Plugin) =>
      b.route.length - a.route.length;

    return [...this.props.pluginStore.frontendPlugins].sort(
      sortRoutesByLengthDesc,
    );
  }

  private setCurrentVendorFromUrl() {
    const { vendor } = QueryStringParser.parse(this.props.location.search);

    if (vendor && vendor !== this.props.vendorStore.currentVendorId) {
      this.props.vendorStore.setCurrentVendorId(vendor);
    }
  }
}
