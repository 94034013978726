import { injectable, inject } from 'inversify';
import { Plugin } from '../models/Plugin';
import { observable, reaction, computed } from 'mobx';
import { Api } from '@deliveryhero/portal-api-client';
import LoadingManager from '../utils/LoadingManager';
import FwfStore from './FwfStore';
import {
  VariationFallback,
  VariationFallbackType,
} from '@deliveryhero/fwf-sdk-javascript';
import { PORTAL_VERSION } from '../constants';
import { SessionStore } from './SessionStore';
import { QueryStringParser } from '../utils/QueryStringParser';
import { TYPES } from '../types';
import { VendorStore } from './VendorStore';

const LOADING_MANAGER_PLUGIN_STORE = 'plugin_store';

const filterByType = (type: string) => (plugin) => plugin.type === type;

const filterByUniqueRoute = () => {
  const uniqueRoutes = [];
  return ({ route }: { route?: string }) => {
    const result = !uniqueRoutes.includes(route);
    uniqueRoutes.push(route);
    return result;
  };
};

@injectable()
export class PluginStore {
  readonly TYPE_MENU = 'MENU';
  readonly TYPE_SIDEBAR_MENU = 'SIDEBAR_MENU';
  readonly MENU_MAX_ITEMS = 4;

  @observable plugins: Array<Plugin> = [];
  @observable isDirty: boolean = false;

  @inject(TYPES.SessionStore) private sessionStore: SessionStore;
  @inject(TYPES.VendorStore) private vendorStore: VendorStore;
  @inject(TYPES.GlobalApi) private api: Api;
  @inject('pluginUrl') private baseUrl: string;
  @inject(LoadingManager) private loadingManager: LoadingManager;
  @inject(FwfStore) private fwfStore: FwfStore;

  init() {
    // Reaction that triggers every time the vendors change (should only happen once after login)
    reaction(
      () => [...this.vendorStore.allVendors],
      (allVendors) => {
        if (allVendors.length > 0) {
          const platformIds = this.vendorStore.allPlatforms;
          this.isDirty = false;
          this.setLoadingManager(true);
          const { role } = this.sessionStore.getMainSession();
          const verticalTypes = this.vendorStore.verticalTypes.join(',');
          const searchParams = QueryStringParser.stringify({
            portalVersion: PORTAL_VERSION,
            role,
            verticalTypes,
          });
          const fetchUrl = `${this.baseUrl}/platforms/${platformIds.join(
            ',',
          )}/plugins${searchParams}`;

          this.api
            .fetch(fetchUrl, 200, { method: 'GET' })
            .then(this.payloadToPlugins)
            .then(this.setPluginsForRestaurant.bind(this))
            .then(() => this.setLoadingManager(false))
            .catch(() => this.setLoadingManager(false));
        }
      },
      { fireImmediately: true },
    );
  }

  /**
   * Get plugin by plugin code
   * @param code Plugin code (e.g. `OPENING_TIMES_TB` or `MENU_MANAGEMENT_PANDORA`)
   */
  getByCode(code: string): Plugin | undefined {
    if (!this.isPluginAvailable(code)) {
      return undefined;
    }

    const plugins = this.plugins.filter(
      (plugin: Plugin) => plugin.code === code,
    );

    return plugins.length ? plugins[0] : undefined;
  }

  /**
   * Returns boolean value that indicates if plugin is available for user
   * @param code Plugin code
   */
  isPluginAvailable(code: string): boolean {
    if (!this.plugins) {
      return false;
    }

    return this.plugins.some((element: Plugin) => element.code === code);
  }

  /**
   * Returns plugins that have all the required configuration (has route and bundle URL)
   */
  @computed get frontendPlugins() {
    return this.plugins.filter((plugin) => plugin.isFrontendPlugin);
  }

  /**
   * Plugins that can be shown in the menu
   */
  @computed get menuPlugins() {
    return this.getAllPluginsItemsForType(this.TYPE_MENU);
  }

  /**
   * Returns plugins that can be displayed in the mobile menu
   */
  @computed get pluginsForMenuItems(): Plugin[] {
    const allPluginsForMenu = this.menuPlugins;
    if (allPluginsForMenu.length <= this.MENU_MAX_ITEMS) {
      return allPluginsForMenu;
    }
    return allPluginsForMenu.slice(0, this.MENU_MAX_ITEMS - 1);
  }

  /**
   * Returns plugins that can be displayed in the more section on mobile
   */
  @computed get pluginsForMoreMenuItems(): Plugin[] {
    const allPluginsForMenu = this.getAllPluginsItemsForType(this.TYPE_MENU);
    if (allPluginsForMenu.length <= this.MENU_MAX_ITEMS) {
      return [];
    }
    return allPluginsForMenu.slice(this.MENU_MAX_ITEMS - 1);
  }

  /**
   * @deprecated
   */
  @computed get pluginsForSidebarMenuItems(): Plugin[] {
    return this.getAllPluginsItemsForType(this.TYPE_SIDEBAR_MENU);
  }

  private async setPluginsForRestaurant(plugins: Plugin[]) {
    const pluginVisibility = await Promise.all(
      plugins.map(async (plugin) => {
        const activeFallback = plugin.activeFallback ?? true;
        if (plugin.fwfClient) {
          const variationFallback = new VariationFallback(
            VariationFallbackType.FROM_VALUE,
          );
          variationFallback.setValue(activeFallback);
          try {
            const pluginCode = plugin.code.replace(/\_/g, '-').toLowerCase();
            const flagName = `${pluginCode}-active`;
            const variation = await plugin.fwfClient.getVariation(
              flagName,
              variationFallback,
            );
            return variation[flagName].variation;
          } catch {
            return activeFallback;
          }
        }

        return activeFallback;
      }),
    );

    this.plugins = plugins.filter((_plugin, i) => pluginVisibility[i]);
    this.isDirty = true;
  }

  /**
   * Generates a `Plugin` model instance from API response
   * @param payload payload of the API response
   */
  private payloadToPlugins = async (payload): Promise<Plugin[]> => {
    const plugins: Plugin[] = await Promise.all(
      payload.data.map(
        async (pluginPayload) =>
          new Plugin(pluginPayload, {
            ...(await this.getFwfPluginParameters(pluginPayload)),
          }),
      ),
    );

    return plugins;
  };

  /**
   * Gets the FwF parameters (client and activeFallback).
   *
   * @param pluginPayload single plugin payload of the Portal API plugin endpoint
   */
  private async getFwfPluginParameters(pluginPayload) {
    if (pluginPayload.options?.fwf?.clientId) {
      const { activeFallback } = pluginPayload.options.fwf;
      const fwfClient = await this.fwfStore.createFwfClient(
        `plugin_${pluginPayload.code}`,
        pluginPayload.options.fwf.clientId,
      );

      return {
        fwfClient,
        activeFallback,
      };
    }

    return {};
  }

  /**
   * Filters plugins based on type (e.g. `MENU` or `UNLISTED`)
   * @param pluginType type of the plugin
   */
  private getAllPluginsItemsForType(pluginType: string): Plugin[] {
    const allPlugins = this.frontendPlugins.filter(
      (plugin, i, arr) =>
        arr.findIndex((val) => val.route === plugin.route) === i,
    );
    const plugins = this.frontendPlugins.length > 0 ? allPlugins : [];

    return plugins
      .filter(filterByType(pluginType))
      .filter(filterByUniqueRoute());
  }

  /**
   * Sets the loading state for the plugin store (to show UI blocking loading spinner)
   * @param loading new loading state
   */
  private setLoadingManager(loading: boolean): void {
    if (!loading) {
      this.loadingManager.remove(LOADING_MANAGER_PLUGIN_STORE);
      return;
    }

    this.loadingManager.add(LOADING_MANAGER_PLUGIN_STORE);
  }
}
