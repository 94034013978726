import * as React from 'react';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import StartPrompt from './StartPrompt';
import SalesforceStore from '../../stores/SalesforceStore';
import { LanguageStore } from '../../stores/LanguageStore';
import { IPlatform } from '@deliveryhero/vendor-portal-components/dist/interfaces/IPlatform';

export type StartPromptProps = {
  platform: IPlatform;
};

export default withDependencies({
  salesforceStore: SalesforceStore,
  languageStore: LanguageStore,
  availableLanguages: 'availableLanguages',
})(StartPrompt) as React.ComponentType<StartPromptProps>;
