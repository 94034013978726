import * as React from 'react';
import { CenteredCircularProgress } from '@deliveryhero/vendor-portal-components';
import PluginModuleStore from '../../stores/PluginModuleStore';
import PluginModule from '../../models/PluginModule';
import PluginModuleLoadError from '../../components/errors/PluginModuleLoadError';
import { observer } from 'mobx-react';
import { when } from 'mobx';
import { SdkProvider } from '@deliveryhero/vendor-portal-sdk';
import { SessionStore } from '../../stores/SessionStore';
import { RouterProvider } from '../../utils/CustomRouter';
import { VendorStore } from '../../stores/VendorStore';

export type FrontendPluginContainerProps = {
  bundleUrl: string;
  pluginName: string;
};

export type FrontendPluginContainerInnerProps = FrontendPluginContainerProps & {
  moduleStore: PluginModuleStore;
  vendorStore: VendorStore;
  sessionStore: SessionStore;
};

@observer
export default class FrontendPluginContainer extends React.Component<
  FrontendPluginContainerInnerProps
> {
  state = {
    PluginComponent: null,
    sdk: null,
    hasError: false,
  };

  private _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    return new Promise<void>((resolve) =>
      when(
        () => {
          return (
            this.props.vendorStore.isVendorAvailable &&
            this.currentSessionIsAvailable
          );
        },
        () => {
          this.props.moduleStore
            .loadModule(this.props.pluginName, this.props.bundleUrl)
            .then(
              (pluginModule: PluginModule) => {
                if (this._isMounted) {
                  let PluginComponent = pluginModule.instance.getMainComponent();

                  if (!PluginComponent) {
                    this.setState({
                      hasError: true,
                    });
                    resolve();
                    return;
                  }

                  this.setState({
                    PluginComponent,
                    sdk: pluginModule.sdk,
                  });
                  resolve();
                }
              },
              () => {
                this.setState({
                  hasError: true,
                });
                resolve();
              },
            );
        },
      ),
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.state.hasError) {
      return <PluginModuleLoadError />;
    }

    if (this.state.PluginComponent && this.currentSessionIsAvailable) {
      return (
        <RouterProvider baseurl={this.state.sdk.getBaseRoute()}>
          <SdkProvider sdk={this.state.sdk}>
            <this.state.PluginComponent />
          </SdkProvider>
        </RouterProvider>
      );
    }

    return <CenteredCircularProgress />;
  }

  private get currentSessionIsAvailable() {
    return this.props.sessionStore.hasSession;
  }
}
