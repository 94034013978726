import * as React from 'react';

import {
  Heatmap as __Heatmap,
  IHeatmapProps,
} from '@deliveryhero/vendor-portal-components';
import { withDependencies } from '@deliveryhero/react-inversify-provider';
import { TranslationsStore } from '../../stores/TranslationsStore';

export {
  IBarChart,
  IBarChartData,
  UnthemedBarChart,
  BarChart,
  IBaseHeatmapProps,
  IScaledHeatmapProps,
  IHeatmapProps,
  defaultColors,
  IScaledHeatmapState,
  ScaledHeatmap,
  ITableChartProps,
  TableChart,
  IHorizontalBarProps,
  HorizontalBar,
  IListProps,
  List,
} from '@deliveryhero/vendor-portal-components';

export type DependencyProps = {
  translationsStore: TranslationsStore;
};

const _Heatmap: React.StatelessComponent<IHeatmapProps & DependencyProps> = (
  props,
) => (
  <__Heatmap
    noDataMessage={props.translationsStore.translate(
      'plugins.reports.errors.no_data',
    )}
    {...props}
  />
);

export const Heatmap: React.StatelessComponent<IHeatmapProps> = withDependencies(
  {
    translationsStore: TranslationsStore,
  },
)(_Heatmap);
