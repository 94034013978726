import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Api } from '@deliveryhero/portal-api-client';
import LoadingManager from '../../../utils/LoadingManager';
import AuthService from '../../../services/AuthService';
import { SessionStore } from '../../../stores/SessionStore';
import { reaction } from 'mobx';
import { QueryStringParser } from '../../../utils/QueryStringParser';
import { getLoginRedirectUrlParts } from '../../../utils/getLoginRedirectUrlParts';

export type RefreshTokenHandlerProps = RouteComponentProps & {
  globalApi: Api;
  authService: AuthService;
  sessionStore: SessionStore;
  loadingManager: LoadingManager;
};

export interface IRefreshTokenHandlerState {
  hasRefreshTokenFailed: boolean;
  isTokenInvalid: boolean;
}

/**
 * Component that is used to listen to invalid token changes of the global API.
 * When unmounting removing the subscription.
 * @todo Not really sure if it is still needed, maybe try out with removing
 */
class RefreshTokenHandler extends React.Component<
  RefreshTokenHandlerProps,
  IRefreshTokenHandlerState
> {
  static displayName = 'RefreshTokenHandler';

  private globalSubscription: any;

  constructor(props) {
    super(props);
    this.state = {
      hasRefreshTokenFailed: false,
      isTokenInvalid: false,
    };
  }

  componentDidMount() {
    this.globalSubscription = this.props.globalApi.subscribe(
      this.handleApiSubscription.bind(this),
    );

    reaction(
      () => [
        this.props.sessionStore.hasSession,
        this.props.sessionStore.isDirty,
      ],
      ([hasSessions, isDirty]) => {
        if (!hasSessions && isDirty) {
          this.logout();
        }
      },
    );
  }

  componentWillUnmount() {
    this.props.globalApi.unsubscribe(this.globalSubscription);
  }

  render() {
    return null;
  }

  private handleApiSubscription({ res, payload }) {
    const parsedPayload =
      typeof payload === 'string' ? JSON.parse(payload || '{}') : payload;

    if (res.status === 401 && parsedPayload.code === 'TOKEN_INVALID') {
      this.logout();
    }
  }

  private logout() {
    const {
      redirectQueryParameter,
      excludedQueryParameters,
    } = getLoginRedirectUrlParts(this.props.location);

    this.props.history.push({
      pathname: `/logout${QueryStringParser.stringify(
        excludedQueryParameters,
      )}`,
      state: {
        redirect: redirectQueryParameter,
      },
    });
  }
}

export default withRouter(RefreshTokenHandler);
