import * as React from 'react';
import WelcomeStartContainer from './parts/Start/Start';
import WelcomePasswordContainer from './parts/Password/PasswordContainer';
import PasswordHandlingView from './PasswordHandlingView';
import { PlatformStore } from '../../stores/PlatformStore';
import { IPlatform } from '../../config';
import { NavStore } from '../../stores/NavStore';
import { QueryStringParser } from '../../utils/QueryStringParser';
import { ErrorMessage } from '@deliveryhero/vendor-portal-components';
import { Translate } from '../../components/Translate';
import { Location } from 'history';

export type PasswordHandlingContainerProps = {
  location: Location;
  platformStore: PlatformStore;
  navStore: NavStore;
  submit: (token: string, password: string) => Promise<void>;
  skipIntro: boolean;
};

export type PasswordHandlingContainerState = {
  currentComponentIndex: number;
};

export type PasswordHandlingContainerComponentProps = {
  currentPlatform?: IPlatform;
  token: string;
  onBackClick?: () => void;
  onNextClick?: () => void;
  skipIntro?: boolean;
  submit: (token: string, password: string) => Promise<void>;
};

// Is used by welcome and reset password containers
export default class PasswordHandlingContainer extends React.Component<
  PasswordHandlingContainerProps,
  PasswordHandlingContainerState
> {
  state = {
    currentComponentIndex: 0,
  };

  private components: React.ComponentType<
    PasswordHandlingContainerComponentProps
  >[] = [WelcomeStartContainer, WelcomePasswordContainer];

  componentDidMount() {
    this.props.navStore.setNewNavigation({
      title: null,
      name: 'welcome',
      showAppBar: false,
      showBottomNav: false,
    });
  }

  render() {
    const components = this.getComponents();
    const CurrentComponent = components[this.state.currentComponentIndex];
    const currentPlatform = this.props.platformStore.currentPlatform;

    const searchParams = QueryStringParser.parse(this.props.location.search);

    if (!searchParams.token) {
      return (
        <ErrorMessage
          title={<Translate code="password_handling.error.no_token.title" />}
          message={
            <Translate code="password_handling.error.no_token.description" />
          }
        />
      );
    }

    return (
      <PasswordHandlingView currentPlatform={currentPlatform}>
        <CurrentComponent
          onBackClick={this.handleBack}
          onNextClick={this.handleNext}
          currentPlatform={currentPlatform}
          token={searchParams.token}
          {...this.props}
        />
      </PasswordHandlingView>
    );
  }

  handleBack = () => {
    if (this.state.currentComponentIndex > 0) {
      this.setState({
        currentComponentIndex: this.state.currentComponentIndex - 1,
      });
    }
  };

  handleNext = () => {
    const components = this.getComponents();

    if (this.state.currentComponentIndex < components.length - 1) {
      this.setState({
        currentComponentIndex: this.state.currentComponentIndex + 1,
      });
    }
  };

  private getComponents() {
    if (this.props.skipIntro) {
      return this.components.slice(1);
    }

    return this.components;
  }
}
