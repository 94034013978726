import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { observer } from 'mobx-react';

import { DialogStore } from '../../stores/DialogStore';
import { Translate } from '../Translate';

export type AppDialogProps = {
  dialogStore: DialogStore;
};

export interface IAppDialogState {}

@observer
export default class AppDialog extends React.Component<
  AppDialogProps,
  IAppDialogState
> {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    const currentDialog = this.props.dialogStore.currentDialog;

    return (
      <Dialog
        open={!!currentDialog && currentDialog.isOpen}
        onClose={this.handleClose}
      >
        <DialogContent>
          {currentDialog && currentDialog.titleCode && (
            <DialogTitle>
              <Translate code={currentDialog.titleCode} />
            </DialogTitle>
          )}
          {currentDialog && currentDialog.messageCode && (
            <DialogContentText>
              <Translate code={currentDialog.messageCode} />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {currentDialog && currentDialog.isCancelable ? (
            <Button
              color="primary"
              onClick={this.handleClose}
              data-testid="app-dialog-close-btn"
            >
              <Translate code={currentDialog.cancelButtonCode} />
            </Button>
          ) : null}
          {currentDialog && (
            <Button
              color="primary"
              onClick={this.handleClose}
              data-testid="app-dialog-ok-btn"
            >
              <Translate code={currentDialog.okButtonCode} />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  private handleClose() {
    this.props.dialogStore.closeCurrentDialog();
    this.props.dialogStore.next();
  }
}
