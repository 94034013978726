import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import _DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Button } from '@deliveryhero/vendor-portal-components';
import { Translate } from './Translate';
import Radio from '@material-ui/core/Radio';
import styled from 'styled-components';
import { getSpacing } from '@deliveryhero/styled-material-ui';

export type Option = {
  label: string;
  Icon?: React.Component | JSX.Element | React.ReactFragment;
  initiallySelected: boolean;
};

export type Props = {
  open: boolean;
  onClose: (event: any) => void;
  options: Option[];
  onSelect: Function;
  title: string | JSX.Element;
};

type State = {
  selected: number;
};

const DialogContent = styled(_DialogContent)`
  padding: 0;
`;

const ListItemStyled = styled(ListItem)`
  padding-left: ${getSpacing(1)};
  padding-top: 0;
  padding-bottom: 0;
`;

const ListItemTextStyled = styled(ListItemText)`
  padding-left: ${getSpacing(0.5)};
` as any;

const isInitiallySelected = ({ initiallySelected }: Option): boolean =>
  initiallySelected;

const renderOption = (onClick, selected: number) => (
  { label, Icon }: Option,
  i: number,
) => (
  <ListItemStyled
    key={`dialog-menu-${i}`}
    onClick={() => onClick(i)}
    data-testid={`dialog-menu-option-${i}`}
    button
  >
    <Radio checked={selected === i} color="primary" />

    <ListItemTextStyled primary={label} />
    {Icon || null}
  </ListItemStyled>
);

const isNumber = (value: any): boolean => typeof value === 'number';

class DialogMenu extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const initiallySelected = this.props.options.findIndex(isInitiallySelected);

    this.state = {
      selected: isNumber(initiallySelected) ? initiallySelected : -1,
    };
  }

  render() {
    const { open, onClose, options, onSelect, title } = this.props;

    const { selected } = this.state;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <List>
            {options.map(renderOption(this.selectOption.bind(this), selected))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
            data-testid="dialog-menu-cancel"
          >
            <Translate code="global.cancel" />
          </Button>

          <Button
            color="primary"
            disabled={selected === -1}
            onClick={() => onSelect(selected)}
            data-testid="dialog-menu-save"
          >
            <Translate code="global.save" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private selectOption = (index: number): void => {
    this.setState({ selected: index });
  };
}

export default DialogMenu;
