import * as React from 'react';
import Input, { InputProps } from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export type PasswordFieldProps = InputProps & {
  buttonDisabled?: boolean;
  visible?: boolean;
};

export type PasswordFieldState = {
  visible: boolean;
};

export default class PasswordField extends React.Component<
  PasswordFieldProps,
  PasswordFieldState
> {
  static defaultProps = {
    buttonDisabled: false,
    visible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
    };
  }

  toggleVisibility = () => {
    this.setState(({ visible }) => ({
      visible: !visible,
    }));
  };

  handleButtonMouseDown = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      classes,
      buttonDisabled,
      visible: visibleProp,
      ...other
    } = this.props;

    const { visible } = this.state;

    return (
      <Input
        {...other}
        type={this.state.visible ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={this.toggleVisibility}
              onMouseDown={this.handleButtonMouseDown}
              disabled={other.disabled || buttonDisabled}
              tabIndex={-1}
              data-testid="password-field-visibility-toggle"
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    );
  }
}
