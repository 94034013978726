import { injectable, inject } from 'inversify';

import GtmManager from './GtmManager';
import { GtmEvent } from './GtmEvent';
import MarketingUserInfo from '../MarketingUserInfo';

@injectable()
export default class GtmAuthenticatedUserEvent extends GtmEvent {
  @inject(GtmManager) protected gtmManager: GtmManager;
  @inject(MarketingUserInfo) protected marketingUserInfo: MarketingUserInfo;

  async pushEvent(event: string, data?: {}): Promise<void> {
    const userInfo = await this.marketingUserInfo.getData();

    this.gtmManager.pushEvent(event, {
      ...data,
      ...userInfo,
    });
  }
}
