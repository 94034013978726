import { Model, primitiveProp } from '@deliveryhero/mobx-model';
import { computed } from 'mobx';
import FWFMain from '@deliveryhero/fwf-sdk-javascript';

export interface IMenuIcon {
  url: string;
}

export interface IPlugin {
  name: string;
  code: string;
  type: string;
  isInline?: boolean; // For local plugins without bundleUrl
  endpoint?: string;
  options?: any;
  bundleUrl?: string;
  route?: string;
  menuIcon?: IMenuIcon;
  fwfClient?: FWFMain;
  fallbackUrl?: string;
}

export class Plugin extends Model<IPlugin> {
  @primitiveProp() name: string;
  @primitiveProp() code: string;
  @primitiveProp() type: string;
  /** @deprecated */
  @primitiveProp() isInline?: boolean;
  @primitiveProp() endpoint?: string;
  @primitiveProp() options?: any;
  @primitiveProp() bundleUrl?: string;
  @primitiveProp() route?: string;
  @primitiveProp() menuIcon?: IMenuIcon;
  @primitiveProp() fwfClient?: FWFMain;
  @primitiveProp() activeFallback?: boolean;
  @primitiveProp() fallbackUrl?: string;

  constructor(props, defaults = {}) {
    super();
    this.fromJson(props, {
      isInline: false,
      fallbackUrl: props.options?.fallbackUrl,
      ...defaults,
    });
  }

  /**
   * Tells if plugin is displayable (has bundle URL and a route configured)
   */
  @computed get isFrontendPlugin(): boolean {
    return (!!this.bundleUrl || this.isInline) && !!this.route;
  }
}
