import { injectable, inject } from 'inversify';
import { IPlatform } from '../config';

const defaultPlatform: IPlatform = {
  name: 'default',
  nameReadable: 'Default',
  platformKeys: ['LH_DE'],
  domains: [],
  color: 'rgb(71.8%, 71.8%, 71.8%)',
  defaultLocale: null,
};

/**
 * Handles platform configuration to set the branding. The platform that is managed here is
 * based on the domain, so `foodpanda.portal.restaurant` sets the platform to foodpanda.
 * This platform has nothing to do with the actual vendors/platforms attached to the user.
 *
 * @todo we should rename this to `BrandingStore` maybe?
 */
@injectable()
export class PlatformStore {
  currentPlatform: IPlatform;

  @inject('platforms') private platforms: IPlatform[];

  /**
   * Set the platform by name (e.g. through the `platform` search query parameter), this is mainly used for dev
   * @param platformName name of the platform
   */
  setPlatformByName(platformName: string) {
    const platformToSet = this.platforms.filter(
      (platform) => platform.name === platformName,
    )[0];
    return this.setPlatform(platformToSet);
  }

  /**
   * Set platform by domain (e.g. foodpanda.portal.restaurant) would set the platform to foodpanda
   * @param domain
   */
  setPlatformByDomain(domain: string) {
    const platformToSet = this.platforms.filter(
      (platform) => platform.domains.indexOf(domain) > -1,
    )[0];
    return this.setPlatform(platformToSet);
  }

  private setPlatform(platform: IPlatform) {
    this.currentPlatform = Object.assign({}, defaultPlatform, platform);
  }
}
